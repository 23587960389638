import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {Grid,
    Card,
    CardContent,
    CardHeader,
    Button} from '@material-ui/core';

import {dateFormat} from 'variables/general.jsx';

import moment from "moment";
import{
    ContentSave,
} from 'mdi-material-ui';
import BasicProjectForm from 'components/Projects/BasicProjectForm.jsx'

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    update:{
        backgroundColor: theme.button.update,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    projectContainer:{
        overflow:'visible'
    }
});

class ProjectViewOne extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            name_fixed: "",
            name: "",
            color: "",
            description: "",
            date_start: moment(new Date()).format(dateFormat),
            date_end: moment(new Date()).format(dateFormat),
            daysCount: 0,
            categories:[],
			name_errorMessage: "",
            description_errorMessage: "",
            daysCount_errorMessage: "",
            userId:-1,
            clientId:-1,
            user_errorMessage: "",
            categories_errorMessage:"",
            tags:[],
        };
        this.updateRight = this.props.user.adminPermission.projects.createUpdate;
        if(!this.updateRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    componentDidMount = () => {
		this.props.apiControllers.projects.getById(this.props.match.params.projectId,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token
        }).then((response)=>{
            this.props.logger.debug(response)
            this.setState({
                name_fixed: response.data.name,
                name: response.data.name,
                description: response.data.description,
                color: response.data.color,
                date_start: response.data.date_start,
                date_end: response.data.date_end,
                daysCount: response.data.daysCount,
                userId: response.data.userId,
                clientId: response.data.clientId,
                categories: response.data.categories,
                tags: response.data.tags
            });

        }).catch((error) => {
            if(error.status === 403 || error.status === 404 || error.status === 400){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

    //======================= UI Callback ========================

    update_click = () => {
        if(this.state.name === ""){
            this.setState({name_errorMessage: "Le champ ne peut être vide"});
        } else if(this.state.userId === -1){
            this.setState({user_errorMessage: "Vous devez choisir un utilisateur"});
        }else if(isNaN(parseInt(this.state.daysCount)) || this.state.daysCount <= 0 || (this.state.daysCount % 1) !== 0){
            this.setState({daysCount_errorMessage: 'Le champ doit être supérieur à 0, par pas de 1'});
        } else if(this.state.categories.length <= 0){
            this.setState({categories_errorMessage:'Vous devez sélectionner au moins une catégorie'});
        } else {
            this.props.apiControllers.projects.update(
                this.props.match.params.projectId,
                {
                    name: this.state.name,
                    color: this.state.color,
                    userId: this.state.userId,
                    clientId: (this.state.clientId === -1 ? null : this.state.clientId),
                    categories:this.state.categories.map(category => category.id),
                    date_start: this.state.date_start,
                    date_end: this.state.date_end,
                    daysCount: this.state.daysCount,
                    tags:this.state.tags.map(tag => tag.id),
                    description: this.state.description
                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }
            ).then((response) => {
                this.props.notifier.success('Projet correctement modifié');
                this.props.updateInnerUser()
                .then(() => {this.props.history.goBack()});
            }).catch((error) => {
                this.props.notifier.error(error.data);
            });
        }
    }

    name_change = (event) => {
        this.setState({
            name: event.target.value,
            name_errorMessage: ""
        });
    }

    color_change = (color) => {
        this.setState({ color: color.hex });
    };

    description_change = (event) => {
        this.setState({
            description: event.target.value,
            description_errorMessage: ""
        });
    }

    date_start_change = (date) => {
        this.setState({
            date_start: date
        });
    }

    date_end_change = (date) => {
        this.setState({
            date_end: date
        });
    }

    daysCount_change = (event) => {
        this.setState({
            daysCount: event.target.value,
            daysCount_errorMessage: ""
        })
    }

    userId_change = (event) => {
        this.setState({
            userId: event.target.value,
            user_errorMessage: ""
        });
    }

    clientId_change = (event) => {
        this.setState({
            clientId: event.target.value,
        });
    }

    categories_changed = (categories) => {
        this.setState({
            categories:categories,
            categories_errorMessage:""
        });
    }

    tags_changed = (tags) => {
        this.setState({
            tags:tags
        });
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        className={classes.projectContainer}
                    >
                        <CardHeader
                            title={"Modification du projet "+this.state.name_fixed}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <BasicProjectForm
                                        apiControllers={this.props.apiControllers}
                                        logger={this.props.logger}
                                        name={this.state.name}
                                        description={this.state.description}
                                        date_start={this.state.date_start}
                                        date_end={this.state.date_end}
                                        daysCount={this.state.daysCount}
                                        color={this.state.color}
                                        selectedCategories={this.state.categories}
                                        selectedTags={this.state.tags}
                                        userId={this.state.userId}
                                        clientId={this.state.clientId}
                                        auth_user={this.props.user}
                                        name_errorMessage={this.state.name_errorMessage}
                                        description_errorMessage={this.state.description_errorMessage}
                                        daysCount_errorMessage={this.state.daysCount_errorMessage}
                                        user_errorMessage={this.state.user_errorMessage}
                                        categories_errorMessage={this.state.categories_errorMessage}
                                        name_change={this.name_change}
                                        description_change={this.description_change}
                                        date_start_change={this.date_start_change}
                                        date_end_change={this.date_end_change}
                                        daysCount_change={this.daysCount_change}
                                        color_change={this.color_change}
                                        userId_change={this.userId_change}
                                        clientId_change={this.clientId_change}
                                        categories_changed={this.categories_changed}
                                        tags_changed={this.tags_changed}
                                        checkAuthentication={this.props.checkAuthentication}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={this.update_click}
                                        className={classNames(classes.button,classes.update)}
                                        variant="contained"
                                    >
                                        <ContentSave className={classes.leftIcon} />
                                        Enregistrer les modifications
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

ProjectViewOne.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(ProjectViewOne);
