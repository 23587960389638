import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";

import { entityDisplayNames,
    entityAttributeName } from "variables/general.jsx";


import {
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@material-ui/core";

import {
    CheckboxBlank,
} from "mdi-material-ui"


const styles = theme => ({
	tableWrapper: {
		overflowX: "auto",
		paddingLeft:"20px",
		paddingTop:"20px"
	},
    collapsibleRow:{
        paddingBottom:"0!important",
        height:'auto',
    },
    unCollapsedRow:{
        '& tr:not(:last-of-type)':{
            '& td':{
                border:'none',
            }
        },
    },
    collapsibleHeadRow:{
        cursor:'pointer',
        '& td':{
            fontSize:'1em'
        }
    },
    totalRow:{
        '& td':{
            fontSize:'1.2em',
            fontWeight:'bold'
        }
    },
    tableHead:{
        '& th':{
            fontSize:'1.2em',
            fontWeight:'bold'
        }
    },
});


class PrintableCollapsibleTableCrossedData extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            unCollapsedEntities:[]
        };
    }

    //======================= Page Render ========================

    render(){

		const { classes } = this.props;
        return(
            <React.Fragment>
                <div className={classes.tableWrapper}>
                    <Typography variant='h3'>
                        {this.props.tableTitle}
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                <TableCell
                                    padding='checkbox'
                                ></TableCell>
                                <TableCell>{entityDisplayNames[this.props.rowEntityName]}</TableCell>
                                {this.props.colsEntities.map(entity => (
                                    <TableCell key={'head-'+entity.id}>{entity[entityAttributeName[this.props.colEntityName]]}</TableCell>
                                ))}
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        {this.props.rowsEntities.map(rowEntity => (
                            <TableBody
                                key={'row-'+rowEntity.id}
                                className={classes.unCollapsedRow}
                            >
                                <TableRow
                                    hover
                                    className={classNames(classes.collapsibleRow,classes.collapsibleHeadRow)}
                                >
                                    <TableCell
                                        padding='checkbox'
                                    >
                                    </TableCell>
                                    <TableCell
                                    >{rowEntity[entityAttributeName[this.props.rowEntityName]]}</TableCell>
                                    {this.props.colsEntities.map(colEntity => (
                                        <TableCell
                                            key={'rowCol-'+rowEntity.id+'-'+colEntity.id}
                                        >{rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id) !== undefined ? rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id).hoursSum : 0} h</TableCell>
                                    ))}
                                    <TableCell
                                    >{rowEntity.hoursSum} h</TableCell>
                                </TableRow>
                                {rowEntity[this.props.collapsedEntityName].map(collapsedEntity => (
                                    <TableRow
                                        key={'collapsed-'+collapsedEntity.id}
                                        hover
                                        className={classes.collapsibleRow}
                                    >
                                        <TableCell
                                            padding='checkbox'
                                        >
                                            {collapsedEntity.color !== undefined ?
                                                <CheckboxBlank
                                                    style={{
                                                        color:collapsedEntity.color,
                                                    }}
                                                />
                                            :null}
                                        </TableCell>
                                        <TableCell
                                        >
                                            {collapsedEntity[entityAttributeName[this.props.collapsedEntityName]]}
                                        </TableCell>
                                        {this.props.colsEntities.map(colEntity => (
                                            <TableCell
                                                key={'collapsedCol-'+collapsedEntity.id+'-'+colEntity.id}
                                            >{
                                                rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id) !== undefined ?
                                                (rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id)[this.props.collapsedEntityName].find(collapsedOfCol => collapsedOfCol.id === collapsedEntity.id) !== undefined ? rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id)[this.props.collapsedEntityName].find(collapsedOfCol => collapsedOfCol.id === collapsedEntity.id).hoursSum : 0) : 0
                                            } h</TableCell>
                                        ))}
                                        <TableCell
                                        >{collapsedEntity.hoursSum} h</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ))}
                        <TableBody className={classes.totalRow}>
                            <TableRow>
                                <TableCell
                                    padding='checkbox'
                                ></TableCell>
                                <TableCell>Total</TableCell>
                                {this.props.resultTotal[this.props.colEntityName] && this.props.colsEntities.map(colEntity => (
                                    <TableCell key={'total-'+colEntity.id}>{this.props.resultTotal[this.props.colEntityName].find(totalCol => colEntity.id === totalCol.id) !== undefined ? this.props.resultTotal[this.props.colEntityName].find(totalCol => colEntity.id === totalCol.id).hoursSum : 0} h</TableCell>
                                ))}
                                <TableCell>{this.props.resultTotal.hoursSum} h</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>

                </div>
            </React.Fragment>
        )
    }
}

PrintableCollapsibleTableCrossedData.propTypes = {
    tableTitle:PropTypes.string.isRequired,
    rowEntityName:PropTypes.string.isRequired,
    colEntityName:PropTypes.string.isRequired,
    collapsedEntityName:PropTypes.string.isRequired,
    rowsEntities:PropTypes.array.isRequired,
    colsEntities:PropTypes.array.isRequired,
    resultTotal:PropTypes.object.isRequired,
};
export default withStyles(styles,{withTheme: true})(PrintableCollapsibleTableCrossedData);
