import CryptoJS from "crypto-js";

const passphrase = "thisisapassphrase";

class UserManager {

    constructor(connectionManager, logger) {
        this.logger = logger;
        this.connectionManager = connectionManager;
        this.permissions = null;
        this.connectionManager.get('permissionsDetails',undefined).then((response)=>{
            this.permissions = response.data;
        });
    }

    logout(username,token) {
        if (username !== undefined && typeof username !== 'string') {
			throw new TypeError(`username is wrong : ${typeof username} is not a string`);
        }

        return this.connectionManager.post('logout',{
            username: username,
            token: token
        },undefined).then((response) => {
            return response;
        }).catch((error) => {
            throw error;
        });
    }

    login(username, password) {
        if (username !== undefined && typeof username !== 'string') {
			throw new TypeError(`username is wrong : ${typeof username} is not a string`);
		}
        if (password !== undefined && typeof password !== 'string') {
			throw new TypeError(`password is wrong : ${typeof password} is not a string`);
        }
        // let hash = CryptoJS.SHA3(password).toString();
        let hash = password;
        let encryptedHash = CryptoJS.HmacSHA3(passphrase, hash).toString();
        return this.connectionManager.post('login',{
            username:username,
            password:encryptedHash
        },undefined).then((user) => {
            return user;
        }).catch((error) => {
            this.logger.debug(error);
            throw error;
        });
    }

    tryToken(username, token) {
        if (username !== undefined && typeof username !== 'string') {
			throw new TypeError(`username is wrong : ${typeof username} is not a string`);
        }

        return this.connectionManager.post('tryToken',{
            username: username,
            token: token
        },undefined).then((response) => {
            return response;
        }).catch((error) => {
            throw error;
        });
    }
}
export default UserManager;