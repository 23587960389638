import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
	withStyles,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography
} from "@material-ui/core";

import {
	Delete,
	CloseCircle
} from "mdi-material-ui"

const style_override = theme => ({
	deletion:{
		backgroundColor: theme.button.deletion,
		color: theme.palette.primary.contrastText
	},
});

class AuthenticationDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: ""
		};
	}

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
			>
				<DialogTitle id="form-dialog-title">{this.props.permanent ? 'Suppression' : 'Désactivation'}</DialogTitle>
				<form>
					<DialogContent>
						<Typography variant='body1'>
							Êtes-vous sûr de {this.props.permanent ? 'supprimer' : 'désactiver'} cet élément ?
						</Typography>
						{this.props.permanent ?
							<Typography variant='body1'>
								La supression sera définitive !
							</Typography>
						: <Typography variant='body1'>
							La désactivation ne sera pas définitive et l'élément pourra être ré-activé.
						</Typography>}
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.props.deleteHandler}
							className={classNames(classes.button,classes.deletion)}
							variant="contained"
						>
							<Delete className={classes.leftIcon} />
							{this.props.permanent ? 'Supprimer' : 'Désactiver'}
						</Button>
						<Button
							onClick={this.props.closeHandler}
							color="secondary"
							className={classes.button}
							variant="contained"
						>
							<CloseCircle className={classes.leftIcon} />
							Annuler
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

AuthenticationDialog.propTypes = {
	permanent: PropTypes.bool.isRequired,
	deleteHandler: PropTypes.func.isRequired,
	closeHandler: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(style_override,{withTheme: true})(AuthenticationDialog);
