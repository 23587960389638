import React from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import {Card,
    CardHeader,
    CardContent,
    Button,
    Grid
} from '@material-ui/core';

import {
    ViewList,
    Plus
} from "mdi-material-ui"

import { entityDisplayNames } from "variables/general.jsx";


const styles = theme => ({
    cardContent:{
        textAlign: "center"
    },
    button: {
        margin: theme.spacing(),
    },
    creation:{
        backgroundColor: theme.button.creation,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    }
});

class AdminControl extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            count:0,
        }
        this.creationRight = this.props.user.adminPermission[this.props.entityName].createUpdate;

    }

    componentDidMount = () => {
		this.props.apiController.getAll({
            auth_username:this.props.user.username,
            auth_token:this.props.user.token
        }).then((response)=>{
            this.setState({count:response.data.length});
        }).catch((error) => {
            this.props.checkAuthentication();
        });
    }

    //======================= UI Callback ========================

    list_clicked = () => {
        this.props.history.push('/list/'+this.props.entityName);
    }

    create_clicked = () => {
        this.props.history.push('/'+this.props.entityName+'/create');
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid item xs={3}>
                <Card className={classes.cardContent}>

                    <CardHeader
                        title={entityDisplayNames[this.props.entityName]+" : "+this.state.count}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    onClick={this.list_clicked}
                                >
                                    <ViewList className={classes.leftIcon} />
                                    Liste
                                </Button>
                            </Grid>
                            {this.creationRight ?
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        className={classNames(classes.button,classes.creation)}
                                        onClick={this.create_clicked}
                                    >
                                        <Plus className={classes.leftIcon} />
                                        Créer
                                    </Button>
                                </Grid>
                            : null}
                        </Grid>

                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

AdminControl.propTypes = {
    user: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    entityName: PropTypes.string.isRequired,
    apiController: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(AdminControl);
