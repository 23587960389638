import React, { Children } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import UpdateEventDialog from 'components/Events/UpdateEventDialog.jsx';

import PrintableCalendar from 'components/Events/PrintableCalendar.jsx';

import moment from 'moment';
import momentBusinessDays from 'moment-business-days';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './EventsCalendar.css'
import ReactToPrint from 'react-to-print';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import { IconButton, Typography, Button } from "@material-ui/core";

import{
	ChevronLeft,
	ChevronRight,
	CalendarToday,
	Printer,
} from 'mdi-material-ui';

const styles = theme => ({
	printVersion:{
		display:'none'
	},
	printButton:{
		backgroundColor: theme.palette.secondary.main+"!important",
		color: theme.palette.secondary.contrastText+"!important"
	}
});

export let navigate = {
	PREVIOUS: 'PREV',
	NEXT: 'NEXT',
	TODAY: 'TODAY',
}

const localizer = momentLocalizer(moment);

class CustomToolbar extends React.Component {

	//======================= UI Callback ========================

	navigate = action => {
		this.props.onNavigate(action);
	}

	changeVisualisation = visualisation => {
		this.props.changeVisualisation(visualisation);
	}

    //======================= Page Render ========================

	render() {
		let { label, componentToPrintRef, classes} = this.props
		return(
			<div className="rbc-toolbar">
				<span className="rbc-btn-group">
					<IconButton
						onClick={this.navigate.bind(null, navigate.PREVIOUS)}
					>
						<ChevronLeft/>
					</IconButton>
					<IconButton
						onClick={this.navigate.bind(null, navigate.TODAY)}
					>
						<CalendarToday/>
					</IconButton>
					<IconButton
						onClick={this.navigate.bind(null, navigate.NEXT)}
					>
						<ChevronRight/>
					</IconButton>
				</span>
				<span className="rbc-toolbar-label">
					<Typography variant="h2">
						{label}
					</Typography>
				</span>

				<ReactToPrint
					trigger={() => (
						<Button
							variant="contained"
							color="secondary"
							className={classes.printButton}
						>
							<Printer/>
						</Button>
					)}
					content={() => componentToPrintRef}
				/>
			</div>
		)
	}
}

class EventsCalendar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			deleteEventDialog_state: false,
			deleteEvent:undefined,
			selectedDate: new Date(),
		};
	}

	//======================= UI Callback ========================

	changeCalendarRange_handler = (date) => {
		let start_date = moment(date.start);
		let end_date = moment(date.end);
		// if the first date is not the searched month
		if(start_date.date() > 15){
			start_date.add(1,'month').startOf('month');
			end_date = moment(start_date);
			end_date.endOf('month');
			// if the last date is not the searched month
		} else if(end_date.date() < 15){
			end_date.subtract(1,'month').endOf('month');
			start_date = moment(end_date);
			start_date = start_date.startOf('month');
		}
		this.props.rangeChange_handler({from:new Date(start_date),to:new Date(end_date)})
	}

	deleteEventDialogOpen_handler = (event) => {
        this.setState({
            deleteEvent: event,
            deleteEventDialog_state: true
        });
	}

	deleteEventDialogClose_handler = (event) => {
		this.setState({
			deleteEventDialog_state: false
		})
		this.props.deleteEvent_handler(event);
	}

	calendarDayChange_handler = (date) => {
		let selectedDate = date;
		this.setState({selectedDate: selectedDate});
        this.props.dateChange_handler(selectedDate);
	}

	calendarDayClick_handler = (date) => {
		let selectedDate = date.start;
		this.calendarDayChange_handler(selectedDate);
	}

    //======================= Page Render ========================

	render() {

		const { classes } = this.props;
		return (
			<React.Fragment>
				<div className={classes.printVersion}>
					<PrintableCalendar
						selectedDate={this.state.selectedDate}
						events={
							this.props.events
						}
						ref={el => (this.componentToPrintRef = el)}
					/>
				</div>
				<UpdateEventDialog
					deleteHandler={this.deleteEventDialogClose_handler}
					apiControllers={this.props.apiControllers}
					notifier={this.props.notifier}
					open={this.state.deleteEventDialog_state}
					logger={this.props.logger}
					user={this.props.user}
					event={this.state.deleteEvent}
					checkAuthentication={this.props.checkAuthentication}
				/>
				<Calendar
					selectable={true}
					localizer={localizer}
					events={this.props.events}
					titleAccessor={event => {
                        return (<span>
                            {event.title}
                        </span>)
                    }}
					tooltipAccessor={event => {
                        return (<span>
                            {event.title}
                        </span>)
					}}
					resourceTitleAccessor={event => {
                        return (<span>
                            {event.title}
                        </span>)
					}}

					startAccessor={event => {
						return event.date;
					}}
					endAccessor={event => {
						return event.date;
					}}
					views={['month']}
					drilldownView={null}
					popup={true}
					onRangeChange={this.changeCalendarRange_handler}
					onNavigate={this.calendarDayChange_handler}
					onSelectSlot={this.calendarDayClick_handler}
					onSelectEvent={event => {
                        this.deleteEventDialogOpen_handler(event)
					}}
					date={this.state.selectedDate}
					timeslots={50}
					components={{
						toolbar: (props) => (
							<CustomToolbar
								{...props}
								componentToPrintRef={this.componentToPrintRef}
								classes={classes}
							/>
						),
						month:{
							dateHeader: (props) => {
								return(
									<div className="dayHeaderContainer">
										<span className="dayNumber">
											{props.label}
										</span>
									</div>
								)
							}
						},
						dateCellWrapper: (props) => {
							const classNameToAdd = momentBusinessDays(props.value).isBusinessDay() ? "" : "rbc-off-range-bg";
							return(
								React.cloneElement(Children.only(props.children), {
									className: props.children.props.className+" "+(props.children.props.className.includes(classNameToAdd) ? '' : classNameToAdd)
								})
							)
						}
					}}
					eventPropGetter={
						(event, start, end, isSelected) => {
							let newStyle = {
								backgroundColor: '#CE8888',
							};
							return {
								className: "",
								style: newStyle
							};
						}
					}
				/>
			</React.Fragment>

		);
	}
}

EventsCalendar.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object,
	notifier: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object,
	checkAuthentication:PropTypes.func,
	rangeChange_handler:PropTypes.func.isRequired,
	dateChange_handler:PropTypes.func,
	deleteEvent_handler:PropTypes.func,
	events:PropTypes.array.isRequired,
};

export default withStyles(styles, {withTheme: true})(EventsCalendar);
