import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
} from '@material-ui/core';

import {
    CalendarCheckOutline,
    AccountSearch,
    AccountClock,
    FolderClock,
    ChartLine,
    BriefcaseSearch,
    BriefcaseAccount,
    FolderAccount,
    Briefcase
} from "mdi-material-ui"

const styles = theme => ({
    cardContent:{
        textAlign: "center"
    },
    button: {
        margin: theme.spacing(),
        width: "100%"
    },
    leftIcon: {
        marginRight: theme.spacing(),
    }
});


class ReportingPanel extends React.Component {

    //======================= UI Callback ========================

    calendar_clicked = () => {
        this.props.history.push('/reporting/users/calendar');
    }

    chart_clicked = () => {
        this.props.history.push('/reporting/users/chart');
    }

    user_clicked = () => {
        this.props.history.push('/reporting/users/oneUserCalendar');
    }

    userChart_clicked = () => {
        this.props.history.push('/reporting/users/oneUserChart');
    }

    projectChart_clicked = () => {
        this.props.history.push('/reporting/projects/oneProjectChart');
    }

    usersByProjects_clicked = () => {
        this.props.history.push('/reportingCrossTable/users/projects/categories');
    }

    usersByCategories_clicked = () => {
        this.props.history.push('/reportingCrossTable/users/categories/projects');
    }


    projectsByUsers_clicked = () => {
        this.props.history.push('/reportingCrossTable/projects/users/categories');
    }

    projectsByCategories_clicked = () => {
        this.props.history.push('/reportingCrossTable/projects/categories/users');
    }

    categoriesByUsers_clicked = () => {
        this.props.history.push('/reportingCrossTable/categories/users/projects');
    }

    categoriesByProjects_clicked = () => {
        this.props.history.push('/reportingCrossTable/categories/projects/users');
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Panneau de reporting"}
                        />
                    </Card>
                </Grid>

                {(this.props.user.adminPermission.timespents.users.view.others || this.props.user.adminPermission.timespents.users.createUpdate.others) &&
                <Grid item xs={3}>
                    <Card className={classes.cardContent}>

                        <CardHeader
                            title="Utilisateurs"
                        />
                        <CardContent>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.calendar_clicked}
                                    >
                                        <CalendarCheckOutline className={classes.leftIcon} />
                                        Calendrier général
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.user_clicked}
                                    >
                                        <AccountSearch className={classes.leftIcon}/>
                                        Vue utilisateur
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.userChart_clicked}
                                    >
                                        <ChartLine className={classes.leftIcon}/>
                                        Courbe annuelle utilisateur
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.usersByProjects_clicked}
                                    >
                                        <BriefcaseAccount className={classes.leftIcon} />
                                        Suivi utilisateurs / projet
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.usersByCategories_clicked}
                                    >
                                        <FolderAccount className={classes.leftIcon} />
                                        Suivi utilisateurs / catégories
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
                }


                {(this.props.user.projects.length > 0 || this.props.user.adminPermission.timespents.users.view.others) &&
                <Grid item xs={3}>
                    <Card className={classes.cardContent}>

                        <CardHeader
                            title="Projets"
                        />
                        <CardContent>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.projectsByUsers_clicked}
                                    >
                                        <AccountClock className={classes.leftIcon} />
                                        Suivi projet / utilisateurs
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.projectsByCategories_clicked}
                                    >
                                        <FolderClock className={classes.leftIcon} />
                                        Suivi projet / catégories
                                    </Button>
                                </Grid>
                                <Grid item xs={10}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        onClick={this.projectChart_clicked}
                                    >
                                        <BriefcaseSearch className={classes.leftIcon}/>
                                        Vue Projet
                                    </Button>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                </Grid>
                }

                {(this.props.user.adminPermission.timespents.users.view.others) &&
                    <Grid item xs={3}>
                        <Card className={classes.cardContent}>

                            <CardHeader
                                title="Catégories"
                            />
                            <CardContent>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={10}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.categoriesByUsers_clicked}
                                        >
                                            <AccountClock className={classes.leftIcon} />
                                            Suivi catégories / utilisateurs
                                        </Button>
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.categoriesByProjects_clicked}
                                        >
                                            <Briefcase className={classes.leftIcon} />
                                            Suivi catégories / projets
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                }</Grid>
        );
    }
}

ReportingPanel.propTypes = {
    user: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(ReportingPanel);
