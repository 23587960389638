import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';

import { ChromePicker } from 'react-color'
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Card,
    CardContent,
    CardHeader,
    InputAdornment
} from '@material-ui/core';

import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';

import "moment/locale/fr";
import MomentUtils from '@date-io/moment';

const styles = theme => ({
    colorPicker: {
        width: '90% !important'
    },
    colorResult:{
        height: '100%'
    },
    categoriesTagsCard:{
        overflow: 'visible!important',
        width:"100%"
    },
});

class BasicProjectForm extends React.Component {

    constructor(props){
        super(props);

		this.state = {
            categories: [],
            tags: [],
            users: [],
            clients:[]
		}
	}
    componentDidMount = () => {
        this.props.apiControllers.categories.getAll({
            auth_username:this.props.auth_user.username,
            auth_token:this.props.auth_user.token,
        }).then((response)=>{
				this.setState({
                    categories: response.data
                });
            }).catch((error) => {
                if(error.status === 403){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });
        this.props.apiControllers.tags.getAll({
            auth_username:this.props.auth_user.username,
            auth_token:this.props.auth_user.token,
        }).then((response)=>{
                this.setState({
                    tags: response.data
                });
            }).catch((error) => {
                if(error.status === 403){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });
        this.props.apiControllers.users.getAll({
            auth_username:this.props.auth_user.username,
            auth_token:this.props.auth_user.token,
        }).then((response)=>{
                this.setState({users: response.data});
            }).catch((error) => {
                if(error.status === 403){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });
        this.props.apiControllers.clients.getAll({
            auth_username:this.props.auth_user.username,
            auth_token:this.props.auth_user.token,
        }).then((response)=>{
                this.setState({clients: response.data});
            }).catch((error) => {
                if(error.status === 403){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });

    }

	//======================= Tool Functions ========================

    filterTags = () => (
        this.state.tags.filter(tag => {
            return((!tag.disabled) && this.props.selectedCategories.reduce((accumulator, cat) => {
                return([...accumulator, ...cat.tags])
            },[]).find(resultedTag => tag.id === resultedTag.id))
        })
    )

	//======================= UI Callback ========================

    selectAllCategories_handler = () => {
        this.categoriesChange_handler(this.state.categories.filter(category => (!category.disabled)))
    }

    categoriesChange_handler = (categories) => {
        this.props.categories_changed(categories);
    }

    selectAllTags_handler = () => {
        this.tagsChange_handler(this.filterTags());
    }

    tagsChange_handler = (tags) => {
        this.props.tags_changed(tags);
    }

	//======================= Page Render ========================


    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid container spacing={2} item xs={4}>
                    <Grid container spacing={2} item xs={6}>
                        <Grid item xs={12}>
                            <TextField
                                id="displayname-input"
                                label="Nom"
                                value={this.props.name}
                                onChange={this.props.name_change}
                                margin="normal"
                                error={this.props.name_errorMessage !== "" ? true : false}
                                helperText={this.props.name_errorMessage}
                                />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id="description-input"
                                label="Description"
                                multiline
                                value={this.props.description}
                                onChange={this.props.description_change}
                                margin="normal"
                                error={this.props.description_errorMessage !== "" ? true : false}
                                helperText={this.props.description_errorMessage}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}
                                error={this.props.user_errorMessage!==""}
                            >
                                <InputLabel htmlFor="user-select">Manager</InputLabel>
                                <Select
                                    value={this.props.userId}
                                    onChange={this.props.userId_change}
                                    inputProps={{
                                        name: 'manager',
                                        id: 'user-select',
                                    }}
                                >
                                    <MenuItem value={-1}>None</MenuItem>
                                    {this.state.users.sort((a,b)=>{
                                        let nameA = a.displayname.toUpperCase();
                                        let nameB = b.displayname.toUpperCase();
                                        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                                    }).map((user)=>(
                                        <MenuItem key={user.id} value={user.id}>{user.displayname}</MenuItem>

                                    ))}
                                </Select>
                                {this.props.user_errorMessage !== "" ?
                                    <FormHelperText>{this.props.user_errorMessage}</FormHelperText>
                                : null}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}
                            >
                                <InputLabel htmlFor="client-select">Client</InputLabel>
                                <Select
                                    value={this.props.clientId}
                                    onChange={this.props.clientId_change}
                                    inputProps={{
                                        name: 'client',
                                        id: 'client-select',
                                    }}
                                >
                                    <MenuItem value={-1}>Aucun</MenuItem>
                                    {this.state.clients.sort((a,b)=>{
                                        let nameA = a.name.toUpperCase();
                                        let nameB = b.name.toUpperCase();
                                        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
                                    }).map((client)=>(
                                        <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>

                                    ))}
                                </Select>
                                {this.props.client_errorMessage !== "" ?
                                    <FormHelperText>{this.props.client_errorMessage}</FormHelperText>
                                : null}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} item xs={6}>
                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        margin="normal"
                                        label="Date de début"
                                        value={this.props.date_start}
                                        onChange={this.props.date_start_change}
                                        format="DD/MM/YYYY"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        margin="normal"
                                        label="Date de fin"
                                        value={this.props.date_end}
                                        onChange={this.props.date_end_change}
                                        format="DD/MM/YYYY"
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Nombre de jours"
                                value={this.props.daysCount}
                                onChange={this.props.daysCount_change}
                                margin="normal"
                                type="number"
                                error={this.props.daysCount_errorMessage !== "" ? true : false}
                                helperText={this.props.daysCount_errorMessage}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        step: 1
                                    },
                                    endAdornment: <InputAdornment position="end">j</InputAdornment>
                                }}
                            />
                        </Grid>

                    </Grid>
                </Grid>
                <Grid container spacing={2} item xs={4}>
                    <Card
                        className={classes.categoriesTagsCard}
                    >
                        <CardHeader
                            title={"Catégories / Tags"}
                        />
                        <CardContent
                        >
                            <AutoCompleteHightlight
                                suggestions={this.state.categories.filter(category => (!category.disabled))}
                                change_handler={this.categoriesChange_handler}
                                selectedValue={this.props.selectedCategories}
                                errorMessage={this.props.categories_errorMessage}
                                disabled={false}
                                label="Catégories :"
                                isMulti={true}
                                selectAll={true}
                                selectAll_handler={this.selectAllCategories_handler}
                            />
                            <AutoCompleteHightlight
                                suggestions={this.filterTags()}
                                change_handler={this.tagsChange_handler}
                                selectedValue={this.props.selectedTags}
                                errorMessage=""
                                disabled={false}
                                label="Tags :"
                                isMulti={true}
                                selectAll={true}
                                selectAll_handler={this.selectAllTags_handler}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={4}>

                    <Grid item xs={6}>
                        <ChromePicker
                            disableAlpha={true}
                            className={classes.colorPicker}
                            color={ this.props.color }
                            onChangeComplete={ this.props.color_change }
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Card
                            className={classes.colorResult}
                            style={{backgroundColor: this.props.color}}
                        >
                            <CardContent
                            ></CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

BasicProjectForm.propTypes = {
    checkAuthentication: PropTypes.func.isRequired,
    apiControllers: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    date_start: PropTypes.object.isRequired,
    date_end: PropTypes.object.isRequired,
    daysCount:PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    selectedCategories: PropTypes.array.isRequired,
    selectedTags: PropTypes.array.isRequired,
    userId: PropTypes.number.isRequired,
    clientId: PropTypes.number,
    auth_user: PropTypes.object.isRequired,
    name_errorMessage: PropTypes.string.isRequired,
    description_errorMessage: PropTypes.string.isRequired,
    daysCount_errorMessage: PropTypes.string.isRequired,
    user_errorMessage: PropTypes.string.isRequired,
    categories_errorMessage: PropTypes.string.isRequired,
    name_change: PropTypes.func.isRequired,
    description_change: PropTypes.func.isRequired,
    date_start_change: PropTypes.func.isRequired,
    date_end_change: PropTypes.func.isRequired,
    daysCount_change:PropTypes.func.isRequired,
    color_change: PropTypes.func.isRequired,
    userId_change: PropTypes.func.isRequired,
    clientId_change: PropTypes.func.isRequired,
    categories_changed: PropTypes.func.isRequired,
    tags_changed: PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(BasicProjectForm);
