import React from 'react';

import { Switch, Redirect } from "react-router-dom";
import RouteWrapper from "utils/RouteWrapper.jsx"

import Header from "components/Header/Header.jsx";

import { withStyles, Typography} from '@material-ui/core';

import Logger from "utils/logger.js";
import AuthenticationDialog from "components/AuthenticationDialog/AuthenticationDialog.jsx";
import Notifier from 'components/Notifier/Notifier.jsx';
import LoadingDialog from 'components/LoadingDialog/LoadingDialog.jsx'

import UserManager from "utils/userManager.js"
import ConnectionManager from 'utils/connectionManager.js';
import UserAPIController from 'utils/APIControllers/usersAPIController';
import UserContractAPIController from 'utils/APIControllers/userContractsAPIController';
import ProjectAPIController from 'utils/APIControllers/projectsAPIController';
import CategoryAPIController from 'utils/APIControllers/categoriesAPIController';
import TimespentAPIController from 'utils/APIControllers/timespentsAPIController';
import TagAPIController from 'utils/APIControllers/tagsAPIController';
import EventsAPIController from 'utils/APIControllers/eventsAPIController';
import ClientsAPIController from 'utils/APIControllers/clientsAPIController';

import mainRoutes from "routes/mainRoutes.jsx";
import "./MainLayout.css";

const styles = (theme) => ({
	footer:{
		backgroundColor:theme.palette.secondary.main
	}
});

class App extends React.Component {

	constructor(props){
		super(props);
		this.logger = new Logger();
		this.connectionManager = new ConnectionManager(this.logger);
		this.userManager = new UserManager(this.connectionManager, this.logger);
		this.apiControllers = {
			users: new UserAPIController(this.connectionManager, this.logger),
			userContracts: new UserContractAPIController(this.connectionManager, this.logger),
			projects: new ProjectAPIController(this.connectionManager, this.logger),
			categories: new CategoryAPIController(this.connectionManager, this.logger),
			timespents: new TimespentAPIController(this.connectionManager, this.logger),
			tags: new TagAPIController(this.connectionManager, this.logger),
			events: new EventsAPIController(this.connectionManager, this.logger),
			clients: new ClientsAPIController(this.connectionManager, this.logger),
		}

		this.state = {
			loggedin: false,
			authenticationDialog_state: false,
			loadingDialog_state:false,
			notificationState: false,
			notificationMessage: "test",
			notificationType: "info",
			headerName: "home",
			adminConfig: false,
			viewReport: false
		}

		this.notifier = {
			error: this.notifyError,
			warning: this.notifyWarning,
			success: this.notifySuccess,
			info: this.notifyInfo
		}

		this.loadingDialog= {
			open: this.loadingDialog_open,
			close: this.loadingDialog_close
		}

		this.user = {};
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	switchRoutes = (passedProps) =>{
		let toReturn = (
			<Switch>
				{
					mainRoutes.map((prop, key) => {
						if (prop.redirect)
						{
							return <Redirect from={prop.path} to={prop.to} key={key}/>;
						}
						return <RouteWrapper path={prop.path} Component={prop.component} key={key} passedProps={passedProps}/>
					})
				}
			</Switch>
		);
		return(toReturn);
	};

	componentDidMount = () => {
		this.checkAuthentication();
	}

	updateInnerUser = () => {
		return this.apiControllers.users.getById(this.user.id,{
			auth_username:this.user.username,
            auth_token:this.user.token
        }).then((response)=>{
			let newUser = response.data;
			newUser.token = this.user.token;
			this.user = newUser;
			localStorage.setItem('user',JSON.stringify(newUser));
        }).catch((error) => {
			this.logger.error(error);
            if(error.status === 403 || error.status === 404 || error.status === 400){
                this.notifier.error('Accès interdit');
                this.history.push('/');
            }else{
                this.checkAuthentication();
            }
        });
	}

	//============== Notifier ====================

	notifyError = (message) => {
		this.setState({notificationMessage:message, notificationState: true, notificationType: "error"});
	}

	notifyWarning = (message) => {
		this.setState({notificationMessage:message, notificationState: true, notificationType: "warning"});
	}

	notifySuccess = (message) => {
		this.setState({notificationMessage:message, notificationState: true, notificationType: "success"});
	}

	notifyInfo = (message) => {
		this.setState({notificationMessage:message, notificationState: true, notificationType: "info"});
	}

	closeNotification = () => {
		this.setState({notificationState: false});
	}

	loadingDialog_open = () => {
		return this.promisedSetState({loadingDialog_state: true});
	}

	loadingDialog_close = () => {
		return this.promisedSetState({loadingDialog_state: false});
	}

	// ==================== Authentication ===================

	checkAuthentication = () => {
		let user = JSON.parse(localStorage.getItem('user'));
		if(user !== null && user.token !== null){
			this.userManager.tryToken(user.username, user.token)
			.then((res) => {
				this.loggedin_callback(user);
			})
			.catch((error) => {
				// this.user.token = null;
				let usernameToSave = this.user.username;
				this.user = null;
				this.user = {username:usernameToSave};
				localStorage.setItem('user',JSON.stringify(this.user));
				// localStorage.removeItem('user');
				this.user = null;
				this.setState({loggedin: false});
				this.authenticationDialog_open();
			});
		}else{
			this.setState({loggedin: false});
			this.authenticationDialog_open();
		}
	}

	authenticationDialog_open = () => {
		this.setState({authenticationDialog_state:true});
	}

	authenticationDialog_close = () => {
		this.setState({authenticationDialog_state:false});
	}

	loggedin_callback = (user) => {
		this.user = user;
		localStorage.setItem('user',JSON.stringify(user));
		this.setState({adminConfig : (
            this.user.adminPermission !== undefined && (
            this.user.adminPermission.users.createUpdate ||
            this.user.adminPermission.users.disable ||
            this.user.adminPermission.projects.createUpdate ||
            this.user.adminPermission.projects.disable ||
            this.user.adminPermission.categories.createUpdate ||
            this.user.adminPermission.categories.disable ||
            this.user.adminPermission.tags.createUpdate ||
            this.user.adminPermission.tags.disable ||
            this.user.adminPermission.clients.createUpdate ||
            this.user.adminPermission.clients.disable ||
            this.user.adminPermission.events.createUpdate ||
            this.user.adminPermission.events.disable
		)),viewReport:(
			this.user.adminPermission !== undefined && (
			this.user.projects.length > 0 ||
			this.user.adminPermission.timespents.users.view.others ||
			this.user.adminPermission.timespents.users.createUpdate.others ||
			this.user.adminPermission.timespents.absences.view.others ||
			this.user.adminPermission.timespents.absences.createUpdate.others
			))
		});
		this.authenticationDialog_close();
		this.setState({loggedin: true});
		this.notifier.success('Connexion réussie');
	}

	loggedout = () => {

		// this.user.token = null;
		let usernameToSave = this.user.username;
		this.user = null;
		this.user = {username:usernameToSave};
		localStorage.setItem('user',JSON.stringify(this.user));
		this.user = null;
		// localStorage.removeItem('user');
		this.setState({loggedin: false});
		this.authenticationDialog_open();
	}

	// =========== UI Callback ===============

	logout_handler = () => {
		this.userManager.logout(this.user.username, this.user.token)
			.then((res) => {
				this.notifier.success('Déconnexion réussie');
				this.loggedout();
			})
			.catch((error) => {
				if(error.status === 404){
					this.notifier.warning("Mauvais nom d'utilisateur / token");
				}else{
					this.notifier.error("Erreur 400");
				}
			});
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return(
			<div className="App">

				<Header
					logout_handler={this.logout_handler}
					openAuthenticationDialog_handler={this.authenticationDialog_open}
					loggedin={this.state.loggedin}
					user={this.user}
					history={this.props.history}
					headerName={this.state.headerName}
					logger={this.logger}
					adminConfig={this.state.adminConfig}
					viewReport={this.state.viewReport}
				/>

				<AuthenticationDialog
					closeHandler={this.authenticationDialog_close}
					open={this.state.authenticationDialog_state}
					userManager={this.userManager}
					loggedin_callback={this.loggedin_callback}
					notifier={this.notifier}
				/>

				<Notifier
					open={this.state.notificationState}
					message={this.state.notificationMessage}
					closeHandler={this.closeNotification}
					variant={this.state.notificationType}
				/>
				<LoadingDialog
					open={this.state.loadingDialog_state}
				/>
				{this.state.loggedin ?
					<div className="mainPage">{this.switchRoutes(
						{
							user:this.user,
							history:this.props.history,
							notifier:this.notifier,
							loadingDialog: this.loadingDialog,
							logger:this.logger,
							userManager:this.userManager,
							apiControllers:this.apiControllers,
							checkAuthentication: this.checkAuthentication,
							updateInnerUser:this.updateInnerUser
						})}</div>
				:null}
				<footer
					className={classes.footer}
				>
					<Typography>
						Big Brother - {process.env.REACT_APP_VERSION}
					</Typography>
				</footer>
			</div>
		);
	}
}

export default withStyles(styles,{withTheme:true})(App);