import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { ChromePicker } from 'react-color'
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Card,
    CardContent,
} from '@material-ui/core';

const styles = theme => ({
    colorPicker: {
        width: '90% !important'
    },
    colorResult:{
        height: '100%'
    },
});

class BasicProjectForm extends React.Component {

    constructor(props){
        super(props);

		this.state = {
            users: [],
		}
	}
    componentDidMount = () => {
        this.props.apiControllers.users.getAll({
            auth_username:this.props.auth_user.username,
            auth_token:this.props.auth_user.token,
        }).then((response)=>{
            this.setState({users: response.data});
        }).catch((error) => {
            if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid container spacing={2} item xs={4}>
                    <Grid item xs={12}>
                        <TextField
                            id="displayname-input"
                            label="Nom"
                            value={this.props.name}
                            onChange={this.props.name_change}
                            margin="normal"
                            error={this.props.name_errorMessage !== "" ? true : false}
                            helperText={this.props.name_errorMessage}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="description-input"
                            label="Description"
                            multiline
                            value={this.props.description}
                            onChange={this.props.description_change}
                            margin="normal"
                            error={this.props.description_errorMessage !== "" ? true : false}
                            helperText={this.props.description_errorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl className={classes.formControl}
                            error={this.props.user_errorMessage!==""}
                        >
                            <InputLabel htmlFor="user-select">Interlocuteur</InputLabel>
                            <Select
                                value={this.props.userId}
                                onChange={this.props.userId_change}
                                inputProps={{
                                    name: 'contactUser',
                                    id: 'user-select',
                                }}
                            >
                                <MenuItem value={-1}>None</MenuItem>
                                {this.state.users.map((user)=>(
                                    <MenuItem key={user.id} value={user.id}>{user.displayname}</MenuItem>

                                ))}
                            </Select>
                            {this.props.user_errorMessage !== "" ?
                                <FormHelperText>{this.props.user_errorMessage}</FormHelperText>
                            : null}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item xs={4}>

                    <Grid item xs={6}>
                        <ChromePicker
                            disableAlpha={true}
                            className={classes.colorPicker}
                            color={ this.props.color }
                            onChangeComplete={ this.props.color_change }
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Card
                            className={classes.colorResult}
                            style={{backgroundColor: this.props.color}}
                        >
                            <CardContent
                            ></CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

BasicProjectForm.propTypes = {
    checkAuthentication: PropTypes.func.isRequired,
    apiControllers: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    auth_user: PropTypes.object.isRequired,
    name_errorMessage: PropTypes.string.isRequired,
    description_errorMessage: PropTypes.string.isRequired,
    user_errorMessage: PropTypes.string.isRequired,
    name_change: PropTypes.func.isRequired,
    description_change: PropTypes.func.isRequired,
    color_change: PropTypes.func.isRequired,
    userId_change: PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(BasicProjectForm);
