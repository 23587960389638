import React from 'react';
import PropTypes from 'prop-types';
import logo from 'assets/img/logo.png';
import './header.css';

import {
	withStyles,
	AppBar,
	Toolbar,
	Typography,
	Button,
	IconButton,
	MenuItem,
	Popper,
	Grow,
	Paper,
	MenuList,
	ClickAwayListener,
} from '@material-ui/core';

import { AccountBox, Settings, FileChart, Home } from 'mdi-material-ui';

const styles = {
	appBarTitle: {
		flexGrow: 1,
		display: 'inline',
	},
	userMenu: {
		zIndex: 5,
	},
};

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			toolbarProfileMenu_state: null,
		};
	}

	//======================= UI Callback ========================

	toolbarProfileMenu_open = event => {
		this.setState({ toolbarProfileMenu_state: event.currentTarget });
	};

	toolbarProfileMenu_close = () => {
		this.setState({ toolbarProfileMenu_state: null });
	};

	logout_click = () => {
		this.toolbarProfileMenu_close();
		this.props.logout_handler();
	};

	profile_clicked = () => {
		this.toolbarProfileMenu_close();
		this.props.history.push('/userProfile/' + this.props.user.id);
	};

	home_click = () => {
		this.props.history.push('/');
	};

	admin_click = () => {
		this.props.history.push('/admin');
	};

	reporting_click = () => {
		this.props.history.push('/reporting/panel');
	};

	//======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<AppBar position="static">
				<Toolbar>
					<img src={logo} alt="Big-brother" id="mainIcon" onClick={this.home_click} />
					<Typography variant="h6" color="inherit" className={classes.appBarTitle} onClick={this.home_click}>
						Big-Brother
					</Typography>
					{/* If the user is not logged in*/}
					{this.props.loggedin ? null : (
						<span>
							<Button color="inherit" onClick={this.props.openAuthenticationDialog_handler}>
								CONNEXION
							</Button>
						</span>
					)}
					{/* If the user is logged in */}
					{this.props.loggedin ? (
						<span color="inherit">
							<Typography variant="h6" color="inherit" className={classes.appBarTitle}>
								{this.props.user.displayname}
							</Typography>

							<IconButton color="inherit" onClick={this.home_click}>
								<Home color="inherit" />
							</IconButton>
							<IconButton
								aria-owns={this.state.toolbarProfileMenu_state ? 'toolbarProfileMenu' : undefined}
								aria-haspopup="true"
								onClick={this.toolbarProfileMenu_open}
								color="inherit"
							>
								<AccountBox color="inherit" />
							</IconButton>
							<Popper
								id="toolbarProfileMenu"
								anchorEl={this.state.toolbarProfileMenu_state}
								open={Boolean(this.state.toolbarProfileMenu_state)}
								placement="bottom-end"
								transition
								disablePortal
								className={classes.userMenu}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										id="menu-list-grow"
										{...TransitionProps}
										style={{
											transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
										}}
									>
										<Paper>
											<ClickAwayListener onClickAway={this.toolbarProfileMenu_close}>
												<MenuList>
													<MenuItem onClick={this.profile_clicked}>Profil</MenuItem>
													<MenuItem onClick={this.logout_click}>Déconnexion</MenuItem>
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
							{this.props.viewReport ? (
								<IconButton onClick={this.reporting_click} color="inherit">
									<FileChart color="inherit" />
								</IconButton>
							) : null}
							{this.props.adminConfig ? (
								<IconButton onClick={this.admin_click} color="inherit">
									<Settings color="inherit" />
								</IconButton>
							) : null}
						</span>
					) : null}
				</Toolbar>
			</AppBar>
		);
	}
}

Header.propTypes = {
	logout_handler: PropTypes.func.isRequired,
	openAuthenticationDialog_handler: PropTypes.func.isRequired,
	loggedin: PropTypes.bool.isRequired,
	adminConfig: PropTypes.bool.isRequired,
	viewReport: PropTypes.bool.isRequired,
	user: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(Header);
