import React, { Children } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import moment from 'moment';
import momentBusinessDays from 'moment-business-days';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css'
// import './PrintableCalendar.css'

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import { Typography } from "@material-ui/core";
const styles = theme => ({
});
const localizer = momentLocalizer(moment);

class CustomToolbar extends React.Component {
	render() {
		let { label} = this.props
		return(
			<div className="rbc-toolbar">
				<span className="rbc-toolbar-label">
					<Typography variant="h2">
						Événements
					</Typography>
				</span>
				<span className="rbc-toolbar-label">
					<Typography variant="h2">
						{label}
					</Typography>
				</span>
			</div>
		)
	}
}

class PrintableCalendar extends React.Component {

	constructor(props) {
		super(props);
			this.state = {
		};
	}
	render() {

		return (
			<React.Fragment>
				<Calendar
					selectable={false}
					localizer={localizer}
					events={this.props.events}
					titleAccessor={event => {
						return (<span>
                            {event.title}
                        </span>)
					}}
					tooltipAccessor={event => {
						return (<span>
                            {event.title}
                        </span>)
					}}
					resourceTitleAccessor={event => {
						return (<span>
                            {event.title}
                        </span>)
					}}

					startAccessor={event => {
						return event.date;
					}}
					endAccessor={event => {
						return event.date;
					}}
					views={['month']}
					drilldownView={null}
					popup={false}
					date={this.props.selectedDate}

					components={{
						toolbar: (props) => (
							<CustomToolbar
								{...props}
								user={this.props.user}
							/>
						),
						dateCellWrapper: (props) => {
							const classNameToAdd = momentBusinessDays(props.value).isBusinessDay() ? "" : "rbc-off-range-bg";
							return(
								React.cloneElement(Children.only(props.children), {
									className: props.children.props.className+" "+(props.children.props.className.includes(classNameToAdd) ? '' : classNameToAdd)
								})
							)
						}

					}}
					eventPropGetter={
						(event, start, end, isSelected) => {
							let newStyle = {
								backgroundColor: "#CE8888",
							};
							return {
								className: "",
								style: newStyle
							};
						}
					}
				/>
			</React.Fragment>

		);
	}
}

PrintableCalendar.propTypes = {
	events:PropTypes.array.isRequired,
	selectedDate: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(PrintableCalendar);
