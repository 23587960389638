import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";


import { resolveObjectPath } from "variables/general.jsx";

import ColorPreviewer from "components/ColorPreviewer/ColorPreviewer.jsx";


const headStyles = theme => ({
	headLabel: {
		paddingLeft:0
	},
});

class EnhancedTableHead extends React.Component {

	//======================= UI Callback ========================

	createSortHandler = property => event => {
		this.props.requestSort_handler(event, property);
	};

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<TableHead>
				<TableRow>
					{this.props.selectable&&
					<TableCell padding="checkbox">
						<Checkbox
							indeterminate={this.props.numSelected > 0 && this.props.numSelected < this.props.rowCount}
							checked={this.props.numSelected === this.props.rowCount}
							onChange={this.props.selectAllClick_handler}
						/>
					</TableCell>
					}
					{this.props.properties.map(property => (
						<TableCell
							className={classes.headLabel}
							key={property.name}
							sortDirection={this.props.orderBy === property.name ? this.props.order : false}
							align={property.align||'left'}
						>
							<Tooltip
								title="Sort"
								placement={"bottom-end"}
								enterDelay={300}
							>
								<TableSortLabel
									active={this.props.orderBy === property.name}
									direction={this.props.order}
									onClick={this.createSortHandler(property.name)}
								>
									{property.label}
								</TableSortLabel>
							</Tooltip>
						</TableCell>
					),this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	requestSort_handler: PropTypes.func.isRequired,
	selectAllClick_handler: PropTypes.func,
	selectable: PropTypes.bool,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	properties: PropTypes.array.isRequired,
};

EnhancedTableHead = withStyles(headStyles,{withTheme: true})(EnhancedTableHead);

const styles = theme => ({
	tableWrapper: {
		overflowX: "auto"
	}
});

class PagedFilterableTable extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			selected: [],
			order: 'asc',
			orderBy: '',
			currentPage: 0,
			rowsPerPage:5
		};
	}

	//======================= Tool Functions ========================

	selectRow = (id) => {
		let newSelected = [...this.state.selected];
		let idIndex = newSelected.indexOf(id);
		if(idIndex === -1){
			newSelected.push(id);
		}else{
			newSelected.splice(idIndex,1);
		}
		this.props.dataSelected_handler(newSelected);
		this.setState({ selected: newSelected });
	};

	isSelected = id => this.state.selected.indexOf(id) !== -1;

	sortData = (data) => {
		let sortedData = [...data];
		let orderBy = this.state.orderBy;
		if(orderBy === ''){
			orderBy = this.props.properties[0].name;
			this.setState({orderBy:orderBy});
		}

		sortedData.sort((a,b) => {
			let testA = resolveObjectPath(this.state.orderBy,a);
			let testB = resolveObjectPath(this.state.orderBy,b);
			if(typeof testA === 'string'){
				testA = testA.toLowerCase();
				testB = testB.toLowerCase();
			}
			if(this.state.order === 'asc'){
				return(testA > testB ? 1 : ((testB > testA) ? -1 : 0));
			}
			else{
				return(testA < testB ? 1 : ((testB < testA) ? -1 : 0));
			}
		});
		return sortedData;
	}

	//======================= UI Callback ========================

	requestSort_handler = (event, property) => {
		const orderBy = property;
		let order = "desc";

		if (this.state.orderBy === property && this.state.order === "desc") {
			order = "asc";
		}

		this.setState({ order:order, orderBy:orderBy });
	};

	selectAllClick_handler = event => {
		let selected = [];
		if (event.target.checked) {
			selected = this.props.data.map(n => n.id);
		}
		this.props.dataSelected_handler(selected);
		this.setState({ selected: selected });
	};

	handleClick = (event, id) => {
		if(this.props.selectable){
			this.selectRow(id);
		}
	};

	changePage_handler = (event, page) => {
		this.setState({ currentPage: page });
	};

	changeRowsPerPage_handler = event => {
		this.setState({ rowsPerPage: event.target.value, currentPage:0 });
	};

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<div className={classes.tableWrapper}>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<EnhancedTableHead
							numSelected={this.state.selected.length}
							order={this.state.order}
							orderBy={this.state.orderBy}
							requestSort_handler={this.requestSort_handler}
							rowCount={this.props.data.length}
							selectAllClick_handler={this.selectAllClick_handler}
							properties={this.props.properties}
							selectable={this.props.selectable}
						/>
						<TableBody>
							{this.sortData(this.props.data)
							.slice(this.state.currentPage * this.state.rowsPerPage, this.state.currentPage * this.state.rowsPerPage + this.state.rowsPerPage)
							.map(element=>{
								const isSelected = this.isSelected(element.id);
								return (
									<TableRow
										hover
										role={this.props.selectable?"checkbox":undefined}
										onClick={event => this.handleClick(event, element.id)}
										aria-checked={isSelected}
										tabIndex={-1}
										key={element.id}
										selected={isSelected}
									>
										{this.props.selectable&&
											<TableCell padding="checkbox">
												<Checkbox checked={isSelected} />
											</TableCell>
										}
										{this.props.properties.map(property => (
											<TableCell scope="row" padding="none" key={property.name} align={property.align||'left'}>
											{property.type === 'color' ?
												<ColorPreviewer color={resolveObjectPath(property.name,element)}/>
											: property.type === 'custom' ?
												resolveObjectPath(property.name,element)()
											:
												(resolveObjectPath(property.name,element)+(property.suffix || ''))
											}
											</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25, 50, 100]}
					component="div"
					count={this.props.data.length}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.currentPage}
					backIconButtonProps={{
						"aria-label": "Previous Page"
					}}
					nextIconButtonProps={{
						"aria-label": "Next Page"
					}}
					onChangePage={this.changePage_handler}
					onChangeRowsPerPage={this.changeRowsPerPage_handler}
				/>
			</React.Fragment>
		);
	}
}

PagedFilterableTable.propTypes = {
	properties: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	dataSelected_handler: PropTypes.func,
	selectable: PropTypes.bool
};

export default withStyles(styles,{withTheme: true})(PagedFilterableTable);
