import React, { Children } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import moment from 'moment';
import momentBusinessDays from 'moment-business-days';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import './TimespentsCalendar.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
// import './PrintableCalendar.css'

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import { Chip, Typography } from "@material-ui/core";
import{
	CurrencyUsd,
	Sleep,
	CurrencyUsdOff,
	HeartPulse,
	AccountChild,
	BabyBuggy,
	HumanPregnant,
	HomeAlert,
	School,
	FileDocumentEdit,
	ProgressClock
} from 'mdi-material-ui';

const styles = theme => ({
	chipHours:{
		height:"auto",
		marginLeft:"5%"
	}
});
const iconStyle = {
	marginRight:"2%",
	fontSize:"16px"
};
const categoriesIcons = [
	{id:1, icon:(<CurrencyUsd
		style={iconStyle}
	/>)},
	{id:2, icon:(<Sleep
		style={iconStyle}
	/>)},
	{id:3, icon:(<CurrencyUsdOff
		style={iconStyle}
	/>)},
	{id:4, icon:(<HeartPulse
		style={iconStyle}
	/>)},
	{id:5, icon:(<AccountChild
		style={iconStyle}
	/>)},
	{id:6, icon:(<BabyBuggy
		style={iconStyle}
	/>)},
	{id:7, icon:(<HumanPregnant
		style={iconStyle}
	/>)},
	{id:8, icon:(<HomeAlert
		style={iconStyle}
	/>)},
	{id:9, icon:(<School
		style={iconStyle}
	/>)},
	{id:10, icon:(<FileDocumentEdit
		style={iconStyle}
	/>)},
	{id:11, icon:(<ProgressClock
		style={iconStyle}
	/>)}
]
const localizer = momentLocalizer(moment);

class CustomToolbar extends React.Component {
	render() {
		let { label, userToDisplay } = this.props
		return(
			<div className="rbc-toolbar">
				{userToDisplay ?
					<span className="rbc-toolbar-label">
						<Typography variant="h2">
							{userToDisplay.displayname}
						</Typography>
					</span>
				: null}
				<span className="rbc-toolbar-label">
					<Typography variant="h2">
						{label}
					</Typography>
				</span>
			</div>
		)
	}
}

class PrintableCalendar extends React.Component {

	constructor(props) {
		super(props);
			this.state = {
		};
	}
	render() {

		const { classes } = this.props;
		return (
			<React.Fragment>
				<Calendar
					selectable={false}
					localizer={localizer}
					events={[...this.props.timespents,...this.props.events]}
					titleAccessor={event => {
						if(event.blocking !== undefined){
							return event.title
						}else{
							if(event.project.system && event.project.id === 1){
								let title = event.category.name;
								if(this.props.accessor === 'user'){
									title = event.user.name;
								}
								return (<span>
									{categoriesIcons.find(catIcon => catIcon.id === event.category.id).icon}
									{title+" : "+(event.hours)/7+" j"}
									</span>)
							}else{
								return event[this.props.accessor].name+" : "+event.hours+"h"
							}
						}
					}}
					tooltipAccessor={event => {
						if(event.blocking !== undefined){
							return event.title
						}else{
							if(event.project.system && event.project.id === 1){
								return event.category.name+" : "+(event.hours)/7+" j"
							}else{
								return event[this.props.accessor].name+" : "+event.hours+"h"
							}
						}
					}}
					resourceTitleAccessor={event => {
						if(event.blocking !== undefined){
							return event.title
						}else{
							if(event.project.system && event.project.id === 1){
								return event.category.name+" : "+(event.hours)/7+" j"
							}else{
								return event[this.props.accessor].name+" : "+event.hours+"h"
							}
						}
					}}

					startAccessor={event => {
						return event.date;
					}}
					endAccessor={event => {
						return event.date;
					}}
					views={['month']}
					drilldownView={null}
					popup={false}
					date={this.props.selectedDate}

					components={{
						toolbar: (props) => (
							<CustomToolbar
								{...props}
								userToDisplay={this.props.userToDisplay}
							/>
						),
						month:{
							dateHeader: (props) => {
								let hours = 0;
								if(this.props.daySum && this.state.visualisation !== "absence"){
									hours = this.props.timespents.filter((timespent) => !timespent.project.system).filter(timespent => moment(timespent.date).isSame(props.date, 'day')).reduce((a, b) => a + (b.hours || 0), 0);
								}

								let shouldWork = '';

								if(this.props.contracts){
									let contract = this.props.contracts.find(contract => (moment(contract.date_start).isSameOrBefore(props.date)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(props.date)));
									if(contract){
										if(contract.hoursConfiguration.type === 'hours'){
											let currentDayName = moment(props.date).locale('en').format('dddd').toLowerCase();
											shouldWork = ' / '+contract.hoursConfiguration.days[currentDayName]+'h';
										}else{
											hours = 0;
										}
									}
								}

								return(
									<div className="dayHeaderContainer">
										<span className="hourSum">
											{hours > 0 ?
												<Chip
													label={hours+'h '+shouldWork}
													color="secondary"
													className={classes.chipHours}
												/>
											: null}
										</span>
										<span className="dayNumber">
											{props.label}
										</span>
									</div>
								)
							}
						},
						dateCellWrapper: (props) => {
							let workingDay = true;
							if(this.props.contracts){
								let contract = this.props.contracts.find(contract => (moment(contract.date_start).isSameOrBefore(props.value)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(props.value)));
								if(contract){
									if(contract.hoursConfiguration.type === 'hours'){
										let currentDayName = moment(props.value).locale('en').format('dddd').toLowerCase();
										workingDay = (contract.hoursConfiguration.days[currentDayName] > 0);
									}else{
										workingDay = momentBusinessDays(props.value).isBusinessDay();
									}
								}else{
									workingDay = false;
								}
							}else{
								workingDay = momentBusinessDays(props.value).isBusinessDay()
							}
							const classNameToAdd = workingDay ? "" : "rbc-off-range-bg";
							return(
								React.cloneElement(Children.only(props.children), {
									className: props.children.props.className+" "+(props.children.props.className.includes(classNameToAdd) ? '' : classNameToAdd)
								})
							)
						}

					}}
					eventPropGetter={
						(event, start, end, isSelected) => {
							let newStyle = {
								backgroundColor: event.blocking !== undefined ? '#CE8888' : (event.project.system ? event.project.color : event[this.props.accessor].color),
							};
							return {
								className: "",
								style: newStyle
							};
						}
					}
				/>
			</React.Fragment>

		);
	}
}

PrintableCalendar.propTypes = {
	accessor:PropTypes.string.isRequired,
	daySum:PropTypes.bool.isRequired,
	timespents:PropTypes.array.isRequired,
	selectedDate: PropTypes.object.isRequired,
	userToDisplay:PropTypes.object,
	contracts:PropTypes.array,
	hideHoursSum:PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(PrintableCalendar);
