import React from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import {
	withStyles,
	TextField,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	DialogTitle,
	DialogContentText
} from "@material-ui/core";

import {
	Lock
} from "mdi-material-ui"

const style_override = theme => ({
	paper:{
		minWidth: '50vw',
		overflowY:'visible'
	},
	dialogContent:{
		overflow: 'visible'
	}
});

class ChangePasswordDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			firstPassword: "",
			secondPassword: "",
			firstPassword_errorMessage: "",
			secondPassword_errorMessage: ""
		};
	}

    //======================= UI Callback ========================

	close_handler = () => {
		this.setState({
			firstPassword: "",
			secondPassword: "",
			firstPassword_errorMessage: "",
			secondPassword_errorMessage: ""
		});
		this.props.closeHandler();
	}

	firstPasswordTextChange_handler = (event) => {
		this.setState({
			firstPassword: event.target.value,
			firstPassword_errorMessage:"",
			secondPassword_errorMessage:""
		});
	};

	secondPasswordTextChange_handler = (event) => {
		this.setState({
			secondPassword: event.target.value,
			firstPassword_errorMessage:"",
			secondPassword_errorMessage:""
		});
	};

	changePassword_handler = (event) => {
		this.setState({firstPassword_errorMessage:"",secondPassword_errorMessage:""});
		if(this.state.firstPassword === ""){
			this.setState({firstPassword_errorMessage:"Mot de passe incorrect"});
		}else if(this.state.firstPassword !== this.state.secondPassword){
			this.setState({secondPassword_errorMessage:"Les mots de passe ne correspondent pas"});
		}
		let hash = CryptoJS.SHA3(this.state.firstPassword).toString();
		this.props.apiControllers.users.update(
			this.props.userId,{
				password: hash
			},{
				auth_username:this.props.auth_user.username,
				auth_token: this.props.auth_user.token
		}).then((user) => {
			this.props.notifier.success("Mot de passe modifié")
			this.close_handler();
		}).catch((error) =>{
			if(error.status === 400){
				this.props.notifier.warning("Mauvais mot de passe");
			}else if(error.status === 404){
				this.props.notifier.error("Utilisateur non trouvé");
			}else{
				this.props.notifier.error("Erreur 400");
			}
		});
	};

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.close_handler}
				aria-labelledby="form-dialog-title"
				classes={{
					paper:classes.paper
				}}
			>
				<DialogTitle id="form-dialog-title">Modifier le mot de passe</DialogTitle>
				<form>
					<DialogContent className={classes.dialogContent}>
						<DialogContentText>
								Entrez deux fois le nouveau mot de passe
						</DialogContentText>
						<Grid container spacing={2} alignItems="flex-end">
							<Grid item xs={12} container spacing={2} alignItems="flex-end">
								<Grid item xs={1}>
									<Lock />
								</Grid>
								<Grid item xs={11}>
									<TextField
										required
										fullWidth
										error={this.state.firstPassword_errorMessage !== "" ? true : false}
										helperText={this.state.firstPassword_errorMessage}
										color="secondary"
										id="firstPassword-input"
										label="Mot de passe"
										type="password"
										value={this.state.firstPassword}
										onChange={this.firstPasswordTextChange_handler}
										margin="normal"
										/>
								</Grid>
							</Grid>
							<Grid item xs={12} container spacing={2} alignItems="flex-end">
								<Grid item xs={1}>
									<Lock />
								</Grid>
								<Grid item xs={11}>
									<TextField
										required
										fullWidth
										error={this.state.secondPassword_errorMessage !== "" ? true : false}
										helperText={this.state.secondPassword_errorMessage}
										color="secondary"
										id="secondPassword-input"
										label="Mot de passe"
										type="password"
										value={this.state.secondPassword}
										onChange={this.secondPasswordTextChange_handler}
										margin="normal"
									/>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.changePassword_handler}
							color="secondary"
							variant="contained">
							Modifier
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

ChangePasswordDialog.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	logger: PropTypes.object.isRequired,
	auth_user: PropTypes.object.isRequired,
	userId: PropTypes.number
};

export default withStyles(style_override,{withTheme: true})(ChangePasswordDialog);
