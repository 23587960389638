import moment from 'moment';
import {dateFormat} from 'variables/general.jsx';

class TimespentsAPIController{

    constructor(connectionManager, logger) {
        this.entityName = 'timespents';
        this.connectionManager = connectionManager;
        this.logger = logger;
    }

    getAll = (id, searchedEntityName, start_date, end_date, withoutDetails, auth_parameters) =>{
        let options = {};
        options.start_date = start_date;
        options.end_date = end_date;
        options.withoutDetails = withoutDetails;
        auth_parameters.options = JSON.stringify(options);
        let url = "";
        if(searchedEntityName !== undefined){
            url = this.entityName+'/'+searchedEntityName+'/'+id;
        }else{
            url = this.entityName;
        }
        return this.connectionManager.get(url,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    getAllCurrentWeek = (id, searchedEntityName, auth_parameters) => {
        let start_date = moment().startOf('week').format(dateFormat);
        let end_date = moment().endOf('week').format(dateFormat);
        return this.getAll(id, searchedEntityName, start_date, end_date, auth_parameters);
    }

    getById(id,auth_parameters) {
        return this.connectionManager.get(this.entityName+"/"+id,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    getSumOfEntity = (id, searchedEntityName, start_date, end_date, auth_parameters) => {
        let options = {};
        options.start_date = start_date;
        options.end_date = end_date;
        auth_parameters.options = JSON.stringify(options);
        let url = "";
        if(searchedEntityName !== undefined){
            url = this.entityName+'/sum/'+searchedEntityName+'/'+id;
        }
        return this.connectionManager.get(url,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    create(body_parameters,auth_parameters) {
        return this.connectionManager.post(this.entityName,body_parameters,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    update(id,body_parameters,auth_parameters) {
        return this.connectionManager.put(this.entityName+"/"+id,body_parameters,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    delete(id,auth_parameters) {
        return this.connectionManager.delete(this.entityName+"/"+id,{},auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }
}

export default TimespentsAPIController;