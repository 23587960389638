import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';

import moment from 'moment';

import {
	Button,
	Typography,
	TextField,
	InputAdornment
} from '@material-ui/core';

import {
	Plus,
	CurrencyUsd,
	Sleep,
	CurrencyUsdOff,
	HeartPulse,
	AccountChild,
	BabyBuggy,
	HumanPregnant,
	HomeAlert,
	School,
	FileDocumentEdit,
	ProgressClock
} from 'mdi-material-ui';

const absenceDays_limit= {
	min: 0.5,
	max: 30,
	step: 0.5
};

const hoursPerDay = 7;
const iconStyle = {
	marginRight:"2%",
	fontSize:"16px"
};
const categoriesIcons = [
	{id:1, icon:(<CurrencyUsd
		style={iconStyle}
	/>)},
	{id:2, icon:(<Sleep
		style={iconStyle}
	/>)},
	{id:3, icon:(<CurrencyUsdOff
		style={iconStyle}
	/>)},
	{id:4, icon:(<HeartPulse
		style={iconStyle}
	/>)},
	{id:5, icon:(<AccountChild
		style={iconStyle}
	/>)},
	{id:6, icon:(<BabyBuggy
		style={iconStyle}
	/>)},
	{id:7, icon:(<HumanPregnant
		style={iconStyle}
	/>)},
	{id:8, icon:(<HomeAlert
		style={iconStyle}
	/>)},
	{id:9, icon:(<School
		style={iconStyle}
	/>)},
	{id:10, icon:(<FileDocumentEdit
		style={iconStyle}
	/>)},
	{id:11, icon:(<ProgressClock
		style={iconStyle}
	/>)}
]


const styles = theme => ({
	button: {
		margin: theme.spacing(),
	},
	creation:{
		backgroundColor: theme.button.creation,
		color: theme.palette.primary.contrastText
	},
	leftIcon: {
		marginRight: theme.spacing(),
	},
});

class AddAbsenceForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			absenceProject:undefined,
			form_absenceDays:0,
			form_absenceDays_errorMessage:"",
			form_selectedCategory:null,
			form_category_errorMessage:""
		};
	}

	componentDidMount = () => {
		this.getAbsenceProject();
	}

	//======================= Tool Functions ========================

	getAbsenceProject = () => {
		return this.props.apiControllers.projects.getById(1,{
			auth_username:this.props.auth_user.username,
			auth_token:this.props.auth_user.token,
		}).then((response)=>{
			let project = response.data;
			project.categories.map(cat => cat.icon = categoriesIcons.find((catIcon) => (catIcon.id === cat.id)).icon);
			this.setState({absenceProject: project});
			return true;
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	//======================= UI Callback ========================

	addAbsence_handler = (event) => {
		let absenceDays = this.state.form_absenceDays;
		let project = this.state.absenceProject;
		let category = this.state.form_selectedCategory;
		if(absenceDays < absenceDays_limit.min || absenceDays > absenceDays_limit.max || (absenceDays % absenceDays_limit.step !== 0)){
			this.setState({form_absenceDays_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else if(project === null){
			this.setState({form_project_errorMessage: "Vous devez sélectionner un projet"});
		}else if(category === null){
			this.setState({form_category_errorMessage: "Vous devez sélectionner une catégorie"});
		}else{

			let absencesToAdd = [];
			let currentDate = moment(this.props.selectedDate);
			let absenceDestinationDate = moment(this.props.selectedDate).businessAdd(parseInt(absenceDays-1));
			if(absenceDays >= 1){
				while(currentDate <= absenceDestinationDate){
					if(currentDate.isBusinessDay()){
						absencesToAdd.push({
							date:currentDate.format("YYYY-MM-DD"),
							hours:hoursPerDay
						});
					}
					currentDate = currentDate.businessAdd(1,'day');
				}
			}
			if(absenceDays % 1){
				absencesToAdd.push({
					date:currentDate.format("YYYY-MM-DD"),
					hours:(hoursPerDay/2)
				})
			}
			this.addAbsence_recursive(absencesToAdd,{
				userId: this.props.user.id,
				projectId: project.id,
				categoryId: category.id,
				description:'',
				tags:[],
			},
			[]);
		}
	};

	addAbsence_recursive = (absencesArray, mainObject, result) => {
		this.props.loadingDialog.open();
		this.props.apiControllers.timespents.create({
			...mainObject,
			...absencesArray[0]
		},{
			auth_username:this.props.auth_user.username,
			auth_token:this.props.auth_user.token
		}).then((response) => {
			if(absencesArray.length > 1){
				this.addAbsence_recursive(absencesArray.splice(1), mainObject, [...result,response.data]);
			}else{
				this.props.notifier.success('Absences enregistrées');
				this.props.addTimespent_handler([...result,response.data]);
				this.props.loadingDialog.close();
			}
		}).catch((error) => {
			this.props.logger.error(error);
			this.props.notifier.error(error.data);
			this.props.loadingDialog.close();
		});
	}

	absenceDaysChange_handler = (event) => {
		this.setState({form_absenceDays: event.target.value});
		let value = event.target.value;
		if(value < absenceDays_limit.min || value > absenceDays_limit.max || (value % absenceDays_limit.step !== 0)){
			this.setState({form_absenceDays_errorMessage: 'Min 0.5, Max 30, par pas de 0.5'});
		}else{
			this.setState({form_absenceDays_errorMessage: ''});
		}
	}

	categoryChange_handler = (selectedCategory) => {
		this.setState({
			form_selectedCategory: selectedCategory,
			form_selectedTags:[],
			form_category_errorMessage: '',
		});
	}

	//======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Typography variant="h3">
					Date : {this.props.selectedDate !== undefined ? moment(this.props.selectedDate).format("DD/MM/YYYY") : null}
				</Typography>
				<TextField
					fullWidth
					error={this.state.form_absenceDays_errorMessage !== "" ? true : false}
					helperText={this.state.form_absenceDays_errorMessage}
					color="secondary"
					id="absenceDays-input"
					label="Nombre de jours"
					type="number"
					InputProps={{
						inputProps:{
							min: absenceDays_limit.min,
							max: absenceDays_limit.max,step: absenceDays_limit.step
						},
						endAdornment: <InputAdornment position="end">j</InputAdornment>
					}}
					value={this.state.form_absenceDays}
					onChange={this.absenceDaysChange_handler}
				/>
				{this.state.absenceProject&&
				<AutoCompleteHightlight
					suggestions={this.state.absenceProject.categories.filter(category => (!category.disabled))}
					change_handler={this.categoryChange_handler}
					selectedValue={this.state.form_selectedCategory}
					errorMessage={this.state.form_category_errorMessage}
					disabled={false}
					label="Catégorie :"
				/>
				}
				<Button
					className={classNames(classes.button,classes.creation)}
					variant="contained"
					onClick={this.addAbsence_handler}
				>
					<Plus className={classes.leftIcon} />
					Ajouter une absence
				</Button>
			</React.Fragment>
		);
	}
}

AddAbsenceForm.propTypes = {
	selectedDate: PropTypes.object.isRequired,
	addTimespent_handler: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	auth_user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
	loadingDialog: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(AddAbsenceForm);
