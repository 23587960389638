import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';

import moment from 'moment';

import {
	Button,
	Typography,
	TextField,
	Switch,
	Grid,
	FormControlLabel
} from '@material-ui/core';

import {
	Plus
} from 'mdi-material-ui';

const styles = theme => ({
	button: {
		margin: theme.spacing(),
	},
	creation:{
		backgroundColor: theme.button.creation,
		color: theme.palette.primary.contrastText
	},
	leftIcon: {
		marginRight: theme.spacing(),
    }
});

class AddEventForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            form_title: '',
            form_title_errorMessage: '',
            form_blocking: true
		};
	}

	//======================= UI Callback ========================

	addEvent_handler = (event) => {
		let title = this.state.form_title;
		let blocking = this.state.form_blocking;
		if(title === ""){
			this.setState({form_title_errorMessage: "Vous devez entrer un titre"});
		}else{
			this.props.apiControllers.events.create({
				title:title,
                hours: 7,
                blocking: blocking,
				date:moment(this.props.selectedDate).format("YYYY-MM-DD")
			},{
				auth_username:this.props.auth_user.username,
				auth_token:this.props.auth_user.token
			}).then((response) => {
				this.props.notifier.success('Temps enregistré');
				this.props.addEvent_handler(response.data);
			}).catch((error) => {
				this.props.logger.error(error);
				this.props.notifier.error(error.data);
			});
		}
	};

    titleChange_handler = (event) => {
		this.setState({form_title: event.target.value});
        let value = event.target.value;
        if(value === ''){
			this.setState({form_title_errorMessage: 'Vous devez entrer un titre'});
        }else{
			this.setState({form_title_errorMessage: ''});
        }
    }

    blockingChange_handler = (event) => {
		this.setState({form_blocking: event.target.checked});
    }

	//======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant="h3">
							Date : {this.props.selectedDate !== undefined ? moment(this.props.selectedDate).format("DD/MM/YYYY") : null}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							error={this.state.form_title_errorMessage !== "" ? true : false}
							helperText={this.state.form_title_errorMessage}
							color="secondary"
							id="title-input"
							label="Titre"
							type="text"
							value={this.state.form_title}
							onChange={this.titleChange_handler}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Switch
									checked={this.state.form_blocking}
									onChange={this.blockingChange_handler}
								/>
							}
							label="Événement bloquant"
						/>
					</Grid>
					<Grid item xs={12}>
						<Button
							className={classNames(classes.button,classes.creation)}
							variant="contained"
							onClick={this.addEvent_handler}
						>
							<Plus className={classes.leftIcon} />
							Ajouter un événement
						</Button>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}
}

AddEventForm.propTypes = {
    selectedDate: PropTypes.object.isRequired,
    addEvent_handler: PropTypes.func.isRequired,
    auth_user: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(AddEventForm);
