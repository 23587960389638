class EventsAPIController{

    constructor(connectionManager, logger) {
        this.entityName = 'events';
        this.connectionManager = connectionManager;
        this.logger = logger;
    }

    getAll = (start_date, end_date, auth_parameters) =>{
        let options = {};
        options.start_date = start_date;
        options.end_date = end_date;
        auth_parameters.options = JSON.stringify(options);
        let url = this.entityName;
        return this.connectionManager.get(url,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    getById(id,auth_parameters) {
        return this.connectionManager.get(this.entityName+"/"+id,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    create(body_parameters,auth_parameters) {
        return this.connectionManager.post(this.entityName,body_parameters,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    update(id,body_parameters,auth_parameters) {
        return this.connectionManager.put(this.entityName+"/"+id,body_parameters,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    delete(id,auth_parameters) {
        return this.connectionManager.delete(this.entityName+"/"+id,{},auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }
}

export default EventsAPIController;