import React, { Children } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AddTimespentDialog from 'components/Timespents/AddTimespentDialog.jsx';
import UpdateTimespentDialog from 'components/Timespents/UpdateTimespentDialog.jsx';
import UpdateAbsenceDialog from 'components/Timespents/UpdateAbsenceDialog.jsx';

import PrintableCalendar from 'components/Timespents/PrintableCalendar.jsx';

import moment from 'moment';
import momentBusinessDays from 'moment-business-days';
import {Calendar, momentLocalizer} from 'react-big-calendar';

import 'react-big-calendar/lib/css/react-big-calendar.css'
import './TimespentsCalendar.css'
import ReactToPrint from 'react-to-print';

import {dateFormat} from 'variables/general.jsx';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import { IconButton, Chip, Typography, Button, Grid} from "@material-ui/core";

import Select, {components} from 'react-select';

import{
	ChevronLeft,
	ChevronRight,
	CalendarToday,
	Printer,
	CurrencyUsd,
	Sleep,
	CurrencyUsdOff,
	HeartPulse,
	AccountChild,
	BabyBuggy,
	HumanPregnant,
	HomeAlert,
	School,
	FileDocumentEdit,
	ProgressClock
} from 'mdi-material-ui';

const styles = theme => ({
	chipHours:{
		height:"auto",
		marginLeft:"5%"
	},
	printVersion:{
		display:'none'
	},
	printButton:{
		backgroundColor: theme.palette.secondary.main+"!important",
		color: theme.palette.secondary.contrastText+"!important"
	},
	selectedButton:{
		backgroundColor: theme.palette.secondary.main+"!important",
		color: theme.palette.secondary.contrastText+"!important"
	}

});

export let navigate = {
	PREVIOUS: 'PREV',
	NEXT: 'NEXT',
	TODAY: 'TODAY',
}

const iconStyle = {
	marginRight:"2%",
	fontSize:"16px"
};
const categoriesIcons = [
	{id:1, icon:(<CurrencyUsd
		style={iconStyle}
	/>)},
	{id:2, icon:(<Sleep
		style={iconStyle}
	/>)},
	{id:3, icon:(<CurrencyUsdOff
		style={iconStyle}
	/>)},
	{id:4, icon:(<HeartPulse
		style={iconStyle}
	/>)},
	{id:5, icon:(<AccountChild
		style={iconStyle}
	/>)},
	{id:6, icon:(<BabyBuggy
		style={iconStyle}
	/>)},
	{id:7, icon:(<HumanPregnant
		style={iconStyle}
	/>)},
	{id:8, icon:(<HomeAlert
		style={iconStyle}
	/>)}
	,
	{id:9, icon:(<School
		style={iconStyle}
	/>)},
	{id:10, icon:(<FileDocumentEdit
		style={iconStyle}
	/>)},
	{id:11, icon:(<ProgressClock
		style={iconStyle}
	/>)}
]
const localizer = momentLocalizer(moment);

class CustomToolbar extends React.Component {
	render() {
		let { label, componentToPrintRef, classes, visualisation, displayAbsences,workedMonthHours,shouldWorkedMonthHours} = this.props
		return(
			<div className="rbc-toolbar">
				<span className="rbc-btn-group">
					<IconButton
						onClick={this.navigate.bind(null, navigate.PREVIOUS)}
					>
						<ChevronLeft/>
					</IconButton>
					<IconButton
						onClick={this.navigate.bind(null, navigate.TODAY)}
					>
						<CalendarToday/>
					</IconButton>
					<IconButton
						onClick={this.navigate.bind(null, navigate.NEXT)}
					>
						<ChevronRight/>
					</IconButton>
				</span>
				{displayAbsences&&
					<span className="rbc-btn-group">
						<Button

							className={visualisation === 'all' ? classes.selectedButton : null}
							onClick={this.changeVisualisation.bind(null,'all')}
						>
							<Typography variant="body1">Tout voir</Typography>
						</Button>
						<Button
							className={visualisation === 'work' ? classes.selectedButton : null}
							onClick={this.changeVisualisation.bind(null,'work')}
						>
							<Typography variant="body1">Travail</Typography>
						</Button>
						<Button

							className={visualisation === 'absence' ? classes.selectedButton : null}
							onClick={this.changeVisualisation.bind(null,'absence')}
						>
							<Typography variant="body1">Absences</Typography>
						</Button>

					</span>
				}
				<span className="rbc-toolbar-label">
					<Grid container spacing={2}>
						<Grid item xs={1}>
						</Grid>
						<Grid item xs={3}>
							<Select
								isMulti={false}
								options={[...Array(12).keys()].map(el => ({
									value:el,
									label:moment().month(el).format('MMMM')
								}))}
								getOptionValue={(option) => (option.value)}
								getOptionLabel={(option) => (option.label)}
								value={{value:moment(this.props.date).month(),label:moment(this.props.date).format('MMMM')}}
								onChange={this.changeMonth}
								styles={{
									menu: (styles, { data }) => ({
										...styles,
										zIndex:1000,
									}),
								}}
							/>
						</Grid>
						<Grid item xs={2}>
							<Select
								isMulti={false}
								options={[...Array(moment(this.date).year()+1-2015).keys()].map(el => ({
									value:el+2015,
									label:el+2015
								}))}
								getOptionValue={(option) => (option.value)}
								getOptionLabel={(option) => (option.label)}
								value={{value:moment(this.props.date).year(),label:moment(this.props.date).format('YYYY')}}
								onChange={this.changeYear}
								styles={{
									menu: (styles, { data }) => ({
										...styles,
										zIndex:1000,
									}),
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							{!this.props.hideHoursSum &&
								<Typography variant="h2">
									{workedMonthHours !== undefined ? `${workedMonthHours}h` : ''} {shouldWorkedMonthHours !== undefined ? `/ ${shouldWorkedMonthHours}h` : ''}
								</Typography>
							}
						</Grid>
					</Grid>
				</span>

				<ReactToPrint
					trigger={() => (
						<Button
							variant="contained"
							color="secondary"
							className={classes.printButton}
						>
							<Printer/>
						</Button>
					)}
					content={() => componentToPrintRef}
				/>
			</div>
		)
	}

	changeMonth = month => {
		this.props.onNavigate(null,moment(this.props.date).month(month.value).toDate());
	}

	changeYear = year => {
		this.props.onNavigate(null,moment(this.props.date).year(year.value).toDate());
	}

	navigate = action => {
		this.props.onNavigate(action);
	}

	changeVisualisation = visualisation => {
		this.props.changeVisualisation(visualisation);
	}
}

class TimespentsCalendar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			addTimespentDialog_state: false,
			updateTimespentDialog_state: false,
			updateAbsenceDialog_state: false,
			addTimespent_date: moment().format(dateFormat),
			updateTimespent:undefined,
			selectedDate: new Date(),
			visualisation: 'all',
		};
	}

	componentDidMount = () => {
		this.getEvents();
	}

	//======================= Tool Functions ========================

	getEvents = () => {
		return this.props.apiControllers.events.getAll(
			undefined,
			undefined,
			{
				auth_username:this.props.user.username,
				auth_token:this.props.user.token,
		}).then((response)=>{
			let events = response.data;
			let eventsBase = this.state.events;
			if(events !== undefined && events !== null){
				let eventsToAdd = events;
				for(let event of eventsToAdd){
					eventsBase.push(event);
				}
				this.props.logger.debug(eventsToAdd);
			}
            return this.setState({events: eventsBase});
		}).catch((error) => {
			if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
		});
	}

	changeVisualisation = (visualisation) => {
		this.setState({visualisation:visualisation});
	}

	//======================= UI Callback ========================

	changeCalendarRange_handler = (date) => {
		let start_date = moment(date.start);
		let end_date = moment(date.end);
		// if the first date is not the searched month
		if(start_date.date() > 15){
			start_date.add(1,'month').startOf('month');
			end_date = moment(start_date);
			end_date.endOf('month');
			// if the last date is not the searched month
		} else if(end_date.date() < 15){
			end_date.subtract(1,'month').endOf('month');
			start_date = moment(end_date);
			start_date = start_date.startOf('month');
		}
		this.props.rangeChange_handler({from:new Date(start_date),to:new Date(end_date)})
	}

	addTimespentDialogOpen_handler = () => {

		if(this.props.enableAddTimespent !== undefined && this.props.enableAddTimespent !== false){
			this.setState({
				addTimespent_date: moment().format(dateFormat),
				addTimespentDialog_state: true
			});
		}
	}

	updateTimespentDialogOpen_handler = (timespent) => {
		if(this.props.enableUpdateTimespent !== undefined && this.props.enableUpdateTimespent !== false){
			this.setState({
				updateTimespent: timespent,
				updateTimespentDialog_state: true
			});
		}
	}

	updateAbsenceDialogOpen_handler = (timespent) => {
		if(this.props.enableUpdateAbsence !== undefined && this.props.enableUpdateAbsence !== false){
			this.setState({
				updateTimespent: timespent,
				updateAbsenceDialog_state: true
			});
		}
	}

	addTimespentDialogClose_handler = (timespent) => {
		this.setState({
			addTimespentDialog_state: false
		})
		this.props.addTimespent_handler(timespent);
	}

	updateTimespentDialogClose_handler = (timespent) => {
		this.setState({
			updateTimespentDialog_state: false
		})
		this.props.updateTimespent_handler(timespent);
	}

	updateAbsenceDialogClose_handler = (timespent) => {
		this.setState({
			updateAbsenceDialog_state: false
		})
		this.props.updateTimespent_handler(timespent);
	}

	deleteAbsenceDialogClose_handler = (timespent) => {
		this.setState({
			updateAbsenceDialog_state: false
		})
		this.props.deleteTimespent_handler(timespent);
	}

	deleteTimespentDialogClose_handler = (timespentId) => {
		this.setState({
			updateTimespentDialog_state: false
		})
		this.props.deleteTimespent_handler(timespentId);
	}

	calendarDayChange_handler = (date) => {
		let selectedDate = date;
		this.setState({selectedDate: selectedDate});
		if(this.props.selectable){
			this.props.dateChange_handler(selectedDate);
		}
	}

	calendarDayClick_handler = (date) => {
		let selectedDate = date.start;
		if(this.props.enableAddTimespent !== undefined && this.props.enableAddTimespent !== false){
			this.setState({
				selectedDate:selectedDate,
				addTimespentDialog_state: true
			});
		}else{
			this.calendarDayChange_handler(selectedDate);
		}
	}

    //======================= Page Render ========================

	render() {

		const { classes } = this.props;
		return (
			<React.Fragment>
				<div className={classes.printVersion}>
					<PrintableCalendar
						accessor={this.props.accessor}
						daySum={this.props.daySum}
						selectedDate={this.state.selectedDate}
						timespents={
							(this.state.visualisation === "work" ?
							this.props.timespents.filter(timespent => !timespent.project.system) : 			this.state.visualisation === "absence" ?
								this.props.timespents.filter(timespent => timespent.project.id === 1) :
							this.props.timespents.filter(timespent => (!timespent.project.system || timespent.project.id === 1))
							)
						}
						events={this.state.events}
						ref={el => (this.componentToPrintRef = el)}
						user={this.props.user}
						contracts={this.props.contracts}
						hideHoursSum={this.props.hideHoursSum}
						userToDisplay={this.props.userToDisplay}
					/>
				</div>
				{this.props.enableUpdateAbsence &&
				<UpdateAbsenceDialog
					updateHandler={this.updateAbsenceDialogClose_handler}
					deleteHandler={this.deleteAbsenceDialogClose_handler}
					apiControllers={this.props.apiControllers}
					notifier={this.props.notifier}
					open={this.state.updateAbsenceDialog_state}
					logger={this.props.logger}
					user={this.props.user}
					checkAuthentication={this.props.checkAuthentication}
					timespent={this.state.updateTimespent}
					date={this.state.selectedDate}
					loadingDialog={this.props.loadingDialog}
				/>}
				{this.props.enableAddTimespent &&
				<AddTimespentDialog
					addHandler={this.addTimespentDialogClose_handler}
					apiControllers={this.props.apiControllers}
					notifier={this.props.notifier}
					open={this.state.addTimespentDialog_state}
					logger={this.props.logger}
					user={this.props.user}
					checkAuthentication={this.props.checkAuthentication}
					date={this.state.selectedDate}
				/>}
				{this.props.enableUpdateTimespent &&
				<UpdateTimespentDialog
					updateHandler={this.updateTimespentDialogClose_handler}
					deleteHandler={this.deleteTimespentDialogClose_handler}
					apiControllers={this.props.apiControllers}
					notifier={this.props.notifier}
					open={this.state.updateTimespentDialog_state}
					logger={this.props.logger}
					user={this.props.user}
					timespent={this.state.updateTimespent}
					checkAuthentication={this.props.checkAuthentication}
					loadingDialog={this.props.loadingDialog}
				/>}
				<Calendar
					selectable={this.props.selectable}
					localizer={localizer}
					events={
						([...(this.state.visualisation === "work" ?
							this.props.timespents.filter(timespent => !timespent.project.system) : this.state.visualisation === "absence" ?
								this.props.timespents.filter(timespent => timespent.project.id === 1) :
							this.props.timespents.filter(timespent => (!timespent.project.system || timespent.project.id === 1))),...this.state.events]
						)
					}
					titleAccessor={event => {
						if(event.blocking !== undefined){
							return event.title
						}else{
							if(event.project.system && event.project.id === 1){
								let title = event.category.name;
								if(this.props.accessor === 'user'){
									title = event.user.name;
								}
								return (<span>
									{categoriesIcons.find(catIcon => catIcon.id === event.category.id).icon}
									{title+" : "+(event.hours)/7+" j"}
									</span>)
							}else{
								return event[this.props.accessor].name+" : "+event.hours+"h"
							}
						}
					}}
					tooltipAccessor={event => {
						if(event.blocking !== undefined){
							return event.title
						}else{
							if(event.project.system && event.project.id === 1){
								return event.category.name+" : "+(event.hours)/7+" j"
							}else{
								return event[this.props.accessor].name+" : "+event.hours+"h"
							}
						}
					}}
					resourceTitleAccessor={event => {
						if(event.blocking !== undefined){
							return event.title
						}else{
							if(event.project.system && event.project.id === 1){
								return event.category.name+" : "+(event.hours)/7+" j"
							}else{
								return event[this.props.accessor].name+" : "+event.hours+"h"
							}
						}
					}}

					startAccessor={event => {
						return event.date;
					}}
					endAccessor={event => {
						return event.date;
					}}
					views={['month']}
					drilldownView={null}
					popup={true}
					onRangeChange={this.changeCalendarRange_handler}
					onNavigate={this.calendarDayChange_handler}
					onSelectSlot={this.calendarDayClick_handler}
					onSelectEvent={event => {
						if(event.blocking === undefined){
							if(event.project.id === 1){
								this.updateAbsenceDialogOpen_handler(event)
							}else if(!event.project.system){
								this.updateTimespentDialogOpen_handler(event)
							}
						}
					}}
					date={this.state.selectedDate}
					timeslots={50}

					components={{
						toolbar: (props) => (
							<CustomToolbar
								{...props}
								componentToPrintRef={this.componentToPrintRef}
								classes={classes}
								visualisation={this.state.visualisation}
								changeVisualisation={this.changeVisualisation}
								displayAbsences={this.props.displayAbsences}
								workedMonthHours={this.props.workedMonthHours}
								shouldWorkedMonthHours={this.props.shouldWorkedMonthHours}
								hideHoursSum={this.props.hideHoursSum}
							/>
						),
						month:{
							dateHeader: (props) => {
								let hours = 0;
								if(this.props.daySum && this.state.visualisation !== "absence"){
									hours = this.props.timespents.filter((timespent) => !timespent.project.system).filter(timespent => moment(timespent.date).isSame(props.date, 'day')).reduce((a, b) => a + (b.hours || 0), 0);
								}

								let shouldWork = '';

								if(this.props.contracts){
									let contract = this.props.contracts.find(contract => (moment(contract.date_start).isSameOrBefore(props.date)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(props.date)));
									if(contract){
										if(contract.hoursConfiguration.type === 'hours'){
											let currentDayName = moment(props.date).locale('en').format('dddd').toLowerCase();
											shouldWork = ' / '+contract.hoursConfiguration.days[currentDayName]+'h';
										}else{
											hours = 0;
										}
									}
								}

								return(
									<div className="dayHeaderContainer">
										<span className="hourSum">
											{hours > 0 ?
												<Chip
													label={hours+'h '+shouldWork}
													color="secondary"
													className={classes.chipHours}
												/>
											: null}
										</span>
										<span className="dayNumber">
											{props.label}
										</span>
									</div>
								)
							}
						},
						dateCellWrapper: (props) => {
							let workingDay = true;
							if(this.props.contracts){
								let contract = this.props.contracts.find(contract => (moment(contract.date_start).isSameOrBefore(props.value)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(props.value)));
								if(contract){
									if(contract.hoursConfiguration.type === 'hours'){
										let currentDayName = moment(props.value).locale('en').format('dddd').toLowerCase();
										workingDay = (contract.hoursConfiguration.days[currentDayName] > 0);
									}else{
										workingDay = momentBusinessDays(props.value).isBusinessDay();
									}
								}else{
									workingDay = false;
								}
							}else{
								workingDay = momentBusinessDays(props.value).isBusinessDay()
							}
							const classNameToAdd = workingDay ? "" : "rbc-off-range-bg";
							return(
								React.cloneElement(Children.only(props.children), {
									className: props.children.props.className+" "+(props.children.props.className.includes(classNameToAdd) ? '' : classNameToAdd)
								})
							)
						}

					}}
					eventPropGetter={
						(event, start, end, isSelected) => {
							let newStyle = {
								backgroundColor: event.blocking !== undefined ? '#CE8888' : (event.project.system ? event.project.color : event[this.props.accessor].color),
							};
							return {
								className: "",
								style: newStyle
							};
						}
					}
				/>
			</React.Fragment>

		);
	}
}

TimespentsCalendar.propTypes = {
	displayAbsences: PropTypes.bool.isRequired,
	daySum: PropTypes.bool.isRequired,
	accessor: PropTypes.string.isRequired,
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userToDisplay: PropTypes.object,
	notifier: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object,
	checkAuthentication:PropTypes.func,
	rangeChange_handler:PropTypes.func.isRequired,
	selectable:PropTypes.bool.isRequired,
	dateChange_handler:PropTypes.func,
	addTimespent_handler:PropTypes.func,
	updateTimespent_handler:PropTypes.func,
	deleteTimespent_handler:PropTypes.func,
	timespents:PropTypes.array.isRequired,
	enableAddTimespent:PropTypes.bool,
	enableUpdateTimespent:PropTypes.bool,
	enableUpdateAbsence: PropTypes.bool,
	loadingDialog: PropTypes.object.isRequired,
	workedMonthHours:PropTypes.number,
	shouldWorkedMonthHours:PropTypes.number,
	contracts:PropTypes.array,
	hideHoursSum:PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(TimespentsCalendar);
