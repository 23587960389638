import React from "react";
import ReactDOM from "react-dom";

import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import indexRoutes from "routes/index.jsx";
import "./index.css";

import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import bigbrotherTheme from "bigbrotherTheme.jsx";

const hist = createBrowserHistory();

ReactDOM.render(
	<MuiThemeProvider theme={bigbrotherTheme}>
		<Router history={hist}>
			<Switch>
					{/* <App /> */}
					{indexRoutes.map((prop, key) => {
						return <Route path={prop.path} component={prop.component} key={key} />;
					})}
			</Switch>
		</Router>
	</MuiThemeProvider>,
	document.getElementById("root")
);
