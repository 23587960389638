import React from 'react';
import Select, {components} from 'react-select';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import './AutoCompleteHighlight.css'

import chroma from 'chroma-js';

import { withStyles, Typography,
} from '@material-ui/core';

import {
	CheckboxBlank,
	CheckboxMultipleMarked
} from "mdi-material-ui"

const styles = {
	errorMessage:{
		color:'red'
	},
	selectAllButton:{
		color: '#cccccc',
		padding: '8px',
		'&:hover':{
			color:'#999999'
		}
	}
};


class AutoCompleteHighlight extends React.Component {

	constructor(props){
		super(props);

		this.attributeToDisplay = props.attributeToDisplay || 'name';

		this.state = {
			toolbarProfileMenu_state: null,
		}
	}

	//======================= Custom inner component ========================

	indicatorsContainer = (props) => {
		return (
			<div style={{
				display: 'flex',
				alignItems: 'center'
			}}>
				{props.selectAll &&
					<CheckboxMultipleMarked
						onClick={this.selectAll_handler}
						className={props.classes.selectAllButton}
					/>
				}
				<components.IndicatorsContainer {...props}/>
			</div>
		);
	};

	formatOptionLabel = (object, context) => {
		return (
			<React.Fragment>
				{(object.color === undefined || object.icon || (this.props.isMulti && (context.selectValue.find(el => el.id === object.id) !== undefined ))) ||
					<CheckboxBlank
						style={{
							color:object.color,
							marginRight:"2%",
							fontSize:"16px"
						}}
					/>
				}
				<Highlighter
					searchWords={[context.inputValue]}
					textToHighlight={object[this.attributeToDisplay]}
				/>
			</React.Fragment>
		);
	}

	//======================= UI Callback ========================

	selectAll_handler = (event) => {
		event.preventDefault();
		this.props.selectAll_handler();
	}

	change_handler = (event) => {
		if(!event){
			event = [];
		}
		this.props.change_handler(event);
	}

    //======================= Page Render ========================

	render() {
		const {classes} = this.props;
		return(
			<React.Fragment>
				{this.props.label !== undefined ?
					<Typography variant="body1" >
						{this.props.label}
					</Typography>
				: null}
				<Select
					isMulti={this.props.isMulti}
					formatOptionLabel={this.formatOptionLabel}
					options={this.props.suggestions.sort((a, b)=> {
						let nameA = a[this.attributeToDisplay].toUpperCase();
						let nameB = b[this.attributeToDisplay].toUpperCase();
						return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
					})}
					errorMessage={this.props.errorMessage}
					// isOptionDisabled={(option) => option.disabled}
					getOptionValue={(option) => (option['id'])}
					getOptionLabel={(option) => (option[this.attributeToDisplay])}
					components={{
						IndicatorsContainer: (props) => {
							return(this.indicatorsContainer({
								...props,
								classes: classes,
								selectAll: this.props.selectAll
							}))
						}
					}}
					isDisabled={(this.props.disabled !== undefined ? this.props.disabled : false)}
					styles={{
						singleValue: styles => ({...styles, width:"calc(100% - 8px)"}),
						control: (styles,options) => {
							return(
								{
									...styles,
									borderColor: (options.selectProps.errorMessage !== undefined && options.selectProps.errorMessage !== null && options.selectProps.errorMessage !== "") ? 'red' : 'hsl(0,0%,80%)',
									'&:hover': {
										borderColor: (options.selectProps.errorMessage !== undefined && options.selectProps.errorMessage !== null && options.selectProps.errorMessage !== "") ? 'red' : 'hsl(0,0%,80%)'
									}
								}
							)
						},
						multiValue: (styles, { data }) => {
							const color = data.color ? chroma(data.color) : chroma('#AAAAAA');
							return {
								...styles,
								backgroundColor: color.alpha(0.1).css(),
							};
						},
						multiValueLabel: (styles, { data }) => ({
							...styles,
							color: data.color || '#AAAAAA',
						}),
						multiValueRemove: (styles, { data }) => ({
							...styles,
							color: data.color || '#AAAAAA',
							':hover': {
								backgroundColor: data.color || '#AAAAAA',
								color: 'white',
							},
						}),
					}}
					value={this.props.selectedValue}
					onChange={this.change_handler}
					onSelectAll={this.props.selectAll_handler}
				/>
				{(this.props.errorMessage !== null && this.props.errorMessage !== "") ?
					<div className={classes.errorMessage}>{this.props.errorMessage}</div>
				: null}
			</React.Fragment>

		);
	}
}

AutoCompleteHighlight.propTypes = {
	suggestions: PropTypes.array.isRequired,
	change_handler: PropTypes.func,
	selectedValue: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array
	]),
	errorMessage: PropTypes.string,
	attributeToDisplay: PropTypes.string,
	disabled: PropTypes.bool,
	label:PropTypes.string,
	isMulti: PropTypes.bool,
	selectAll: PropTypes.bool,
	selectAll_handler: PropTypes.func
};

export default withStyles(styles,{withTheme: true})(AutoCompleteHighlight);