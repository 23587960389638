import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {Grid,
    Card,
    CardContent,
    CardHeader,
    Button} from '@material-ui/core';

import{
    ContentSave,
} from 'mdi-material-ui';
import BasicClientForm from 'components/Clients/BasicClientForm.jsx'

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    update:{
        backgroundColor: theme.button.update,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    clientContainer:{
        overflow:'visible'
    }
});

class ClientViewOne extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            name_fixed: "",
            name: "",
            color: "",
            description: "",
			name_errorMessage: "",
            description_errorMessage: "",
            userId:-1,
            user_errorMessage: "",
        };
        this.updateRight = this.props.user.adminPermission.clients.createUpdate;
        if(!this.updateRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    componentDidMount = () => {
		this.props.apiControllers.clients.getById(this.props.match.params.clientId,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token
        }).then((response)=>{
            this.props.logger.debug(response)
            this.setState({
                name_fixed: response.data.name,
                name: response.data.name,
                description: response.data.description,
                color: response.data.color,
                userId: response.data.userId,
            });

        }).catch((error) => {
            if(error.status === 403 || error.status === 404 || error.status === 400){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

    //======================= UI Callback ========================

    update_click = () => {
        if(this.state.name === ""){
            this.setState({name_errorMessage: "Le champ ne peut être vide"});
        } else if(this.state.userId === -1){
            this.setState({user_errorMessage: "Vous devez choisir un utilisateur"});
        } else {
            this.props.apiControllers.clients.update(
                this.props.match.params.clientId,
                {
                    name: this.state.name,
                    color: this.state.color,
                    userId: this.state.userId,
                    description: this.state.description
                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }).then((response) => {
                    this.props.notifier.success('Client correctement modifié');
                    this.props.updateInnerUser()
                        .then(() => {this.props.history.goBack()});
                }).catch((error) => {
                    this.props.notifier.error(error.data);
                });
        }
    }

    name_change = (event) => {
        this.setState({
            name: event.target.value,
            name_errorMessage: ""
        });
    }

    color_change = (color) => {
        this.setState({ color: color.hex });
    };

    description_change = (event) => {
        this.setState({
            description: event.target.value,
            description_errorMessage: ""
        });
    }

    userId_change = (event) => {
        this.setState({
            userId: event.target.value,
            user_errorMessage: ""
        });
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        className={classes.clientContainer}
                    >
                        <CardHeader
                            title={"Modification du client "+this.state.name_fixed}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <BasicClientForm
                                        apiControllers={this.props.apiControllers}
                                        logger={this.props.logger}
                                        name={this.state.name}
                                        description={this.state.description}
                                        color={this.state.color}
                                        userId={this.state.userId}
                                        auth_user={this.props.user}
                                        name_errorMessage={this.state.name_errorMessage}
                                        description_errorMessage={this.state.description_errorMessage}
                                        user_errorMessage={this.state.user_errorMessage}
                                        name_change={this.name_change}
                                        description_change={this.description_change}
                                        color_change={this.color_change}
                                        userId_change={this.userId_change}
                                        checkAuthentication={this.props.checkAuthentication}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={this.update_click}
                                        className={classNames(classes.button,classes.update)}
                                        variant="contained"
                                    >
                                        <ContentSave className={classes.leftIcon} />
                                        Enregistrer les modifications
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

ClientViewOne.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(ClientViewOne);
