import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Grid,
    TextField,
    InputAdornment,
    Typography
} from '@material-ui/core';

const styles = theme => ({
});

class DaysConfigurationForm extends React.Component {

	//======================= UI Callback ========================
    days_change = (event) => {
        let daysPerYear = isNaN(parseInt(event.target.value)) ? event.target.value : parseInt(event.target.value);
        let hoursConfiguration = {...this.props.hoursConfiguration};
        hoursConfiguration.daysPerYear = daysPerYear;
        this.props.setHoursConfiguration_handler(hoursConfiguration);
    }

    //======================= Page Render ========================

    render() {
        return (
            (this.props.hoursConfiguration ?
                <Grid container>
                    <Grid container item xs={5} alignItems="center">
                        <Grid item xs={3}>
                            <TextField
                                id="days"
                                label="Jours / an"
                                value={this.props.hoursConfiguration.daysPerYear}
                                onChange={this.days_change}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.daysPerYear !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.daysPerYear}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 210,
                                        step: 1
                                    },
                                    endAdornment: <InputAdornment position="end">j/an</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h3">
                                = {(((
                                    this.props.hoursConfiguration.daysPerYear
                                )*100)/210).toFixed(2)} %
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            : null)
        );
    }
}

DaysConfigurationForm.propTypes = {
    hoursConfiguration: PropTypes.object,
    setHoursConfiguration_handler: PropTypes.func.isRequired
};

export default withStyles(styles,{withTheme: true})(DaysConfigurationForm);
