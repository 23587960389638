import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import EventsCalendar from 'components/Events/EventsCalendar.jsx';

import moment from 'moment';

// import './Edit.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import AddEventForm from 'components/Events/AddEventForm.jsx'

import {dateFormat} from 'variables/general.jsx';
import EventsFetchers from 'utils/eventsFetchers.js';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
	CardHeader,
	Card,
	Typography,
    CardContent,
} from '@material-ui/core';

const styles = theme => ({
	calendarContainer:{
		paddingBottom:"0!important",
	},
	formCardsContainer:{
		flexDirection: 'column'
	},
	addEventCard_container:{
		flex:1
	},
    addEventCard:{
		overflow:"visible",
		"&>div>*":{
			margin:"2% 0"
		}
	},
});

const eventsFetchers = new EventsFetchers();

class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			events: [],
			calendarRange:{
				from:new Date(moment().startOf('month')),
				to:new Date(moment().endOf('month'))
			},
            selectedDate: moment()
		};
        this.alreadyFetchedRanges = [];

        this.creationRight = this.props.user.adminPermission.events.createUpdate;

        if(!this.creationRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	componentDidMount = () => {
		this.getThisMonthEvents();
	}

    //======================= Tool Functions ========================

	getThisMonthEvents = () => {
		let start_date = moment(this.state.calendarRange.from).format(dateFormat);
		let end_date = moment(this.state.calendarRange.to).format(dateFormat);
		this.props.loadingDialog.open();
		return eventsFetchers.fetchPeriodEvent(start_date,end_date,this.alreadyFetchedRanges,this.props.apiControllers.events,this.props.user,this.props.logger).then((events) => {

			let eventsBase = this.state.events;
			if(events !== undefined && events !== null){
				let eventsToAdd = events;
				for(let event of eventsToAdd){
					eventsBase.push(event);
				}
				this.props.logger.debug(eventsToAdd);
			}
			this.props.loadingDialog.close();
            return this.promisedSetState({events: eventsBase});
		}).catch((error) => {
            if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
			this.props.loadingDialog.close();
		});
	}

    //======================= UI Callback ========================

	calendarRangeChange_handler = (dateRange) => {
		this.setState({calendarRange:dateRange},
			this.getThisMonthEvents);
	}

	calendarDateChange_handler = (date) => {
		this.setState({selectedDate: date});
	}

	addEvent_handler = (event) => {
		if(event !== undefined){
			if(eventsFetchers.rangeAlreadyFetched(event.date, event.date, this.alreadyFetchedRanges, this.props.logger)){
				let eventsBase = this.state.events;
				eventsBase.push(event);
				this.setState({events:eventsBase});
				this.getThisMonthEvents();
			}
		}
	}

	updateEvent_handler = (event) => {
		if(event !== undefined){
			let eventsBase = this.state.events;
			let index = eventsBase.indexOf(eventsBase.find(el => el.id === event.id));
			eventsBase[index] = event;
			this.setState({events:eventsBase});
			this.getThisMonthEvents();
		}
	}

	deleteEvent_handler = (event) => {
		if(event !== undefined){
			let eventsBase = this.state.events;
			let eventIndex = eventsBase.indexOf(eventsBase.find(el => el.id === event.id));
			if(eventIndex >= 0){
				eventsBase.splice(eventIndex,1);
			}
			this.setState({events:eventsBase});
			this.getThisMonthEvents();
		}
    }

    titleChange_handler = (event) => {
        this.setState({form_title: event.target.value});
		let value = event.target.value;
		if(value  !== ''){
			this.setState({form_title_errorMessage: 'Rentrez un texte'});
		}else{
			this.setState({form_title_errorMessage: ''});
		}
    }

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<Grid container spacing={2} className='dashboardContainer'>
					<Grid container item xs={3} spacing={2} className={classes.formCardsContainer}>
						<Grid item xs={12} className={classes.addEventCard_container}>

							<Card className={classes.addEventCard}>
								<CardHeader
									title={
										<Typography variant='h2'>
											Ajouter un événement
										</Typography>
									}
								/>
								<CardContent>
									<AddEventForm
										addEvent_handler={this.addEvent_handler}
										auth_user={this.props.user}
										notifier={this.props.notifier}
										logger={this.props.logger}
										apiControllers={this.props.apiControllers}
										checkAuthentication={this.props.checkAuthentication}
										selectedDate={this.state.selectedDate}
									/>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				<Grid item xs={9} className={classes.calendarContainer}>
					<EventsCalendar
						rangeChange_handler={this.calendarRangeChange_handler}
						dateChange_handler={this.calendarDateChange_handler}
						deleteEvent_handler={this.deleteEvent_handler}
						events={this.state.events}
						apiControllers={this.props.apiControllers}
						notifier={this.props.notifier}
						logger={this.props.logger}
						user={this.props.user}
						checkAuthentication={this.props.checkAuthentication}
					/>
				</Grid>
			</Grid>

		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(Dashboard);
