const entityDisplayNames = {
    users:'Utilisateurs',
    projects:'Projets',
    categories:'Catégories',
    tags:'Tags',
    clients:'Clients',
}

const entityEmbeddedName = {
    users:'user',
    projects:'project',
    categories:'category',
}

const entityAttributeId = {
    users:'userId',
    projects:'projectId',
    categories:'categoryId',
}

const entityAttributeName = {
    users:'displayname',
    projects:'name',
    categories:'name',
}

const contractTypes = {
    hours: 'Forfait heures',
    days: 'Forfait jours'
}

const dateFormat = 'YYYY-MM-DD';

const entityElements = {
    users:[
        {name:"id",label:"id",toDisplay:false, type:'number'},
        {name:"displayname",label:"Nom",toDisplay:true, type:'string', filterable:true},
        {name:"username",label:"Nom d'utilisateur",toDisplay:true, type:'string'},
        {name:"joiningDate",label:"Date d'entrée",toDisplay:true, type:'string'},
    ],
    projects:[
        {name:"id",label:"id",toDisplay:false, type:'number'},
        {name:"name",label:"Nom",toDisplay:true, type:'string', filterable:true},
        {name:"user.displayname",label:"Manager",toDisplay:true, type:'string'},
        {name:"client.name",label:"Client",toDisplay:true, type:'string'},
        {name:"color",label:"Couleur",toDisplay:true, type:'color'},
    ],
    categories:[
        {name:"id",label:"id",toDisplay:false, type:'number'},
        {name:"name",label:"Nom",toDisplay:true, type:'string', filterable:true},
        {name:"color",label:"Couleur",toDisplay:true, type:'color'},
    ],
    tags:[
        {name:"id",label:"id",toDisplay:false, type:'number'},
        {name:"name",label:"Nom",toDisplay:true, type:'string', filterable:true},
        {name:"color",label:"Couleur",toDisplay:true, type:'color'},
    ],
    clients:[
        {name:"id",label:"id",toDisplay:false, type:'number'},
        {name:"name",label:"Nom",toDisplay:true, type:'string', filterable:true},
        {name:"user.displayname",label:"Interlocuteur",toDisplay:true, type:'string'},
        {name:"color",label:"Couleur",toDisplay:true, type:'color'},
    ]
}

function resolveObjectPath(path, obj) {
    var properties = Array.isArray(path) ? path : path.split('.')
    return properties.reduce((prev, curr) => prev && prev[curr], obj)
}

module.exports = {
    entityDisplayNames,
    entityElements,
    dateFormat,
    entityAttributeId,
    entityAttributeName,
    entityEmbeddedName,
    contractTypes,
    resolveObjectPath
};