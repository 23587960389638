import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow
} from "@material-ui/core";


import { resolveObjectPath } from "variables/general.jsx";

const headStyles = theme => ({
	headLabel: {
		paddingLeft:0
	},
});

class TableHeader extends React.Component {

	render() {
		const { classes } = this.props;
		return (
			<TableHead>
				<TableRow>
					{this.props.properties.map(property => (
						<TableCell
							className={classes.headLabel}
							key={property.name}
						>
							{property.label}
						</TableCell>
					),this)}
				</TableRow>
			</TableHead>
		);
	}
}

TableHeader.propTypes = {
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	properties: PropTypes.array.isRequired,
};

TableHeader = withStyles(headStyles,{withTheme: true})(TableHeader);

const styles = theme => ({
	tableWrapper: {
		overflowX: "auto",
		paddingLeft:"20px"
	}
});

class PrintableTable extends React.Component {

	//======================= Tool Functions ========================

	sortData = (data) => {
		let sortedData = [...data];

		sortedData.sort((a,b) => {
			let testA = a[this.props.orderBy];
			let testB = b[this.props.orderBy];
			if(typeof testA === 'string'){
				testA = testA.toLowerCase();
				testB = testB.toLowerCase();
			}
			if(this.props.order === 'asc'){
				return(testA > testB ? 1 : ((testB > testA) ? -1 : 0));
			}
			else{
				return(testA < testB ? 1 : ((testB < testA) ? -1 : 0));
			}
		});
		return sortedData;
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<React.Fragment>
				<div className={classes.tableWrapper}>
					<Typography
						variant="h2"
					>
						{this.props.title}
					</Typography>
					<Table className={classes.table} aria-labelledby="tableTitle">
						<TableHeader
							order={this.props.order}
							orderBy={this.props.orderBy}
							requestSort_handler={this.requestSort_handler}
							rowCount={this.props.data.length}
							selectAllClick_handler={this.selectAllClick_handler}
							properties={this.props.properties}
						/>
						<TableBody>
							{this.sortData(this.props.data)
							.map(element=>{
								return (
									<TableRow
										hover
										tabIndex={-1}
										key={element.id}
									>
										{this.props.properties.map(property => (
											<TableCell scope="row" padding="none" key={property.name}>
												{resolveObjectPath(property.name,element)} {property.suffix || ''}
											</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</div>
			</React.Fragment>
		);
	}
}

PrintableTable.propTypes = {
	orderBy: PropTypes.string.isRequired,
	order: PropTypes.string.isRequired,
	properties: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	dataSelected_handler: PropTypes.func.isRequired,
	title:PropTypes.string.isRequired
};

export default withStyles(styles,{withTheme: true})(PrintableTable);
