import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Select, {components} from 'react-select';
import classNames from 'classnames';
import SuppressDialog from 'components/SuppressDialog/SuppressDialog.jsx';
import {Grid,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Button,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';

import{
    ContentSave,
    Lock,
    Cancel,
    CloseCircle,
    Plus,
    Delete
} from 'mdi-material-ui';

import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';


import {dateFormat, contractTypes} from 'variables/general.jsx';

import moment from "moment";
import "moment/locale/fr";
import MomentUtils from '@date-io/moment';


import ChangePasswordDialog from "components/ChangePasswordDialog/ChangePasswordDialog.jsx"
import RightsTable from "components/Users/RightsTable.jsx"
import HoursConfigurationForm from "components/Users/HoursConfigurationForm.jsx"
import DaysConfigurationForm from "components/Users/DaysConfigurationForm.jsx"
moment.locale("fr");

const defaultHoursConfiguration =  {
    hours:{
        type:'hours',
        days:{
            monday: 7,
            tuesday: 7,
            wednesday: 7,
            thursday: 7,
            friday: 7,
            saturday: 0,
            sunday:0
        }
    },
    days:{
        type:'days',
        daysPerYear:210
    }
};

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    update:{
        backgroundColor: theme.button.update,
        color: theme.palette.primary.contrastText
    },
    creation:{
        backgroundColor: theme.button.creation,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    deletion:{
        backgroundColor: theme.button.deletion,
        color: theme.palette.primary.contrastText
    },
    contractsContainer:{
		overflow:"visible"
	},
});

class UserProfile extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            displayName_fixed: "",
            displayname: "",
            username: "",
            joiningDate: moment(new Date()).format(dateFormat),
            date_start: null,
            date_end: null,
            userId: null,
            changePasswordDialog_state: false,
			username_errorMessage: "",
            displayname_errorMessage: "",
            test_state:false,
            adminPermission: undefined,
            hoursConfiguration: undefined,
            hoursDays_errorMessage:{
                monday:'',
                tuesday:'',
                wednesday:'',
                thursday:'',
                friday:'',
                saturday:'',
                sunday:'',
                daysPerYear:''
            },
            contracts:[],
            selectedContract:undefined,
            finishContractDialog_state:false,
            supressContractDialogOpen:false
        };
        this.owner = this.props.user.id ===  parseInt(this.props.match.params.userId);

        this.updateRight = this.props.user.adminPermission.users.createUpdate;
        if(!this.updateRight && !this.owner){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    componentDidMount = () => {
		this.props.apiControllers.users.getById(this.props.match.params.userId,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token
        }).then((response)=>{
            let selectedContract = (response.data.userContracts.find(contract => contract.date_end === null) || response.data.userContracts[response.data.userContracts.length -1]);
            console.log(selectedContract)
            this.setState({
                displayName_fixed: response.data.displayname,
                displayname: response.data.displayname,
                username: response.data.username,
                userId: response.data.id,
                adminPermission: response.data.adminPermission,
                joiningDate: response.data.joiningDate,
                hoursConfiguration: (selectedContract ? selectedContract.hoursConfiguration : defaultHoursConfiguration.hours),
                contracts:response.data.userContracts,
                selectedContract: selectedContract,
                date_start: (selectedContract ? selectedContract.date_start : null),
                date_end: (selectedContract ? selectedContract.date_end : null)

            });
        }).catch((error) => {
            if(error.status === 403 || error.status === 404 || error.status === 400){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

    //======================= Tool Functions ========================

    checkHoursConfiguration = () => {
        if(this.state.hoursConfiguration.type==='hours'){
            return this.checkHoursConfigurationHours();
        }else{
            return this.checkHoursConfigurationDays();
        }
    }

    checkHoursConfigurationHours = () => {
        let res = true;
        let messages = {
            monday:'',
            tuesday:'',
            wednesday:'',
            thursday:'',
            friday:'',
            saturday:'',
            sunday:'',
            daysPerYear:''
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.monday)) || this.state.hoursConfiguration.days.monday < 0 || this.state.hoursConfiguration.days.monday > 12 || (this.state.hoursConfiguration.days.monday % 0.5) !== 0){
            messages.monday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.tuesday)) || this.state.hoursConfiguration.days.tuesday < 0 || this.state.hoursConfiguration.days.tuesday > 12 || (this.state.hoursConfiguration.days.tuesday % 0.5) !== 0){
            messages.tuesday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.wednesday)) || this.state.hoursConfiguration.days.wednesday < 0 || this.state.hoursConfiguration.days.wednesday > 12 || (this.state.hoursConfiguration.days.wednesday % 0.5) !== 0){
            messages.wednesday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.thursday)) || this.state.hoursConfiguration.days.thursday < 0 || this.state.hoursConfiguration.days.thursday > 12 || (this.state.hoursConfiguration.days.thursday % 0.5) !== 0){
            messages.thursday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.friday)) || this.state.hoursConfiguration.days.friday < 0 || this.state.hoursConfiguration.days.friday > 12 || (this.state.hoursConfiguration.days.friday % 0.5) !== 0){
            messages.friday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.saturday)) || this.state.hoursConfiguration.days.saturday < 0 || this.state.hoursConfiguration.days.saturday > 12 || (this.state.hoursConfiguration.days.saturday % 0.5) !== 0){
            messages.saturday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.sunday)) || this.state.hoursConfiguration.days.sunday < 0 || this.state.hoursConfiguration.days.sunday > 12 || (this.state.hoursConfiguration.days.sunday % 0.5) !== 0){
            messages.sunday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        this.setState({hoursDays_errorMessage:messages});
        return res;
    }

    checkHoursConfigurationDays = () => {
        let res = true;
        let messages = {
            monday:'',
            tuesday:'',
            wednesday:'',
            thursday:'',
            friday:'',
            saturday:'',
            sunday:'',
            daysPerYear:''
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.daysPerYear))){
            messages.monday = 'Le champ doit être compris entre 0 et 365, par pas de 1';
            res = false;
        }
        this.setState({hoursDays_errorMessage:messages});
        return res;
    }

    //======================= UI Callback ========================

    changePassword_click = () => {
        this.setState({changePasswordDialog_state: true});
    }

    changePasswordDialog_closeHandler = () => {
        this.setState({changePasswordDialog_state: false});
    }

    createContract_clicked = () => {
        this.setState({
            date_start:moment(),
            date_end:null,
            selectedContract:{},
            hoursConfiguration:Object.assign(defaultHoursConfiguration.hours)
        });
    }

    finishContract_click = () => {
        this.setState({
            finishContractDialog_state : true,
            date_end:moment()
        });
    }

    finishContractDialog_closeHandler = () => {
        this.setState({finishContractDialog_state : false});
    }

    updateProfile_click = () => {
        if(this.state.displayname === ""){
            this.setState({displayname_errorMessage: "Le champ ne peut être vide"});
        } else if (this.state.username === ""){
            this.setState({username_errorMessage: "Le champ ne peut être vide"});
        } else{
            this.props.apiControllers.users.update(
                this.props.match.params.userId,{
                    displayname: this.state.displayname,
                    username: this.updateRight ? this.state.username : undefined,
                    joiningDate: this.updateRight ? moment(this.state.joiningDate).format("YYYY-MM-DD") : undefined,

                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }).then((response) => {
                    this.props.notifier.success('Modification enregistrées');
                    this.props.updateInnerUser()
                        .then(() => {this.props.history.goBack()});
                }).catch((error) => {
                    this.props.notifier.error(error.data);
                });
        }
    }

    updateRights_click = () => {
        this.props.apiControllers.users.update(
            this.props.match.params.userId,{
                adminPermission: this.updateRight ? this.state.adminPermission : undefined,
            },{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token
            }
        ).then((response) => {
            this.props.notifier.success('Modification enregistrées');
            this.props.updateInnerUser()
                .then(() => {this.props.history.goBack()});
        }).catch((error) => {
            this.props.notifier.error(error.data);
        });
    }

    updateContract_click = () => {
        if (this.checkHoursConfiguration()){
            this.props.apiControllers.userContracts.update(
                this.state.selectedContract.id,{
                    hoursConfiguration: this.state.hoursConfiguration ? this.state.hoursConfiguration : undefined,
                    date_start: this.updateRight ? moment(this.state.date_start).format("YYYY-MM-DD") : undefined,
                    date_end: (this.updateRight && this.state.date_end) ? moment(this.state.date_end).format("YYYY-MM-DD") : undefined,

                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }
            ).then((response) => {
                this.props.notifier.success('Modification enregistrées');
                let interContracts = this.state.contracts;
                let interContract = interContracts.find(contract => contract.id === this.state.selectedContract.id);
                interContract.hoursConfiguration = response.data.hoursConfiguration;
                interContract.date_start = response.data.date_start;
                interContract.date_end = response.data.date_end;
                this.setState({
                    contracts:interContracts
                },() => {this.props.updateInnerUser()});
            }).catch((error) => {
                this.props.notifier.error(error.data);
            });
        }
    }

    cancelFinishContract = () => {
        this.setState({date_end:null},()=>{this.finishContractDialog_closeHandler()});
    }

    confirmFinishContract = () => {
        this.props.apiControllers.userContracts.update(
            this.state.selectedContract.id,{
                date_end: (this.updateRight) ? moment(this.state.date_end).format("YYYY-MM-DD") : undefined,
            },{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token
            }
        ).then((response) => {
            let contracts = this.state.contracts;
            contracts.find(contract => contract.id === this.state.selectedContract.id).date_end = this.state.date_end;

            this.props.notifier.success('Contrat terminé');
            this.setState({
                contracts:contracts
            }, () => {
                this.props.updateInnerUser()
                    .then(() => {this.finishContractDialog_closeHandler()});
            });
        }).catch((error) => {
            this.props.notifier.error(error.data);
        });
    }

    saveNewContract_click = () => {
        if (this.checkHoursConfiguration()){
            this.props.apiControllers.userContracts.create({
                    hoursConfiguration: this.state.hoursConfiguration ? this.state.hoursConfiguration : undefined,
                    date_start: this.updateRight ? moment(this.state.date_start).format("YYYY-MM-DD") : undefined,
                    date_end: (this.updateRight && this.state.date_end) ? moment(this.state.date_end).format("YYYY-MM-DD") : undefined,
                    userId: this.state.userId,
                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }
            ).then((response) => {
                this.props.notifier.success('Contrat créé');
                let contracts = this.state.contracts;
                contracts.push(response.data);
                this.props.logger.debug(contracts);
                this.setState({
                    contracts : contracts,
                }, () => {
                    this.props.updateInnerUser();
                    this.contractChange_handler(response.data)
                });
            }).catch((error) => {
                this.props.notifier.error(error.data);
            });
        }
    }

    deleteContract_handler = () => {
        this.props.apiControllers.userContracts.delete(
            this.state.selectedContract.id,{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token
            }
        ).then((response) => {
            this.props.notifier.success('Contrat supprimé');
            let contracts = this.state.contracts;
            contracts.splice(contracts.findIndex(contract => contract.id === this.state.selectedContract.id), 1);
            let selectedContract = (contracts.find(contract => contract.date_end === null) || contracts[contracts.length -1]);
            this.setState({
                contracts:contracts,
            }, () => {
                this.props.updateInnerUser()
                .then(() => {
                    this.closeSuppressContractDialog_handler()
                    this.contractChange_handler(selectedContract)
                });
            });
        }).catch((error) => {
            this.props.notifier.error(error.data);
        });
    }

    openSuppressContractDialog_handler = () => {
        this.setState({
            supressContractDialogOpen:true
        })
    }

    closeSuppressContractDialog_handler = () => {
        this.setState({
            supressContractDialogOpen:false
        })
    }

    displayname_change = (event) => {
        this.setState({
            displayname: event.target.value,
            displayname_errorMessage: ""
        });
    }

    username_change = (event) => {
        this.setState({
            username: event.target.value,
            username_errorMessage: ""
        });
    }

    setHoursConfigurationHours_handler = (hoursConfiguration) => {
        this.setState({
            hoursConfiguration: hoursConfiguration,
            hoursDays_errorMessage: {
                monday:'',
                tuesday:'',
                wednesday:'',
                thursday:'',
                friday:'',
                saturday:'',
                sunday:'',
                daysPerYear:''
            }
        },this.checkHoursConfigurationHours);
    }

    setHoursConfigurationDays_handler = (hoursConfiguration) => {
        this.setState({
            hoursConfiguration: hoursConfiguration,
            hoursDays_errorMessage: {
                monday:'',
                tuesday:'',
                wednesday:'',
                thursday:'',
                friday:'',
                saturday:'',
                sunday:'',
                daysPerYear:''
            }
        },this.checkHoursConfigurationDays);
    }

    contractTypeChange_handler = (type) => {
        let hoursConfiguration = this.state.hoursConfiguration;
        if(hoursConfiguration.type !== type.value){
            this.setState({
                hoursConfiguration:Object.assign(defaultHoursConfiguration[type.value])
            });
        }
    }


    joiningDate_change = (date) => {
        this.setState({
            joiningDate: date,
        })
    }

    date_start_change = (date) => {
        this.setState({
            date_start: date,
        })
    }

    date_end_change = (date) => {
        this.setState({
            date_end: date,
        })
    }

    contractChange_handler = (contract) => {
        this.setState({
            selectedContract: contract,
            date_start: contract.date_start,
            date_end:contract.date_end || null,
            hoursConfiguration: contract.hoursConfiguration
        });
    }

    test_clicked = (event) => {
        this.setState({
            test_state:!this.state.test_state
        })
    }

    changePermissions_handler = (adminPermission) => {
        this.setState({
            adminPermission: adminPermission
        });
    }

    //======================= Page render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Profil de "+this.state.displayName_fixed}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <TextField
                                        id="displayname-input"
                                        label="Nom affiché"
                                        value={this.state.displayname}
                                        onChange={this.displayname_change}
                                        margin="normal"
                                        error={this.state.displayname_errorMessage !== "" ? true : false}
                                        helperText={this.state.displayname_errorMessage}
                                        disabled={!this.updateRight && !this.owner}
                                        />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        id="username-input"
                                        label="Nom d'utilisateur"
                                        value={this.state.username}
                                        onChange={this.username_change}
                                        margin="normal"
                                        error={this.state.username_errorMessage !== "" ? true : false}
										helperText={this.state.username_errorMessage}
                                        disabled={!this.updateRight}
									/>
                                </Grid>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            margin="normal"
                                            label="Date d'entrée"
                                            value={this.state.joiningDate}
                                            onChange={this.joiningDate_change}
                                            disabled={!this.updateRight}
                                            format="DD/MM/YYYY"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {(this.updateRight || this.owner) ?
                                    <React.Fragment>
                                            <Grid item xs={6}>
                                                <Button
                                                    onClick={this.changePassword_click}
                                                    color="secondary"
                                                    className={classes.button}
                                                    variant="contained">
                                                    <Lock className={classes.leftIcon} />
                                                    Modifier le mot de passe
                                                </Button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Button
                                                    onClick={this.updateProfile_click}
                                                    color="secondary"
                                                    className={classNames(classes.button,classes.update)}
                                                    variant="contained"
                                                >
                                                    <ContentSave className={classes.leftIcon} />
                                                    Enregistrer les modifications
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                : null}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.contractsContainer}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h3">
                                        Contrats
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <React.Fragment>
                                        <Typography variant="body1" >
                                            Contrat
                                        </Typography>
                                        <Select
                                            isMulti={false}
                                            options={this.state.contracts.sort((a, b)=> {
                                                return (moment(a.date_start).isBefore(b.date_start) ? -1 : (moment(a.date_start).isAfter(b.date_start)) ? 1 : 0);
                                            })}
                                            getOptionValue={(option) => (option['id'])}
                                            getOptionLabel={(option) => (option.date_start ? (
                                                    moment(option.date_start).format('DD/MM/YYYY')+' -> '+(option.date_end ?moment(option.date_end).format('DD/MM/YYYY') : 'Actuel')
                                                ) : 'Nouveau Contrat')
                                            }
                                            value={this.state.selectedContract}
                                            onChange={this.contractChange_handler}
                                        />
                                    </React.Fragment>
                                </Grid>
                                <Grid container spacing={2} item xs={4}>
                                    {this.updateRight && (
                                        <React.Fragment>
                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    className={classNames(classes.button,classes.deletion)}
                                                    onClick={this.openSuppressContractDialog_handler}
                                                >
                                                    <Delete className={classes.leftIcon} />
                                                    Supprimer le Contrat
                                                </Button>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <Button
                                                    variant="contained"
                                                    className={classNames(classes.button,classes.creation)}
                                                    onClick={this.createContract_clicked}
                                                >
                                                    <Plus className={classes.leftIcon} />
                                                    Créer un Contrat
                                                </Button>
                                            </Grid>
                                        </React.Fragment>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            margin="normal"
                                            label="Date de début"
                                            value={this.state.date_start}
                                            onChange={this.date_start_change}
                                            format="DD/MM/YYYY"
                                            disabled={!this.updateRight}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3}>
                                    {(this.state.selectedContract && this.state.selectedContract.id) &&
                                        <React.Fragment>
                                            {this.state.date_end ?
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        margin="normal"
                                                        label="Date de fin"
                                                        value={this.state.date_end}
                                                        onChange={this.date_end_change}
                                                        format="DD/MM/YYYY"
                                                        disabled={!this.updateRight}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            : (this.updateRight && (
                                                <Grid item xs={6}>
                                                    <Button
                                                        onClick={this.finishContract_click}
                                                        color="secondary"
                                                        className={classNames(classes.button,classes.deletion)}
                                                        variant="contained"
                                                    >
                                                        <Cancel className={classes.leftIcon}/>
                                                        Terminer le contrat
                                                    </Button>
                                                </Grid>
                                            ))
                                            }
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Grid>
                            {(this.state.selectedContract && this.updateRight) &&
                                <React.Fragment>
                                    <Grid container spacing={8}>
                                        <Grid item xs={2}>
                                            <React.Fragment>
                                                <Typography variant="body1" >
                                                    Type de contrat
                                                </Typography>
                                                <Select
                                                    isMulti={false}
                                                    options={[{value:'hours', label:contractTypes.hours},{value:'days', label:contractTypes.days}]}
                                                    getOptionValue={(option) => (option.value)}
                                                    getOptionLabel={(option) => (option.label)
                                                    }
                                                    value={{value:this.state.hoursConfiguration.type,label:contractTypes[this.state.hoursConfiguration.type]}}
                                                    onChange={this.contractTypeChange_handler}
                                                />
                                            </React.Fragment>
                                        </Grid>
                                        <Grid item xs={10}>
                                            {this.state.hoursConfiguration.type === 'hours' ?
                                                <HoursConfigurationForm
                                                    hoursConfiguration={this.state.hoursConfiguration}
                                                    setHoursConfiguration_handler={this.setHoursConfigurationHours_handler}
                                                    hoursDays_errorMessage={this.state.hoursDays_errorMessage}
                                                />
                                            :
                                                <DaysConfigurationForm
                                                    hoursConfiguration={this.state.hoursConfiguration}
                                                    setHoursConfiguration_handler={this.setHoursConfigurationDays_handler}
                                                    hoursDays_errorMessage={this.state.hoursDays_errorMessage}
                                                />
                                            }
                                        </Grid>
                                    </Grid>
                                    {this.state.selectedContract.id ?
                                        <Grid item xs={10}>
                                            <Button
                                                onClick={this.updateContract_click}
                                                color="secondary"
                                                className={classNames(classes.button,classes.update)}
                                                variant="contained"
                                            >
                                                <ContentSave className={classes.leftIcon} />
                                                Enregistrer les modifications du contrat
                                            </Button>
                                        </Grid>
                                    :
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={this.saveNewContract_click}
                                                color="secondary"
                                                className={classNames(classes.button,classes.creation)}
                                                variant="contained"
                                            >
                                                <ContentSave className={classes.leftIcon} />
                                                Enregistrer le nouveau contrat
                                            </Button>
                                        </Grid>
                                    }
                                </React.Fragment>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card >
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h3">
                                        Droits
                                    </Typography>
                                </Grid>
                            </Grid>
                            <RightsTable
                                adminPermission={this.state.adminPermission}
                                changePermissions_handler={this.changePermissions_handler}
                            />
                            {(this.updateRight) ?
                                <React.Fragment>
                                        <Grid item xs={6}>
                                            <Button
                                                onClick={this.updateRights_click}
                                                color="secondary"
                                                className={classNames(classes.button,classes.update)}
                                                variant="contained"
                                            >
                                                <ContentSave className={classes.leftIcon} />
                                                Enregistrer les modifications
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                            : null}
                        </CardContent>
                    </Card>
                </Grid>
                <SuppressDialog
					open={this.state.supressContractDialogOpen}
					permanent={true}
					deleteHandler={this.deleteContract_handler}
                    closeHandler={this.closeSuppressContractDialog_handler}
				/>
                <ChangePasswordDialog
                    open={this.state.changePasswordDialog_state}
                    closeHandler={this.changePasswordDialog_closeHandler}
                    notifier={this.props.notifier}
                    logger={this.props.logger}
                    userManager={this.props.userManager}
                    userId={this.state.userId}
                    auth_user={this.props.user}
                    apiControllers={this.props.apiControllers}
                />
                <Dialog
                    open={this.state.finishContractDialog_state}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}
                >
                    <DialogTitle id="form-dialog-title">Terminer le contrat</DialogTitle>
                    <form>
                        <DialogContent>
                            <Typography variant='body1'>
                                Êtes-vous sûr de terminer le contrat ?
                            </Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    margin="normal"
                                    label="Date de fin"
                                    value={this.state.date_end}
                                    onChange={this.date_end_change}
                                    format="DD/MM/YYYY"
                                />
                            </MuiPickersUtilsProvider>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={this.confirmFinishContract}
                                className={classNames(classes.button,classes.deletion)}
                                variant="contained"
                            >
                                <Cancel className={classes.leftIcon} />
                                Terminer le contrat
                            </Button>
                            <Button
                                onClick={this.cancelFinishContract}
                                color="secondary"
                                className={classes.button}
                                variant="contained"
                            >
                                <CloseCircle className={classes.leftIcon} />
                                Annuler
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </Grid>
        );
    }
}

UserProfile.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(UserProfile);
