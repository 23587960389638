import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';

import { ChromePicker } from 'react-color'
import {
    Grid,
    TextField,
    Card,
    CardContent,
    CardHeader
} from '@material-ui/core';

const styles = theme => ({
    colorPicker: {
        width: '90% !important'
    },
    colorResult:{
        height: '100%'
    },
    tagsCard:{
        overflow: 'visible!important',
        width:"100%"
    },
});

class BasicCategoryForm extends React.Component {

    constructor(props){
        super(props);

		this.state = {
            tags: [],
		}
	}

    componentDidMount = () => {
        this.props.apiControllers.tags.getAll({
            auth_username:this.props.auth_user.username,
            auth_token:this.props.auth_user.token,
        }).then((response)=>{
            this.setState({
                tags: response.data
            });
        }).catch((error) => {
            if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

    //======================= UI Callback ========================

    selectAllTags_handler = () => {
        this.tagsChange_handler(this.state.tags.filter(tags => (!tags.disabled)));
    }

    tagsChange_handler = (tags) => {
        this.props.tags_changed(tags);
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid container item xs={4}>
                    <Grid item xs={12}>
                        <TextField
                            id="displayname-input"
                            label="Nom"
                            value={this.props.name}
                            onChange={this.props.name_change}
                            margin="normal"
                            error={this.props.name_errorMessage !== "" ? true : false}
                            helperText={this.props.name_errorMessage}
                            />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Card
                        className={classes.tagsCard}
                    >
                        <CardHeader
                            title={"Tags"}
                        />
                        <CardContent
                        >
                            <AutoCompleteHightlight
                                suggestions={this.state.tags.filter(tags => (!tags.disabled))}
                                change_handler={this.tagsChange_handler}
                                selectedValue={this.props.selectedTags}
                                errorMessage=""
                                disabled={false}
                                label="Tags :"
                                isMulti={true}
                                selectAll={true}
                                selectAll_handler={this.selectAllTags_handler}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={4}>
                    <Grid item xs={6}>
                        <ChromePicker
                            disableAlpha={true}
                            className={classes.colorPicker}
                            color={ this.props.color }
                            onChangeComplete={ this.props.color_change }
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Card
                            className={classes.colorResult}
                            style={{backgroundColor: this.props.color}}
                        >
                            <CardContent
                            ></CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

BasicCategoryForm.propTypes = {
    checkAuthentication: PropTypes.func.isRequired,
    apiControllers: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    auth_user: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    selectedTags: PropTypes.array.isRequired,
    name_errorMessage: PropTypes.string.isRequired,
    name_change: PropTypes.func.isRequired,
    color_change: PropTypes.func.isRequired,
    tags_changed: PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(BasicCategoryForm);
