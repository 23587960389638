import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';
import DatedLabelledProgressdBar from 'components/ProgressBar/DatedLabelledProgressBar.jsx';

import moment from 'moment';
// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
    Card,
	CardContent,
	Button,
	Typography,
	TextField,
	Switch,
	InputAdornment
} from '@material-ui/core';

import {
	ChartLine,
	Filter
} from 'mdi-material-ui';

const styles = theme => ({
    dashboardContainer:{
        alignContent:'flex-start'
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    toolBar:{
		overflow:"visible"
	},
});

class ClientManagmentDashboard extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            projectHours: [],
            clients: [],
			showDisabled: false,
            filter: '',
            clientSelected: undefined
		};

        this.viewRight = this.props.user.adminPermission.clients.viewDashboard || this.props.user.clients.length > 0;
		this.completeViewRight = this.props.user.adminPermission.clients.viewDashboard;
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
		}
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
    }

	componentDidMount = () => {
        if(this.completeViewRight){
            this.getAllClients();

        }else{
            this.setState({clients:this.props.user.clients});
        }
	}

    //======================= Tool Functions ========================
    getAllClients = () => {
		this.props.apiControllers.clients.getAll(
            {
                auth_username:this.props.user.username,
                auth_token:this.props.user.token,
            }
        ).then((res) => {
            this.setState({clients:res.data});
        });
	}

	getProjectsHoursSum = async() => {
        let projectHours = [];
		this.props.logger.debug(this.state.selectedClient.client);
		for(let project of this.state.selectedClient.projects){
			await this.props.apiControllers.timespents.getSumOfEntity(
				project.id,
				'projects',
				undefined,
				undefined,
				{
					auth_username:this.props.user.username,
					auth_token:this.props.user.token,
				}
			).then((res) => {
                projectHours[project.id] = res.data.sum;
			});
        }
        this.setState({projectHours:projectHours});
	}

	//======================= UI Callback ========================

	projectsCurve_clicked = (id) => (event) => {
        this.props.history.push(`/reporting/projects/oneProjectChart/${id}`);
	}

	showDisabled_changed = (event) => {
		this.setState({showDisabled : event.target.checked});
	}

	filterChange_handler = (event) => {
        this.setState({
            filter: event.target.value
        })
    }

    selectedClient_handler = (selectedClient) => {
        this.props.apiControllers.clients.getById(selectedClient.id,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token
        }).then((response)=>{
            this.setState({selectedClient: response.data},this.getProjectsHoursSum);
        });
    }


    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
        let projectsToShow = [];
        if(this.state.selectedClient){
            if(this.state.showDisabled){
                projectsToShow = this.state.selectedClient.projects;
            }else{
                projectsToShow = this.state.selectedClient.projects.filter(project => (!project.disabled));
            }

            if(this.state.filter !== ''){
                projectsToShow = projectsToShow.filter(project => project.name.toLowerCase().includes(this.state.filter.toLowerCase()));
            }

            projectsToShow.sort((a, b)=> {
                let nameA = a.name.toUpperCase();
                let nameB = b.name.toUpperCase();
                return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
            });

            this.props.logger.debug(projectsToShow)
        }


		return (
			<Grid container spacing={2} className='dashboardContainer' className={classes.dashboardContainer}>
				<Grid item xs={12}>
					<Card  className={classes.toolBar}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={2}>
									<Typography variant="h2">
										Projets clients
									</Typography>
								</Grid>
                                <Grid item xs={2}>
                                    <AutoCompleteHightlight
                                        suggestions={this.state.clients}
                                        change_handler={this.selectedClient_handler}
                                        errorMessage=''
                                        label="Client :"
                                    />
                                </Grid>
								<Grid item xs={4}>
									<Typography>
										Afficher les désactivés
										<Switch
											checked={this.state.showDisabled}
											onChange={this.showDisabled_changed}
											value={false}
										/>
									</Typography>
								</Grid>
								<Grid item xs={4}>
                                    <TextField
                                        id="input-with-icon-textfield"
                                        value={this.state.filter}
                                        onChange={this.filterChange_handler}
                                        label="Filtre"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Filter />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
							</Grid>
						</CardContent>
					</Card>
				</Grid>
                {projectsToShow.map((project) => (
					<Grid item xs={3}>
                        <Card>
							<CardContent>
								<Grid container spacing={2}>
									<Grid container item xs={9}>
										<Grid item xs={12}>
											<Typography variant="h3">
												{project.name}
											</Typography>
										</Grid>
                                        <Grid item xs={12}>
                                            <Typography>
                                                Manager : {project.user.displayname}
                                            </Typography>
                                        </Grid>
									</Grid>
									<Grid item xs={3}>
                                        {(this.props.user.adminPermission.timespents.users.view.others || this.props.user.projects.find(userProject => userProject.id === project.id)) &&
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                className={classes.button}
                                                onClick={this.projectsCurve_clicked(project.id)}
                                            >
                                                <ChartLine className={classes.leftIcon} />
                                            </Button>
                                        }
									</Grid>
								</Grid>
								<DatedLabelledProgressdBar
									min={0}
									max={project.daysCount*7}
									value={this.state.projectHours[project.id]}
									date_start={project.date_start}
									date_end={project.date_end}
									date_value={moment()}
								/>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
			</Grid>

		);
	}
}

ClientManagmentDashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ClientManagmentDashboard);
