import React from 'react';
import PropTypes from 'prop-types';

import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';


import moment from 'moment';
import {
	withStyles,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
	Typography
} from "@material-ui/core";


const hours_limit= {
	min: 0.5,
	max: 24,
	step: 0.5
};

const style_override = theme => ({
	paper:{
		overflow:'visible'
	},
	mainDialogContent:{
		overflow:'visible',
		paddingTop:"0!important",
		"&>*":{
			margin:"8% 0"
		}
	}
});

class GenericTimespentDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            projectsList: [],
		};
    }

    componentDidMount = () => {
        this.props.apiControllers.projects.getAll({
            auth_username:this.props.user.username,
            auth_token:this.props.user.token,
        }).then((response)=>{
                this.setState({projectsList: response.data});
        }).catch((error) => {
            if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
	}

	//======================= UI Callback ========================

    closeFromDialog_handler = () => {
        this.props.closeDialog();
    }

	dialogEnter_handler = () => {
        this.props.dialogEnter_handler();
	}

    //======================= Page Render ========================

    render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.closeFromDialog_handler}
				onEnter={this.dialogEnter_handler}
				aria-labelledby="form-dialog-title"
				classes={{
					paper:classes.paper
				}}
			>
				<DialogTitle id="form-dialog-title">{this.props.title}</DialogTitle>
				<form>
					<DialogContent className={classes.mainDialogContent}>
						<Typography variant="h3" >
							Date : {this.props.date !== undefined ? moment(this.props.date).format("DD/MM/YYYY") : undefined}
						</Typography>
                        <TextField
							fullWidth
							error={this.props.hours_errorMessage !== "" ? true : false}
							helperText={this.props.hours_errorMessage}
							color="secondary"
							id="hours-input"
							label="Nombre d'heures"
							type="number"
							InputProps={{
								inputProps:{
									min: hours_limit.min,
									max: hours_limit.max,
									step: hours_limit.step
								},
								endAdornment: <InputAdornment position="end">h</InputAdornment>
							}}
							value={this.props.selectedHours}
							onChange={this.props.hoursChange_handler}
						/>
                        <AutoCompleteHightlight
                            suggestions={this.state.projectsList}
                            selectedValue={this.props.selectedProject}
                            change_handler={this.props.projectChange_handler}
                            errorMessage={this.props.project_errorMessage}
							label="Projet :"
                        />
						{(this.props.selectedProject !== undefined && this.props.selectedProject.categories.length > 0) ?
							<AutoCompleteHightlight
								suggestions={this.props.selectedProject.categories}
								change_handler={this.props.categoryChange_handler}
								selectedValue={this.props.selectedCategory}
								errorMessage={this.props.category_errorMessage}
							/>
						: undefined}
						{this.props.selectedCategory !== undefined ?
							<AutoCompleteHightlight
								suggestions={
                                    this.props.selectedProject.tags.filter(proj_tag => (!proj_tag.disabled && this.props.selectedCategory.tags.find(cat_tag => cat_tag.id === proj_tag.id)))
                                }
								selectedValue={this.props.selectedTags}
								change_handler={this.props.tagsChange_handler}
								errorMessage=""
								label="Tags :"
								isMulti={true}
							/>
						:undefined}
					</DialogContent>
					<DialogActions>
						{this.props.actions}
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

GenericTimespentDialog.propTypes = {
    actions: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    closeDialog: PropTypes.func.isRequired,
	apiControllers: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	logger: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	updateTimespentDialog: PropTypes.bool,
    checkAuthentication:PropTypes.func.isRequired,
    dialogEnter_handler:PropTypes.func.isRequired,
    hoursChange_handler: PropTypes.func.isRequired,
    projectChange_handler: PropTypes.func,
    categoryChange_handler: PropTypes.func.isRequired,
    tagsChange_handler: PropTypes.func.isRequired,
	selectedHours:PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	selectedProject:PropTypes.object,
	selectedCategory:PropTypes.object,
	selectedTags:PropTypes.array,
	hours_errorMessage:PropTypes.string.isRequired,
	project_errorMessage:PropTypes.string.isRequired,
	category_errorMessage:PropTypes.string.isRequired,
};

export default withStyles(style_override,{withTheme: true})(GenericTimespentDialog);
