import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid,
    Card,
    CardHeader,
    CardContent,
    Button
} from '@material-ui/core';


import {
    CalendarEdit,
} from "mdi-material-ui"

import AdminControl from "components/AdminControl/AdminControl.jsx"

const styles = theme => ({
    cardContent:{
        textAlign: "center"
    },
    button: {
        margin: theme.spacing(),
    },
    leftIcon: {
        marginRight: theme.spacing(),
    }
});


class AdminPanel extends React.Component {

	//======================= UI Callback ========================

    event_clicked = () => {
        this.props.history.push('/events/edit');
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Panneau d'administration"}
                        />
                    </Card>
                </Grid>
                {this.props.user.adminPermission.users.createUpdate &&
                <AdminControl
                    userManager={this.props.userManager}
                    entityName={"users"}
                    logger={this.props.logger}
                    user={this.props.user}
                    apiController={this.props.apiControllers.users}
                    history={this.props.history}
                    checkAuthentication={this.props.checkAuthentication}
                />}
                {this.props.user.adminPermission.projects.createUpdate &&
                <AdminControl
                    userManager={this.props.userManager}
                    entityName={"projects"}
                    logger={this.props.logger}
                    user={this.props.user}
                    apiController={this.props.apiControllers.projects}
                    history={this.props.history}
                    checkAuthentication={this.props.checkAuthentication}
                />}
                {this.props.user.adminPermission.categories.createUpdate &&
                <AdminControl
                    userManager={this.props.userManager}
                    entityName={"categories"}
                    logger={this.props.logger}
                    user={this.props.user}
                    apiController={this.props.apiControllers.categories}
                    history={this.props.history}
                    checkAuthentication={this.props.checkAuthentication}
                />}
                {this.props.user.adminPermission.tags.createUpdate &&
                <AdminControl
                    userManager={this.props.userManager}
                    entityName={"tags"}
                    logger={this.props.logger}
                    user={this.props.user}
                    apiController={this.props.apiControllers.tags}
                    history={this.props.history}
                    checkAuthentication={this.props.checkAuthentication}
                />}
                {this.props.user.adminPermission.clients.createUpdate &&
                <AdminControl
                    userManager={this.props.userManager}
                    entityName={"clients"}
                    logger={this.props.logger}
                    user={this.props.user}
                    apiController={this.props.apiControllers.clients}
                    history={this.props.history}
                    checkAuthentication={this.props.checkAuthentication}
                />}
                {this.props.user.adminPermission.events.createUpdate &&
                    <Grid item xs={3}>
                        <Card className={classes.cardContent}>

                            <CardHeader
                                title="Événements"
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            onClick={this.event_clicked}
                                        >
                                            <CalendarEdit className={classes.leftIcon} />
                                            Editer
                                        </Button>
                                    </Grid>
                                </Grid>

                            </CardContent>
                        </Card>
                    </Grid>
                }
            </Grid>
        );
    }
}

AdminPanel.propTypes = {
    user: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(AdminPanel);
