import React from 'react';
import PropTypes from 'prop-types';
import CryptoJS from 'crypto-js';
import {
	withStyles,
	TextField,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	DialogTitle
} from "@material-ui/core";

import {
	AccountCircle,
	Lock,
} from "mdi-material-ui"

const style_override = theme => ({
	paper:{
		minWidth: '50vw',
		overflowY:'visible'
	},
	dialogContent:{
		overflow: 'visible'
	}
});

class AuthenticationDialog extends React.Component {

	constructor(props) {
		super(props);
		let user = JSON.parse(localStorage.getItem('user'));
		if(user !== null && user.username !== undefined){
			this.setState({username:user.username});
		}
		this.state = {
			username: (user!==null && user.username !== undefined ? user.username : ""),
			password: ""
		};
	}

	//======================= UI Callback ========================

	onInputEnterPressed = (event) => {
		if(event.key === "Enter"){
			this.connection_handler();
		}
	}

	close_handler = () => {
		this.props.closeHandler();
	}

	usernameTextChange_handler = (event) => {
		this.setState({
			username: event.target.value,
		});
	};

	passwordTextChange_handler = (event) => {
		this.setState({
			password: event.target.value,
		});
	};

	connection_handler = (event) => {

		let hash = CryptoJS.SHA3(this.state.password).toString();
		this.props.userManager.login(this.state.username, hash)
		.then((user) => {
			this.props.loggedin_callback(user.data);
		}).catch((error) =>{
			if(error.status === 401){
				this.props.notifier.warning("Mauvais nom d'utilisateur / mot de passe");
			}else{
				this.props.notifier.error("Erreur 400");
			}
		});
	};

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.close_handler}
				aria-labelledby="form-dialog-title"
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				classes={{
					paper:classes.paper
				}}
			>
				<DialogTitle id="form-dialog-title">Se connecter</DialogTitle>
				<form>
					<DialogContent className={classes.dialogContent}>
						<div>
							<Grid container spacing={2} alignItems="flex-end">
								<Grid item xs={1}>
									<AccountCircle />
								</Grid>
								<Grid item xs={11}>
									<TextField
										required
										fullWidth
										autoFocus
										id="username-input"
										label="Nom d'utilisateur"
										className={classes.textField}
										value={this.state.username}
										onChange={this.usernameTextChange_handler}
										margin="normal"
										onKeyPress={this.onInputEnterPressed}
										autoComplete="username"
									/>
								</Grid>
							</Grid>
						</div>
						<div>
							<Grid container spacing={2} alignItems="flex-end">
								<Grid item xs={1}>
									<Lock />
								</Grid>
								<Grid item xs={11}>
								<TextField
									required
									fullWidth
									color="secondary"
									id="password-input"
									label="Mot de passe"
									className={classes.textField}
									type="password"
									onChange={this.passwordTextChange_handler}
									margin="normal"
									onKeyPress={this.onInputEnterPressed}
								/>
								</Grid>
							</Grid>

						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={this.connection_handler}
							color="secondary"
							variant="contained">
							Connexion
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

AuthenticationDialog.propTypes = {
    loggedin_callback: PropTypes.func.isRequired,
    closeHandler: PropTypes.func.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
};

export default withStyles(style_override,{withTheme: true})(AuthenticationDialog);
