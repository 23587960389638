import React from "react";
import ReactToPrint from 'react-to-print';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import PagedFilterableTable from 'components/PagedFilterableTable/PagedFilterableTable.jsx';

import PrintableTable from 'components/PrintableTable/PrintableTable.jsx';

import moment from 'moment';
import randomColor from 'randomcolor';

import {Line} from 'react-chartjs-2';

import {dateFormat} from 'variables/general.jsx';

import TimespentsFetchers from 'utils/timespentsFetchers.js';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
	Card,
	CardContent,
	Button,
	Typography,
} from '@material-ui/core';

import {
	Printer,
	ChevronLeft,
	ChevronRight,
	CalendarToday,
} from 'mdi-material-ui';

const hoursPerDay = 7;
const hoursPerMonth = 134;
const chartSkeleton = {
	fill: false,
	lineTension: 0.1,
	borderCapStyle: 'butt',
	borderJoinStyle: 'miter',
	pointRadius: 3,
	pointBorderWidth: 1,
	pointHoverRadius: 5,
	pointHoverBorderWidth: 2,
	data: [...Array(12).keys()].map(el => ({
		y:0,
		accumulator: 0,
		hours: 0,
		accumulator_contract: 0,
		hours_contract: 0
	}))
}

const zeroData = [...Array(12).keys()].map(el => ({
	y:0,
	accumulator: 0,
	hours: 0,
	accumulator_contract: 0,
	hours_contract: 0
}));

const randomColorConfig={
	luminosity: 'bright',
	format: 'hex'
}

const styles = theme => ({
	addTimespentCard:{
		overflow:"visible",
		"&>div>*":{
			margin:"2% 0"
		}
	},
	chartContainer:{
		paddingBottom:"0!important",
		width:'100%',
		height:'90%'
	},
	printVersion:{
		display:'none'
	},
	chartToolbar:{
		display:'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center'
	},
	chartTitle:{
		flexGrow: 1,
		padding: '0 10px',
		textAlign: 'center',
	},
	navButtonGroup:{
		display:'inline-block',
		"&> button:last-child:not(:first-child)":{
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
		"&> button:first-child:not(:last-child)": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
		"&> button:not(:first-child):not(:last-child)": {
			borderRadius: 0
		}
	},
	navButton:{
		backgroundColor:'white'

	}
});

const timespentsFetchers = new TimespentsFetchers();

class ReportingUsersChart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			usersList:[],
			usersSelected:[],
			timespents: [],
			calendarRange:{
				from:new Date(moment().startOf('year')),
				to:new Date(moment().endOf('year'))
			},
			printChart:false,
			chartWidth:null,
		};
		this.alreadyFetchedRanges = [];
		this.hoursPerMonth = [];
		let hoursAccumulator = 0;
		for(let month = 0; month < 12; month++){
			hoursAccumulator += hoursPerMonth;
			this.hoursPerMonth.push(hoursAccumulator);
		}
		this.optimalChart = {
			...chartSkeleton,
			label: 'Optimale',
			backgroundColor: '#666666',
			borderColor: '#333333',
			pointHoverBorderColor: '#333333',
			pointHoverBackgroundColor: '#666666',
			data: [...Array(12).keys()].map(el => ({
				y:(100/12)*(el+1),
			}))
		};

		this.viewRight = this.props.user.adminPermission.timespents.users.view.others && this.props.user.adminPermission.timespents.absences.view.others;
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	componentDidMount = () => {
		this.getUsers().then(() => {
			this.getCurrentYearTimespents();
		});
	}

    //======================= Tool Functions ========================

	getUsers = () => {
		return this.props.apiControllers.users.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({usersList: response.data});
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getCurrentYearTimespents = () => {
		let start_date = moment(this.state.calendarRange.from).format(dateFormat);
		let end_date = moment(this.state.calendarRange.to).format(dateFormat);
		this.props.loadingDialog.open();
		return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, this.alreadyFetchedRanges, this.props.apiControllers.timespents, this.props.user, undefined, undefined, true, this.props.logger).then((timespents) => {
			let timespentsBase = this.state.timespents;
			let timespentsToAdd = timespents;
			if(timespentsToAdd !== undefined){
				for(let timespent of timespents){
					timespentsBase.push(timespent);
				}
			}
			return this.promisedSetState({timespents: timespentsBase}).then(() => {
				this.buildDayUserSumsArray(this.state.timespents.filter(timespent => (
					moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]')
				)));
				this.props.loadingDialog.close();
			});
		});
	}

	buildDayUserSumsArray = (timespents) => {
		this.resetUsersSum().then(() => {
			let usersInter = this.state.usersList;
			usersInter.forEach(user => {
				let color = randomColor(randomColorConfig);
				user.chart = {
					...chartSkeleton,
					label: user.displayname,
					backgroundColor: color,
					borderColor: color,
					pointHoverBackgroundColor: color,
					pointHoverBorderColor: color,
				}
				user.chart.data = JSON.parse(JSON.stringify(zeroData));
			});
			timespents.forEach((current) => {
				let userToChange = usersInter.find(user => user.id === current.user.id);
				userToChange.chart.label=current.user.displayname;
				if(current.project.id === 1){
					userToChange.absenceDaysSum+=(current.hours / hoursPerDay);
				}
				if(current.project.id !== 1 || ![1,2].includes(current.category.id)){
					userToChange.hoursSum+=current.hours;
					userToChange.chart.data[moment(current.date).month()].hours+=current.hours;
				}
			});
			usersInter.forEach(user => {
				let userHoursPerMonth = hoursPerMonth / (100/user.hoursPercentage);
				let accumulator = 0;
				let percentageAccumulator = 0;
				for(let month = 0; month < 12; month++){
					accumulator += user.chart.data[month].hours;
					user.chart.data[month].accumulator = accumulator;
					percentageAccumulator += user.chart.data[month].hours * (100/(userHoursPerMonth*12));
					user.chart.data[month].y = percentageAccumulator;
					user.chart.data[month].hours_should = userHoursPerMonth;
					user.chart.data[month].accumulator_should = (userHoursPerMonth*(month+1));
				}
			});
			this.setState({usersList: usersInter},this.populateEventArray);
		});

	}

	resetUsersSum = () => {
		let usersInter = this.state.usersList;
		usersInter.forEach((el) => {
			el.hoursSum = 0;
			el.daysSum = 0;
			el.absenceDaysSum = 0;
			el.chart={};
		});
		return this.promisedSetState({usersList : usersInter});
	}

    //======================= UI Callback ========================

	navigatePrevious = () => {
		let previousYearStart = new Date(moment(this.state.calendarRange.from).subtract(1,'year').startOf('year'));
		let previousYearEnd = new Date(moment(this.state.calendarRange.from).subtract(1,'year').endOf('year'));
		this.setState({calendarRange:{from:previousYearStart,to:previousYearEnd}}, this.dateChanged_handler);
	}

	navigateNext = () => {
		let previousYearStart = new Date(moment(this.state.calendarRange.from).add(1,'year').startOf('year'));
		let previousYearEnd = new Date(moment(this.state.calendarRange.from).add(1,'year').endOf('year'));
		this.setState({calendarRange:{from:previousYearStart,to:previousYearEnd}}, this.dateChanged_handler);
	}

	navigateToday = () => {
		let previousYearStart = new Date(moment().startOf('year'));
		let previousYearEnd = new Date(moment().endOf('year'));
		this.setState({calendarRange:{from:previousYearStart,to:previousYearEnd}}, this.dateChanged_handler);
	}

	dateChanged_handler = () => {
		this.getCurrentYearTimespents();
	}


	userSelected_handler = (selectedIds) => {
		let usersSelected = [];
		selectedIds.forEach(id => {
			usersSelected.push(this.state.usersList.find(user => user.id === id));
		});
		this.setState({usersSelected:usersSelected},this.populateEventArray);
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<Grid container spacing={2} className='dashboardContainer'>
				<div className={classes.printVersion}>
					<PrintableTable
						data={this.state.usersList}
						dataSelected_handler={this.userSelected_handler}
						orderBy="displayname"
						order="asc"
						properties={[
							{name:"displayname",label:"Nom"},
							{name:"hoursSum",label:"Heures", suffix:' h'},
							{name:"absenceDaysSum",label:"Absences", suffix:' j'},
						]}
						ref={el => (this.componentToPrintRef = el)}
						title={moment(this.state.calendarRange.from).format("YYYY")}
					/>
				</div>
				<Grid container item xs={4} spacing={2}>
					<Grid item xs={12}>
						<Card className={classes.addTimespentCard}>
							<CardContent>
								<Grid container item spacing={2}>
									<Grid item xs={10}>
										<Typography variant="h2" gutterBottom>
											Reporting Utilisateur
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<ReactToPrint
											trigger={() => (
												<Button
													variant="contained"
													color="secondary"
												>
													<Printer/>
												</Button>
											)}
											content={() => this.componentToPrintRef}
										/>
									</Grid>
								</Grid>
								<Typography variant="h3">
									{moment(this.state.calendarRange.from).format("YYYY")}
								</Typography>
								<PagedFilterableTable
									data={this.state.usersList}
									dataSelected_handler={this.userSelected_handler}
									properties={[
										{name:"displayname",label:"Nom"},
										{name:"hoursSum",label:"Heures", suffix:' h'},
										{name:"absenceDaysSum",label:"Absences", suffix:' j'},
									]}
									selectable={true}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={8} className={classes.chartContainer} ref={el => (this.chartToPrintRef = el)}>
					<div className={classes.chartToolbar}>
						<div className={classes.navButtonGroup}>
							<Button
								className={classes.navButton}
								variant="outlined"
								onClick={this.navigatePrevious}
							>
								<ChevronLeft/>
							</Button>
							<Button
								className={classes.navButton}
								variant="outlined"
								onClick={this.navigateToday}
							>
								<CalendarToday/>
							</Button>
							<Button
								className={classes.navButton}
								variant="outlined"
								onClick={this.navigateNext}
							>
								<ChevronRight/>
							</Button>
						</div>
						<Typography variant='h2' className={classes.chartTitle}>
							{moment(this.state.calendarRange.from).format("YYYY")}
						</Typography>
						<ReactToPrint
							trigger={() => (
								<Button
									variant="contained"
									color="secondary"
								>
									<Printer/>
								</Button>
							)}
							content={() => this.chartToPrintRef}
							onBeforePrint={()=>{
								this.setState({chartWidth:window.innerWidth,printChart:true});
							}}
							onAfterPrint={()=>{
								this.setState({printChart:true});
							}}
						/>
					</div>
					<Grid item xs={12} className={classes.chartContainer}>
						<Line
							style={this.state.printChart&&{width:this.state.chartWidth}}
							options={{
								responsive: true,
								maintainAspectRatio: true,
								tooltips: {
									callbacks: {
										label: function(tooltipItem, data) {
											return(
												parseInt(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y)+' %'+
												(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].accumulator !== undefined ? (' - '+
												data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].accumulator+
												" h ("
												+data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].accumulator_should+
												") - "+
												data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].hours+
												" h ("+
												data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].hours_should+
												")") : ''));
										}
									}
								},
								scales: {
									yAxes: [{
										scaleLabel: {
											display:true,
											labelString: "Pourcentage dans l'année (%)"
										}
									}],
									xAxes: [{
										scaleLabel: {
											display:true,
											labelString: "Mois de l'année"
										}
									}]
								}
							}}
							data={{
								labels: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'],
								datasets: [
									this.optimalChart,
									...this.state.usersSelected.map(user => (user.chart))
								]
							}}
						/>

					</Grid>
				</Grid>

			</Grid>

		);
	}
}

ReportingUsersChart.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ReportingUsersChart);
