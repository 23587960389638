import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import PrintableCollapsibleTableCrossedData from './PrintableCollapsibleTableCrossedData.jsx';
import ReactToPrint from 'react-to-print';

import { entityDisplayNames,
    entityAttributeName
} from "variables/general.jsx";

import {
    Typography,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from "@material-ui/core";

import {
    CheckboxBlank,
    ArrowDownDropCircleOutline,
    ArrowUpDropCircleOutline,
    Printer
} from "mdi-material-ui"


const styles = theme => ({
    tableContainer:{
		paddingBottom:"0!important",
		width:'100%',
		height:'90%'
    },
    collapsibleRow:{
        paddingBottom:"0!important",
        height:'auto',
    },
    unCollapsedRow:{
        '& tr:not(:last-of-type)':{
            '& td':{
                border:'none',
            }
        },
    },
    collapsibleHeadRow:{
        cursor:'pointer',
        '& td':{
            fontSize:'1em'
        }
    },
    totalRow:{
        '& td':{
            fontSize:'1.2em',
            fontWeight:'bold'
        }
    },
    tableHead:{
        '& th':{
            fontSize:'1.2em',
            fontWeight:'bold'
        }
    },
	printVersion:{
		display:'none'
	}
});


class CollapsibleTableCrossedData extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            unCollapsedEntities:[]
        };
    }

    //======================= UI Callback ========================

    collapsibleClicked = (entityId) => {
        let unCollapsedEntities = this.state.unCollapsedEntities;
        let index = unCollapsedEntities.findIndex(element => element === entityId);
        if(index !== -1){
            unCollapsedEntities.splice(index,1);
        }else{
            unCollapsedEntities.push(entityId);
        }
        this.setState({unCollapsedEntities:unCollapsedEntities});
    }

    //======================= Page Render ========================

    render(){

		const { classes } = this.props;
        return(
            <Grid container item xs={12} spacing={2}>
                <div className={classes.printVersion}>
					<PrintableCollapsibleTableCrossedData
						tableTitle={this.props.tableTitle}
                        rowEntityName={this.props.rowEntityName}
                        colEntityName={this.props.colEntityName}
                        collapsedEntityName={this.props.collapsedEntityName}
                        rowsEntities={this.props.rowsEntities}
                        colsEntities={this.props.colsEntities}
                        resultTotal={this.props.resultTotal}
                        ref={el => (this.componentToPrintRef = el)}
					/>
				</div>
                <Grid container item xs={12}>
                    <Grid item xs={5}>
                        <Typography variant='h3'>
                            {this.props.tableTitle}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={2}>
                        <ReactToPrint
                            trigger={() => (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                >
                                    <Printer/>
                                </Button>
                            )}
                            content={() => this.componentToPrintRef}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHead}>
                                <TableCell
                                    padding='checkbox'
                                ></TableCell>
                                <TableCell>{entityDisplayNames[this.props.rowEntityName]}</TableCell>
                                {this.props.colsEntities.map(entity => (
                                    <TableCell key={'head-'+entity.id}>{entity[entityAttributeName[this.props.colEntityName]]}</TableCell>
                                ))}
                                <TableCell>Total</TableCell>
                            </TableRow>
                        </TableHead>
                        {this.props.rowsEntities.map(rowEntity => (
                            <TableBody
                                key={'row-'+rowEntity.id}
                                className={this.state.unCollapsedEntities.includes(rowEntity.id)?classes.unCollapsedRow:''}
                            >
                                <TableRow
                                    hover
                                    className={classNames(classes.collapsibleRow,classes.collapsibleHeadRow)}
                                    onClick={() => {this.collapsibleClicked(rowEntity.id)}}
                                >
                                    <TableCell
                                        padding='checkbox'
                                    >
                                        {this.state.unCollapsedEntities.includes(rowEntity.id)?<ArrowUpDropCircleOutline/>:<ArrowDownDropCircleOutline/>}
                                    </TableCell>
                                    <TableCell
                                    >{rowEntity[entityAttributeName[this.props.rowEntityName]]}</TableCell>
                                    {this.props.colsEntities.map(colEntity => (
                                        <TableCell
                                            key={'rowCol-'+rowEntity.id+'-'+colEntity.id}
                                        >{rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id) !== undefined ? rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id).hoursSum : 0} h</TableCell>
                                    ))}
                                    <TableCell
                                    >{rowEntity.hoursSum} h</TableCell>
                                </TableRow>
                                {this.state.unCollapsedEntities.includes(rowEntity.id) && rowEntity[this.props.collapsedEntityName].map(collapsedEntity => (
                                    <TableRow
                                        key={'collapsed-'+collapsedEntity.id}
                                        hover
                                        className={classes.collapsibleRow}
                                    >
                                        <TableCell
                                            padding='checkbox'
                                        >
                                            {collapsedEntity.color !== undefined ?
                                                <CheckboxBlank
                                                    style={{
                                                        color:collapsedEntity.color,
                                                    }}
                                                />
                                            :null}
                                        </TableCell>
                                        <TableCell
                                        >
                                            {collapsedEntity[entityAttributeName[this.props.collapsedEntityName]]}
                                        </TableCell>
                                        {this.props.colsEntities.map(colEntity => (
                                            <TableCell
                                                key={'collapsedCol-'+collapsedEntity.id+'-'+colEntity.id}
                                            >{
                                                rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id) !== undefined ?
                                                (rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id)[this.props.collapsedEntityName].find(collapsedOfCol => collapsedOfCol.id === collapsedEntity.id) !== undefined ? rowEntity[this.props.colEntityName].find(colOfEntity => colEntity.id === colOfEntity.id)[this.props.collapsedEntityName].find(collapsedOfCol => collapsedOfCol.id === collapsedEntity.id).hoursSum : 0) : 0
                                            } h</TableCell>
                                        ))}
                                        <TableCell
                                        >{collapsedEntity.hoursSum} h</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ))}
                        <TableBody className={classes.totalRow}>
                            <TableRow>
                                <TableCell
                                    padding='checkbox'
                                ></TableCell>
                                <TableCell>Total</TableCell>
                                {this.props.resultTotal[this.props.colEntityName] && this.props.colsEntities.map(colEntity => (
                                    <TableCell key={'total-'+colEntity.id}>{this.props.resultTotal[this.props.colEntityName].find(totalCol => colEntity.id === totalCol.id) !== undefined ? this.props.resultTotal[this.props.colEntityName].find(totalCol => colEntity.id === totalCol.id).hoursSum : 0} h</TableCell>
                                ))}
                                <TableCell>{this.props.resultTotal.hoursSum} h</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        )
    }
}

CollapsibleTableCrossedData.propTypes = {
    tableTitle:PropTypes.string.isRequired,
    rowEntityName:PropTypes.string.isRequired,
    colEntityName:PropTypes.string.isRequired,
    collapsedEntityName:PropTypes.string.isRequired,
    rowsEntities:PropTypes.array.isRequired,
    colsEntities:PropTypes.array.isRequired,
    resultTotal:PropTypes.object.isRequired,

};
export default withStyles(styles,{withTheme: true})(CollapsibleTableCrossedData);
