import React from 'react';
import PropTypes from 'prop-types';
import withStyles from "@material-ui/core/styles/withStyles";

import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';
import CollapsibleTableCrossedData from 'components/CollapsibleTableCrossedData/CollapsibleTableCrossedData.jsx';

import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import './ReportingProjectsByUsers.css';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';

import TimespentsFetchers from 'utils/timespentsFetchers.js';
import {dateFormat} from 'variables/general.jsx';

import { entityDisplayNames,
    entityAttributeId,
    entityAttributeName,
    entityEmbeddedName } from "variables/general.jsx";

// Make sure moment.js has the required locale data
import 'moment/locale/fr';

import {
    Button,
    Card,
    CardHeader,
    CardContent,
    Grid,
    Typography,
} from "@material-ui/core";

import {
    CalendarToday,
} from "mdi-material-ui"


const styles = theme => ({
    reportingCard:{
        width:'100%',
        overflow:'visible'
    },
    formCards_container:{
		display:'block'
	},
});

const timespentsFetchers = new TimespentsFetchers();

/**
 * Make a table with 3 entities :
 * displayed Entities by searched entities (ex : display all users for given projects)
 * For each displayed entities, collapsed entities (ex : display all categories for each users)
 */
class ReportingProjectsByUsers extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            calendarRange:{
				from:new Date(moment().startOf('month')),
				to:new Date(moment().endOf('month'))
            },
            selectedSearchedEntity:[],
            resultDisplayedEntities:[],
            resultTotal:{},
            searchEntityList:[],
            clientList:[],
            selectedClients:[],
            timespents: [],
            unCollapsedEntities:[]
        };
        this.alreadyFetchedRanges = [];
        this.searchedEntityName = this.props.match.params.searchedEntityName;
        this.searchedId = parseInt(this.props.match.params.searchedId);
        this.displayedEntityName = this.props.match.params.displayedEntityName;
        this.collapsedEntityName = this.props.match.params.collapsedEntityName;

        this.viewRight = (this.searchedId && (this.searchedEntityName==='users' && this.searchedId === this.props.user.id)) || (this.searchedEntityName==='projects' && this.props.user.projects.length > 0) ||this.props.user.adminPermission.timespents.users.view.others;
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }

        this.completeViewRight = this.props.user.adminPermission.timespents.users.view.others;
    }

    componentDidMount = () => {
        this.getSearchedEntityList(this.searchedId)
            .then(()=>{
                if(this.searchedEntityName === 'projects'){
                    let clients = [];
                    this.state.searchEntityList.map(project => {
                        if(project.client && !((clients.length > 0) && clients.find(client => client.id === project.client.id))){
                            clients.push(project.client);
                        }
                    })
                    this.setState({
                        clientList:clients
                    });
                }
                this.refreshInfos()
            });
    }

    promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

    //======================= Tool Functions ========================

	getSearchedEntityList = (searchedId) => {
        if(searchedId){
            return this.props.apiControllers[this.searchedEntityName].getById(searchedId,{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token,
                disabled:true,
            }).then((response)=>{
                this.setState({selectedSearchedEntity: [response.data]});
                return true;
            }).catch((error) => {
                if(error.status === 403){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });
        }else if(this.completeViewRight){
            return this.props.apiControllers[this.searchedEntityName].getAll({
                auth_username:this.props.user.username,
                auth_token:this.props.user.token,
                disabled:true,
            }).then((response)=>{
                this.setState({searchEntityList: response.data});
                return true;
            }).catch((error) => {
                if(error.status === 403){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });
        }else if(this.searchedEntityName==='projects'){
            return this.promisedSetState({searchEntityList:this.props.user.projects});
        }
    }

    getCurrentPeriodTimespents = () => {
		let start_date = moment(this.state.calendarRange.from).format(dateFormat);
        let end_date = moment(this.state.calendarRange.to).format(dateFormat);
        let entityToSearch = undefined;
        let idToSearch = undefined;
        if(this.searchedId && this.searchedEntityName === 'users'){
            entityToSearch = 'users';
            idToSearch = this.searchedId;
        }
		return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, this.alreadyFetchedRanges, this.props.apiControllers.timespents, this.props.user, idToSearch, entityToSearch, true, this.props.logger).then((timespents)=> {
			let timespentsBase = this.state.timespents;
			let timespentsToAdd = timespents;
			if(timespentsToAdd !== undefined){
				for(let timespent of timespentsToAdd){
                    timespentsBase.push(timespent);
				}
			}
			return this.promisedSetState({timespents: timespentsBase});

		}).catch((error) => {
            this.props.loadingDialog.close();
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
    }

    refreshInfos = () => {
        this.getCurrentPeriodTimespents().then(() => {
            let resultDisplayedEntities = [];
            let resultTotal = {
                hoursSum:0,
            };
            resultTotal[this.searchedEntityName]=[];

            let searchedEntitiesPeriodTimespents = this.state.timespents.filter(timespent =>
                moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]') &&
                this.state.selectedSearchedEntity.find(searchedSearchedEntity => (!timespent[entityEmbeddedName[this.displayedEntityName]].system && searchedSearchedEntity.id === timespent[entityAttributeId[this.searchedEntityName]])));
            this.props.logger.debug(searchedEntitiesPeriodTimespents);

            for(let timespent of searchedEntitiesPeriodTimespents){

                let displayedEntity = resultDisplayedEntities.find(searchedDisplayedEntity => searchedDisplayedEntity.id === timespent[entityAttributeId[this.displayedEntityName]]);

                //----------Compute hours sum for the displayed entity-------
                // Check if there is already an entry for this displayed entity
                if(!displayedEntity){
                    displayedEntity = {
                        ...timespent[entityEmbeddedName[this.displayedEntityName]],
                        hoursSum: 0,
                    };
                    displayedEntity[this.searchedEntityName] = [];
                    displayedEntity[this.collapsedEntityName] = [];
                    resultDisplayedEntities.push(displayedEntity);
                }
                displayedEntity.hoursSum+=timespent.hours;

                //----------Compute hours sum for the searched entity of the displayed entity-------
                let searchedEntity = displayedEntity[this.searchedEntityName].find(searchedSearchedEntity => searchedSearchedEntity.id === timespent[entityAttributeId[this.searchedEntityName]]);
                // Check if there is already an entry for this searched entity in the current displayed entity
                if(!searchedEntity){
                    searchedEntity = {
                        ...(timespent[entityEmbeddedName[this.searchedEntityName]] || this.props.user),
                        hoursSum: 0,
                    };
                    searchedEntity[this.collapsedEntityName] = [];
                    displayedEntity[this.searchedEntityName].push(searchedEntity);
                }
                searchedEntity.hoursSum+=timespent.hours;

                //----------Compute hours sum for the collapsed entity of this searched entity-------
                let collapsedOfSearchedEntity = searchedEntity[this.collapsedEntityName].find(searchedCollapsedEntity => searchedCollapsedEntity.id === timespent[entityAttributeId[this.collapsedEntityName]]);
                if(!collapsedOfSearchedEntity){
                    collapsedOfSearchedEntity = {
                        ...timespent[entityEmbeddedName[this.collapsedEntityName]],
                        hoursSum: 0,
                    };
                    searchedEntity[this.collapsedEntityName].push(collapsedOfSearchedEntity);
                }
                collapsedOfSearchedEntity.hoursSum+=timespent.hours;

                //----------Find collapsed for the displayed-------
                let collapsedOfDisplayedEntity = displayedEntity[this.collapsedEntityName].find(searchedCollapsedEntity => searchedCollapsedEntity.id === timespent[entityAttributeId[this.collapsedEntityName]])
                if(!collapsedOfDisplayedEntity){
                    collapsedOfDisplayedEntity = {
                        ...timespent[entityEmbeddedName[this.collapsedEntityName]],
                        hoursSum:0
                    };
                    displayedEntity[this.collapsedEntityName].push(collapsedOfDisplayedEntity);
                }
                collapsedOfDisplayedEntity.hoursSum+=timespent.hours;

                //----------Compute total hours sum-------
                resultTotal.hoursSum+=timespent.hours;

                //----------Compute total hours sum for the searched Entity-------
                let totalSearchedEntity = resultTotal[this.searchedEntityName].find(searchedSearchedEntity => searchedSearchedEntity.id === timespent[entityAttributeId[this.searchedEntityName]])
                // Check if there is already an entry for this searched entity in the total result
                if(!totalSearchedEntity){
                    totalSearchedEntity = {
                        ...(timespent[entityEmbeddedName[this.searchedEntityName]] || this.props.user),
                        hoursSum: 0,
                    };
                    resultTotal[this.searchedEntityName].push(totalSearchedEntity);
                }
                totalSearchedEntity.hoursSum+=timespent.hours;
            }

            this.setState({resultDisplayedEntities:resultDisplayedEntities,resultTotal:resultTotal});
        });
    }

    //======================= UI Callback ========================

    dayChange_handler = (day) => {
        const range = DateUtils.addDayToRange(day, this.state.calendarRange);
        this.setState({calendarRange:range},this.refreshInfos);
    }

    searchedEntityChange_handler = (searchedEntities) => {
        this.setState({
            selectedSearchedEntity: (searchedEntities || [])
        },this.refreshInfos);
    }

    clientChange_handler = (clients) => {
        this.setState({
            selectedClients : (clients || [])
        });
    }

    selectAllClients_handler = (clients) => {
        this.clientChange_handler(this.state.clientList);
    }

    selectAllTags_handler = () => {}

    //======================= Page Render ========================

    render(){

		const { classes } = this.props;
        return(
            <Grid container spacing={2}>
                <Grid container item xs={3} spacing={2} className={classes.formCards_container}>
                    <Grid container item xs={12}>
                        <Card className={classes.reportingCard}>
                            <CardHeader
                                title={
                                    <Typography variant='h2'>
                                        Période
                                    </Typography>
                                }
                            />
                            <CardContent>
                                <DayPicker
                                    showWeekNumbers
                                    onDayClick={this.dayChange_handler}
                                    selectedDays={this.state.calendarRange}
                                    localeUtils={MomentLocaleUtils}
                                    locale={'fr'}
                                    modifiers={{
                                        start:this.state.calendarRange.from,end:this.state.calendarRange.to}}
                                    todayButton={
                                        <Button component="span" className={classes.button}>
                                            <CalendarToday className={classes.leftIcon}/>
                                            Aujourd'hui
                                        </Button>
                                    }
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    {this.state.searchEntityList.length > 0 ?
                        <Grid container item xs={12}>
                            <Card className={classes.reportingCard}>
                                <CardHeader
                                    title={
                                        <Typography variant='h2'>
                                            {entityDisplayNames[this.searchedEntityName]} :
                                        </Typography>
                                    }
                                />
                                <CardContent>
                                    {this.searchedEntityName === 'projects' &&
                                        <AutoCompleteHightlight
                                            suggestions={this.state.clientList}
                                            change_handler={this.clientChange_handler}
                                            selectedValue={this.state.selectedClients}
                                            errorMessage=""
                                            disabled={false}
                                            attributeToDisplay={'name'}
                                            label="Client :"
                                            isMulti={true}
                                            selectAll={true}
                                            selectAll_handler={this.selectAllClients_handler}
                                        />
                                    }
                                    <AutoCompleteHightlight
                                        suggestions={this.searchedEntityName === 'projects' ? this.state.searchEntityList.filter(project => (project.client && this.state.selectedClients.find(client  => client.id === project.client.id))) : this.state.searchEntityList}
                                        change_handler={this.searchedEntityChange_handler}
                                        selectedValue={this.state.selectedSearchedEntity}
                                        errorMessage=""
                                        disabled={false}
                                        attributeToDisplay={entityAttributeName[this.searchedEntityName]}
                                        label="Recherche :"
                                        isMulti={true}
                                        selectAll={true}
                                        selectAll_handler={this.selectAllTags_handler}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                    : null }
                </Grid>
                <Grid container item xs={9} spacing={2}>
                    <CollapsibleTableCrossedData
                        tableTitle={"Du "+moment(this.state.calendarRange.from).format('DD/MM/YYYY')+" au "+moment(this.state.calendarRange.to).format('DD/MM/YYYY')}
                        rowEntityName={this.displayedEntityName}
                        colEntityName={this.searchedEntityName}
                        collapsedEntityName={this.collapsedEntityName}
                        rowsEntities={this.state.resultDisplayedEntities}
                        colsEntities={this.state.selectedSearchedEntity}
                        resultTotal={this.state.resultTotal}
                        logger={this.props.logger}
                    />
                </Grid>
            </Grid>
        )
    }
}

ReportingProjectsByUsers.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(ReportingProjectsByUsers);
