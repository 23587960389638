import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
    LinearProgress
} from "@material-ui/core";

import classNames from 'classnames';


import moment from 'moment';
// Make sure moment.js has the required locale data
import 'moment/locale/fr';

import{
	MenuUp,
	MenuDown
} from 'mdi-material-ui';

const styles = theme => ({
	outerContainrer:{
		width: '100%',
		height: '105px',
		position:'relative',
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '20px',
		marginTop: '20px'
	},
    innerContainer:{
		width: '80%',
		height: '100%',
		position:'absolute',
		top:'0px',
		bottom:'0px'
	},
	overflowBar:{
		height:'100%',
		position:'absolute',
		left:'0px',
		top:'0px',
		width:'100%',
		backgroundColor:"#FF0000",
		// backgroundImage: 'linear-gradient(45deg,#dc3545 25%, #f10000 25%, #f10000 50%,#dc3545 50%,#dc3545 75%, #f10000 75%, #f10000)',
		backgroundSize: '1rem 1rem'
	},
	barBody:{
		height:'100%',
		position:'absolute',
		left:'0px',
		top:'0px'
	},
	rangeLabels:{
		height: '10px',
		position:'relative',
	},
	genericRangeLabels:{
		position:'absolute',
		fontSize:'0.8em',
		color:'#909090',
		fontWeight:'bold'
	},
	dateRangeLabelMin:{
		left:'0px',
		bottom:'10px',
		transform:'translateX(-50%)',
	},
	dateRangeLabelMax:{
		right:'0px',
		transform:'translateX(+50%)',
	},
	dateRangeLabelValue:{
		position:'absolute',
		bottom:'-20px',
		transform:'translateX(-50%)'
	},
	dateRange:{
		height: '25px',
		border: '1px solid #C1C1C1',
		borderTop: '0px',
		position:'relative',
		boxSizing:'border-box',
		marginBottom:'2px'
	},
	dateValueIndicator:{
		position:'absolute',
		bottom:'-9px',
		transform:'translateX(-50%)'
	},
    dateBarContainer:{
		width: '100%',
		height: '10px',
		background: '#EEEEEE',
		position:'relative',
		marginBottom: '5px'
	},
	hoursBarContainer:{
		width: '100%',
		height: '10px',
		background: '#EEEEEE',
		position:'relative',
	},
	hoursRange:{
		height: '25px',
		border: '1px solid #C1C1C1',
		borderBottom: '0px',
		position:'relative',
		boxSizing:'border-box',
		marginTop:'2px'
	},
	hoursValueIndicator:{
		position:'absolute',
		top:'-9px',
		transform:'translateX(-50%)'
	},
	hoursRangeLabelValue:{
		position:'absolute',
		top:'-20px',
		transform:'translateX(-50%)'
	},
	hoursRangeLabelMin:{
		left:'0px',
		top:'10px',
		transform:'translateX(-50%)',
	},
	hoursRangeLabelMax:{
		right:'0px',
		transform:'translateX(+50%)',
	},

});

class DatedLabelledProgressBar extends React.Component {

    constructor(props) {
		super(props);
	}

	//======================= Tool Functions ========================

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		let hoursLimit = this.props.max;
		let hoursValue = this.props.value;
		let hoursMin = this.props.min;
		let hoursMax = Math.max(hoursLimit,hoursValue);
		let hoursRangePercentage = ((hoursValue-hoursMin)*100)/(hoursLimit-hoursMin);
		let hoursCompleteBarPercentage = ((hoursValue-hoursMin)*100)/(hoursMax-hoursMin);
		let hoursLimitPercentage = ((hoursLimit-hoursMin)*100)/(hoursMax-hoursMin);
		let hoursPartialBarPercentage = Math.min(hoursCompleteBarPercentage,hoursLimitPercentage);
		let showHoursOverflowBar = hoursCompleteBarPercentage > hoursLimitPercentage;
		let hoursColor;

		if(hoursCompleteBarPercentage < 75){
			hoursColor = "#28a745"
		}else if(hoursCompleteBarPercentage < 90){
			hoursColor = "#ffc107"
		}else{
			hoursColor = "#dc3545"
		}

		let dateLimit = moment(this.props.date_end).startOf('day');
		let dateValue = moment(this.props.date_value).startOf('day');
		let dateMin = moment(this.props.date_start).startOf('day');
		let dateMax = moment.max(dateLimit, dateValue);
		let dateRangePercentage = ((dateValue-dateMin)*100)/(dateLimit-dateMin);
		let dateCompleteBarPercentage = ((dateValue-dateMin)*100)/(dateMax-dateMin);
		let dateLimitPercentage = ((dateLimit-dateMin)*100)/(dateMax-dateMin);
		let datePartialBarPercentage = Math.min(dateCompleteBarPercentage,dateLimitPercentage);
		let showDateOverflowBar = dateCompleteBarPercentage > dateLimitPercentage;
		let dateColor;

		if(dateCompleteBarPercentage < 75){
			dateColor = "#28a745"
		}else if(dateCompleteBarPercentage < 90){
			dateColor = "#ffc107"
		}else{
			dateColor = "#dc3545"
		}

		return (
			<React.Fragment>
				<div id="outerContainer" className={classes.outerContainrer}>
					<div id="innerContainer" className={classes.innerContainer}>
						<div
							id="dateLabels"
							className={classes.rangeLabels}
							style={{
								width:""+dateLimitPercentage+"%",
							}}
						>
							<span className={classNames(classes.dateRangeLabelMin,classes.genericRangeLabels)}>{moment(dateMin).format('DD/MM/YYYY')}</span>
							<span
								className={classes.dateRangeLabelValue}
								style={{
									left:""+dateRangePercentage+"%",
								}}
							>
								{moment(dateValue).format('DD/MM/YYYY')}
							</span>
							<span className={classNames(classes.dateRangeLabelMax, classes.genericRangeLabels)}>{moment(dateLimit).format('DD/MM/YYYY')}</span>
						</div>
						<div
							id="dateRange"
							className={classes.dateRange}
							style={{
								width:""+dateLimitPercentage+"%",
							}}
						>
							<MenuDown
								className={classes.dateValueIndicator}
								style={{
									left:""+dateRangePercentage+"%",
								}}
							/>
						</div>
						<div id="dateProgressBarContainer" className={classes.dateBarContainer}>
							{showDateOverflowBar && (
								<div
									id="dateOverflowBarBody"
									className={classes.overflowBar}
								></div>
							)}
							<div
								id="dateProgressBarBody"
								className={classes.barBody}
								style={{
									width:""+datePartialBarPercentage+"%",
									backgroundColor: dateColor
								}}
							></div>
						</div>
						<div id="hoursProgressBarContainer" className={classes.hoursBarContainer}>
							{showHoursOverflowBar && (
								<div
									id="hoursOverflowBarBody"
									className={classes.overflowBar}
								></div>
							)}
							<div
								id="hoursProgressBarBody"
								className={classes.barBody}
								style={{
									width:""+hoursPartialBarPercentage+"%",
									backgroundColor: hoursColor
								}}
							></div>
						</div>
						<div
							id="hoursRange"
							className={classes.hoursRange}
							style={{
								width:""+hoursLimitPercentage+"%",
							}}
						>
							<MenuUp
								className={classes.hoursValueIndicator}
								style={{
									left:""+hoursRangePercentage+"%",
								}}
							/>
						</div>
						<div
							id="hoursLabels"
							className={classes.rangeLabels}
							style={{
								width:""+hoursLimitPercentage+"%",
							}}
						>
							<span className={classNames(classes.hoursRangeLabelMin,classes.genericRangeLabels)}>{hoursMin}h</span>
							<span
								className={classes.hoursRangeLabelValue}
								style={{
									left:""+hoursRangePercentage+"%",
								}}
							>
								{hoursValue}h
							</span>
							<span className={classNames(classes.hoursRangeLabelMax, classes.genericRangeLabels, classes.genericHoursRangeLabel)}>{hoursLimit}h</span>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

DatedLabelledProgressBar.propTypes = {
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
	date_start: PropTypes.object.isRequired,
	date_end: PropTypes.object.isRequired,
	date_value: PropTypes.object.isRequired,
};

export default withStyles(styles,{withTheme: true})(DatedLabelledProgressBar);
