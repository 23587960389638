import moment from 'moment';

import {dateFormat} from 'variables/general.jsx';

class TimesepentsFetchers
{

    fetchPeriodTimespent = (desiredStart, desiredEnd, alreadyFetchedRanges, apiController, user, idToSearch, entityToSearch, withoutDetails, logger) => {
		logger.debug("Try FETCH from "+desiredStart+" to "+desiredEnd);
		let searchRange = this.checkGotTimespentsPeriod(desiredStart, desiredEnd, alreadyFetchedRanges, logger);
		if(searchRange !== null){
			let start_date = searchRange.start_date;
			let end_date = searchRange.end_date;
			logger.debug('Fetch from '+start_date+' to '+end_date);
			alreadyFetchedRanges.push({start_date:moment(start_date),end_date:moment(end_date)});

			return apiController.getAll(
				idToSearch,
				entityToSearch,
				start_date,
				end_date,
				withoutDetails,
				{
					auth_username:user.username,
					auth_token:user.token,
			}).then((response)=>{
				return response.data;
			}).catch((error) => {
				throw error;
			});
		} else {
			return new Promise((resolve) => {
				resolve()
			});
		}
	}

	rangeAlreadyFetched = (start_date, end_date, alreadyFetchedRanges, logger) => {
		let toCheckStart = moment(start_date);
		let toCheckEnd = moment(end_date);
		logger.debug('Range already fetched ? from '+toCheckStart.format(dateFormat)+' to '+toCheckEnd.format(dateFormat));
		logger.debug(alreadyFetchedRanges);
		for(let alreadyFetched of alreadyFetchedRanges){
			logger.debug(alreadyFetched);
			logger.debug('Test '+alreadyFetched.start_date.format(dateFormat)+' to '+alreadyFetched.end_date.format(dateFormat));
			if(toCheckStart >= alreadyFetched.start_date && toCheckEnd <= alreadyFetched.end_date){
				logger.debug('YES');
				return true;
			}
		}
		return false;
	}

	checkGotTimespentsPeriod = (start_date, end_date, alreadyFetchedRanges, logger) => {
		let desiredStart = moment(start_date);
		let desiredEnd = moment(end_date);
		logger.debug('check from '+desiredStart.format(dateFormat)+' to '+desiredEnd.format(dateFormat));
		for(let alreadyFetched of alreadyFetchedRanges){
			logger.debug('already have '+alreadyFetched.start_date.format(dateFormat)+' to '+alreadyFetched.end_date.format(dateFormat));
			if(desiredEnd >= alreadyFetched.start_date && desiredEnd <= alreadyFetched.end_date){
				if(desiredStart >= alreadyFetched.start_date){
					desiredEnd = alreadyFetched.end_date;
					logger.debug('desiredEnd = end_date');
				}else{
					desiredEnd = alreadyFetched.start_date;
					logger.debug('desiredEnd = start_date');
				}
			}
			if(desiredStart >= alreadyFetched.start_date && desiredStart <= alreadyFetched.end_date){
				if(desiredEnd <= alreadyFetched.end_date){
					desiredStart = alreadyFetched.start_date;
					logger.debug('desiredStart = start_date');
				}else{
					desiredStart = alreadyFetched.end_date;
					logger.debug('desiredStart = end_date');
				}
			}
			if(desiredStart === alreadyFetched.start_date && desiredEnd === alreadyFetched.end_date){
				logger.debug('return null');
				return null;
			}
		}
		logger.debug('return '+desiredStart.format(dateFormat)+' to '+desiredEnd.format(dateFormat));
		return {start_date: desiredStart.format(dateFormat), end_date: desiredEnd.format(dateFormat)};

	}
}

export default TimesepentsFetchers;