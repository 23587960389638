import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
} from '@material-ui/core';

import{
    Plus
} from 'mdi-material-ui';
import BasicClientForm from 'components/Clients/BasicClientForm.jsx';

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    creation:{
        backgroundColor: theme.button.creation,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    clientContainer:{
        overflow:'visible'
    }
});

class ClientCreation extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            name: "",
            color: "#CCCCCC",
            description: "",
			name_errorMessage: "",
            description_errorMessage: "",
            userId:-1,
            user_errorMessage: "",
        };
        this.creationRight = this.props.user.adminPermission.clients.createUpdate;

        if(!this.creationRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    //======================= UI Callback ========================

    create_click = () => {
        if(this.state.name === ""){
            this.setState({name_errorMessage: "Le champ ne peut être vide"});
        } else if(this.state.userId === -1){
            this.setState({user_errorMessage: "Vous devez choisir un utilisateur"});
        } else {
            this.props.apiControllers.clients.create({
                name: this.state.name,
                description: this.state.description,
                color: this.state.color,
                userId: this.state.userId,
            },{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token
            }).then((response) => {
                this.props.notifier.success('Client enregistré');
                this.props.history.goBack();
            }).catch((error) => {
                this.props.notifier.error(error.data);
            });
        }
    }

    name_change = (event) => {
        this.setState({
            name: event.target.value,
            name_errorMessage: ""
        });
    }

    color_change = (color) => {
        this.setState({ color: color.hex });
    };

    description_change = (event) => {
        this.setState({
            description: event.target.value,
            description_errorMessage: ""
        });
    }

    userId_change = (event) => {
        this.setState({
            userId: event.target.value,
            user_errorMessage: ""
        });
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card
                        className={classes.clientContainer}
                    >
                        <CardHeader
                            title={"Création d'un client"}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <BasicClientForm
                                        apiControllers={this.props.apiControllers}
                                        logger={this.props.logger}
                                        name={this.state.name}
                                        description={this.state.description}
                                        color={this.state.color}
                                        userId={this.state.userId}
                                        auth_user={this.props.user}
                                        name_errorMessage={this.state.name_errorMessage}
                                        description_errorMessage={this.state.description_errorMessage}
                                        user_errorMessage={this.state.user_errorMessage}
                                        name_change={this.name_change}
                                        description_change={this.description_change}
                                        color_change={this.color_change}
                                        userId_change={this.userId_change}
                                        checkAuthentication={this.props.checkAuthentication}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={this.create_click}
                                        className={classNames(classes.button,classes.creation)}
                                        variant="contained"
                                    >
                                        <Plus className={classes.leftIcon} />
                                        Création
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

ClientCreation.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(ClientCreation);
