// @material-ui/icons
import DashboardPage from "views/Dashboard/Dashboard.jsx";
import ProjectManagmentDashboardPage from "views/Projects/ProjectManagmentDashboard";
import ClientManagmentDashboardPage from "views/Clients/ClientManagmentDashboard";

import AdminPanelPage from "views/AdminPanel/AdminPanel.jsx";
import ListPage from "views/AdminPanel/EntityList.jsx";

import ReportingPanelPage from "views/ReportingPanel/ReportingPanel.jsx";
import ReportingUsersCalendarPage from "views/ReportingPanel/ReportingUsersCalendar.jsx";
import ReportingUsersOneUserCalendarPage from "views/ReportingPanel/ReportingUsersOneUserCalendar.jsx";
import ReportingUsersOneUserChartPage from "views/ReportingPanel/ReportingUsersOneUserChart.jsx";
import ReportingUsersChartPage from "views/ReportingPanel/ReportingUsersChart.jsx";
import ReportingProjectsOneProjectChartPage from "views/ReportingPanel/ReportingProjectsOneProjectChart.jsx";

import ReportingCrossTable from "views/ReportingPanel/ReportingCrossTable.jsx";

import UsersCreationPage from "views/Users/Create.jsx";
import UserProfilePage from "views/Users/ViewOne.jsx";

import ProjectsCreationPage from "views/Projects/Create.jsx";
import ProjectViewOnePage from "views/Projects/ViewOne.jsx";

import ClientsCreationPage from "views/Clients/Create.jsx";
import ClientViewOnePage from "views/Clients/ViewOne.jsx";

import CategoriesCreationPage from "views/Categories/Create.jsx";
import CategoryViewOnePage from "views/Categories/ViewOne.jsx";

import TagsCreationPage from "views/Tags/Create.jsx";
import TagViewOnePage from "views/Tags/ViewOne.jsx";

import EventsEditPage from "views/Events/Edit.jsx";

const dashboardRoutes = [{
        path: "/dashboard",
        component: DashboardPage
    },{
        path: "/projects/dashboard",
        component: ProjectManagmentDashboardPage
    },{
        path: "/clients/dashboard",
        component: ClientManagmentDashboardPage
    },{
        path: "/users/create",
        component: UsersCreationPage
    },{
        path: "/userprofile/:userId",
        component: UserProfilePage
    },{
        path: "/users/:userId",
        component: UserProfilePage
    },{
        path: "/projects/create",
        component: ProjectsCreationPage
    },{
        path: "/projects/:projectId",
        component: ProjectViewOnePage
    },{
        path: "/clients/create",
        component: ClientsCreationPage
    },{
        path: "/clients/:clientId",
        component: ClientViewOnePage
    },{
        path: "/categories/create",
        component: CategoriesCreationPage
    },{
        path: "/categories/:categoryId",
        component: CategoryViewOnePage
    },{
        path: "/tags/create",
        component: TagsCreationPage
    },{
        path: "/tags/:tagId",
        component: TagViewOnePage
    },{
        path: "/events/edit",
        component: EventsEditPage
    },{
        path: "/admin",
        component: AdminPanelPage
    },{
        path: "/list/:entityName(users|projects|categories|tags|clients)",
        component: ListPage
    },{
        path: "/reporting/panel",
        component: ReportingPanelPage
    },{
        path: "/reporting/users/calendar",
        component: ReportingUsersCalendarPage
    },{
        path: "/reporting/users/chart",
        component: ReportingUsersChartPage
    },{
        path: "/reporting/users/oneUserCalendar",
        component: ReportingUsersOneUserCalendarPage
    },{
        path: "/reporting/users/oneUserChart/:userId",
        component: ReportingUsersOneUserChartPage
    },{
        path: "/reporting/users/oneUserChart",
        component: ReportingUsersOneUserChartPage
    },{
        path: "/reporting/projects/oneProjectChart/:projectId",
        component: ReportingProjectsOneProjectChartPage
    },{
        path: "/reporting/projects/oneProjectChart/",
        component: ReportingProjectsOneProjectChartPage
    },{
        path: "/reportingCrossTable/:searchedEntityName/:displayedEntityName/:collapsedEntityName/:searchedId",
        component: ReportingCrossTable
    },{
        path: "/reportingCrossTable/:searchedEntityName/:displayedEntityName/:collapsedEntityName",
        component: ReportingCrossTable
    },{
        redirect: true,
        path: "/",
        to: "/dashboard"
    }
];

export default dashboardRoutes;