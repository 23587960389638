import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {Grid,
    Card,
    CardContent,
    CardHeader,
    Button
} from '@material-ui/core';

import{
    Plus
} from 'mdi-material-ui';
import BasicTagForm from 'components/Tags/BasicTagForm.jsx';

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    creation:{
        backgroundColor: theme.button.creation,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
});

class TagCreation extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            name: "",
            color: "#CCCCCC",
			name_errorMessage: "",
        };

        this.creationRight = this.props.user.adminPermission.tags.createUpdate;

        if(!this.creationRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    //======================= UI Callback ========================

    create_click = () => {
        if(this.state.name === ""){
            this.setState({name_errorMessage: "Le champ ne peut être vide"});
        }else {
            this.props.apiControllers.tags.create({
                    name: this.state.name,
                    color: this.state.color,
                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }).then((response) => {
                    this.props.notifier.success('Tag enregistrée');
                    this.props.history.goBack();
                }).catch((error) => {
                    this.props.notifier.error(error.data);
                });
        }
    }

    name_change = (event) => {
        this.setState({
            name: event.target.value,
            name_errorMessage: ""
        });
    }

    color_change = (color) => {
        this.setState({ color: color.hex });
    };

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Création d'un tag"}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <BasicTagForm
                                        name={this.state.name}
                                        color={this.state.color}
                                        name_errorMessage={this.state.name_errorMessage}
                                        name_change={this.name_change}
                                        color_change={this.color_change}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={this.create_click}
                                        className={classNames(classes.button,classes.creation)}
                                        variant="contained"
                                    >
                                        <Plus className={classes.leftIcon} />
                                        Création
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

TagCreation.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(TagCreation);
