import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import TimespentsCalendar from 'components/Timespents/TimespentsCalendar.jsx';
import AddTimespentForm from 'components/Timespents/AddTimespentForm.jsx';

import moment from 'moment';

import './Dashboard.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import TimespentsFetchers from 'utils/timespentsFetchers.js';

import {dateFormat} from 'variables/general.jsx';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
	Button,
	CardHeader,
	Card,
	Typography,
	CardContent,
} from '@material-ui/core';

import {
	Folder,
	Briefcase,
	ChartGantt,
	ChartLine
} from "mdi-material-ui"

const styles = theme => ({

    leftIcon: {
        marginRight: theme.spacing(),
    },
	calendarContainer:{
		paddingBottom:"0!important",
	},
	formCardsContainer:{
		flexDirection: 'column',
		display:'block'
	},
	addTimespentCard_container:{
		flex:1
	},
    addTimespentCard:{
		overflow:"visible",
		"&>div>*":{
			margin:"2% 0"
		}
	},
});

const timespentsFetchers = new TimespentsFetchers();

class Dashboard extends React.Component {

	constructor(props) {
		super(props);
		this.props.loadingDialog.open();
		this.state = {
			timespents: [],
			clientsList:[],
			projectsList: [],
			calendarRange:{
				from:new Date(moment().startOf('month')),
				to:new Date(moment().endOf('month'))
			},
			selectedDate: moment(),
			currentMonthHours:0
		};
		this.alreadyFetchedRanges = [];

		this.updateRight = this.props.user.adminPermission.timespents.users.createUpdate.self;
		this.absenceUpdateRight = this.props.user.adminPermission.timespents.absences.createUpdate.self;
		this.absenceViewRight = this.props.user.adminPermission.timespents.absences.view.self;
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	componentDidMount = () => {
		this.getProjectList()
		.then(() => this.getClientList())
		.then(() => {
			this.getThisMonthTimespents();
		});
	}

	componentWillUnmount = () => {
		this.props.loadingDialog.close();
	}

    //======================= Tool Functions ========================

	getProjectList = () => {
		return this.props.apiControllers.projects.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({projectsList: response.data});
			return true;
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getClientList = () => {
		return this.props.apiControllers.clients.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({clientsList: response.data});
			return true;
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}


	getThisMonthTimespents = () => {
		return this.props.loadingDialog.open()
		.then(()=>{
			setTimeout(()=>{
				let start_date = moment(this.state.calendarRange.from).format(dateFormat);
				let end_date = moment(this.state.calendarRange.to).format(dateFormat);
				return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, this.alreadyFetchedRanges, this.props.apiControllers.timespents, this.props.user, this.props.user.id, 'users', true, this.props.logger).then((timespents)=> {
					let timespentsBase = this.state.timespents;
					let timespentsToAdd = timespents;
					if(timespentsToAdd !== undefined){
						for(let timespent of timespentsToAdd){
							if(timespent.project.id !== 1 || this.absenceViewRight){
								timespentsBase.push(timespent);
							}
						}
					}

					return this.promisedSetState({timespents: timespentsBase}).then(()=>{
						this.computeTotalHours()
						this.props.loadingDialog.close();
					});

				}).catch((error) => {
					this.props.loadingDialog.close();
					if(error.status === 403){
						this.props.notifier.error('Accès interdit');
						this.props.history.push('/');
					}else{
						this.props.checkAuthentication();
					}
				});
			});
		});
	}

	computeTotalHours = () =>{
		let totalHours = 0;
		this.state.timespents.map(timespent => {
			if((timespent.project.id !== 1 || timespent.category.id === 9) && moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]')){
				totalHours+=timespent.hours;
			}
			return timespent;
		});
		this.setState({currentMonthHours:totalHours});
	}

	addLabelValuesToArray = (array) => {
		return array.map(project => ({
			...project,
			label:project.name,
			value:project.id
		}));
	}

	//======================= UI Callback ========================

	showUserChart_clicked = () => {
		this.props.history.push(`/reporting/users/oneUserChart/${this.props.user.id}`);
	}

	showByProjects_clicked = () => {
        this.props.history.push(`/reportingCrossTable/users/projects/categories/${this.props.user.id}`);
    }

    showByCategories_clicked = () => {
        this.props.history.push(`/reportingCrossTable/users/categories/projects/${this.props.user.id}`);
	}

	projectsDashboard_clicked = () => {
        this.props.history.push(`/projects/dashboard`);
	}

	clientsDashboard_clicked = () => {
        this.props.history.push(`/clients/dashboard`);
    }

	calendarRangeChange_handler = (dateRange) => {
		this.setState({calendarRange:dateRange},
			() => {this.getThisMonthTimespents()});
	}

	calendarDateChange_handler = (date) => {
		this.setState({selectedDate: date});
	}

	addTimespent_handler = (timespent) => {
		if(timespent !== undefined){
			if(timespentsFetchers.rangeAlreadyFetched(timespent.date, timespent.date, this.alreadyFetchedRanges, this.props.logger)){
				let timespentsBase = this.state.timespents;
				timespentsBase.push(timespent);
				this.setState({timespents:timespentsBase});
				this.getThisMonthTimespents();
			}
		}
	}

	updateTimespent_handler = (timespent) => {
		if(timespent !== undefined){
			let timespentsBase = this.state.timespents;
			let index = timespentsBase.indexOf(timespentsBase.find(el => el.id === timespent.id));
			timespentsBase[index] = timespent;
			this.setState({timespents:timespentsBase});
			this.getThisMonthTimespents();
		}
	}

	deleteTimespent_handler = (timespent) => {
		if(timespent !== undefined){
			let timespentsBase = this.state.timespents;
			let timespentIndex = timespentsBase.indexOf(timespentsBase.find(el => el.id === timespent.id));
			if(timespentIndex >= 0){
				timespentsBase.splice(timespentIndex,1);
			}
			this.setState({timespents:timespentsBase});
			this.getThisMonthTimespents();
		}
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<Grid container spacing={2} className='dashboardContainer'>
				<Grid container item xs={3} spacing={2} className={classes.formCardsContainer}>
					{this.updateRight&&
						<Grid item xs={12} className={classes.addTimespentCard_container}>

							<Card className={classes.addTimespentCard}>
								<CardHeader
									title={
										<Typography variant='h2'>
											Ajouter un temps
										</Typography>
									}
								/>
								<CardContent>
									<AddTimespentForm
										addTimespent_handler={this.addTimespent_handler}
										clientsList={this.state.clientsList}
										projectsList={this.state.projectsList}
										user={this.props.user}
										auth_user={this.props.user}
										userManager={this.props.userManager}
										notifier={this.props.notifier}
										logger={this.props.logger}
										apiControllers={this.props.apiControllers}
										checkAuthentication={this.props.checkAuthentication}
										selectedDate={this.state.selectedDate}
									/>
								</CardContent>
							</Card>
						</Grid>
					}
					<Grid item xs={12} className={classes.addTimespentCard_container}>

						<Card>
							<CardHeader
								title={
									<Typography variant='h2'>
										Visualisations
									</Typography>
								}
							/>
							<CardContent>
								<Grid container spacing={2} justify="center">
									<Grid item xs={10}>
										<Button
											variant="contained"
											color="secondary"
											className={classes.button}
											onClick={this.showUserChart_clicked}
										>
											<ChartLine className={classes.leftIcon} />
											Courbe utilisateur
										</Button>
									</Grid>
									<Grid item xs={10}>
										<Button
											variant="contained"
											color="secondary"
											className={classes.button}
											onClick={this.showByProjects_clicked}
										>
											<Briefcase className={classes.leftIcon} />
											Par projets
										</Button>
									</Grid>
									<Grid item xs={10}>
										<Button
											variant="contained"
											color="secondary"
											className={classes.button}
											onClick={this.showByCategories_clicked}
										>
											<Folder className={classes.leftIcon} />
											Par catégories
										</Button>
									</Grid>
									{this.props.user.projects.length > 0 &&
										<Grid item xs={10}>
											<Button
												variant="contained"
												color="secondary"
												className={classes.button}
												onClick={this.projectsDashboard_clicked}
											>
												<ChartGantt className={classes.leftIcon} />
												Suivi des projets
											</Button>
										</Grid>
									}
									{(this.props.user.clients.length > 0 || this.props.user.adminPermission.clients.viewDashboard)&&
										<Grid item xs={10}>
											<Button
												variant="contained"
												color="secondary"
												className={classes.button}
												onClick={this.clientsDashboard_clicked}
											>
												<ChartGantt className={classes.leftIcon} />
												Suivi des clients
											</Button>
										</Grid>
									}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={9} className={classes.calendarContainer}>
					<TimespentsCalendar
						displayAbsences={this.absenceViewRight}
						accessor="project"
						selectable={true}
						daySum={true}
						enableUpdateTimespent={this.updateRight}
						enableUpdateAbsence={this.absenceUpdateRight}
						rangeChange_handler={this.calendarRangeChange_handler}
						dateChange_handler={this.calendarDateChange_handler}
						updateTimespent_handler={this.updateTimespent_handler}
						deleteTimespent_handler={this.deleteTimespent_handler}
						timespents={this.state.timespents}
						apiControllers={this.props.apiControllers}
						notifier={this.props.notifier}
						logger={this.props.logger}
						user={this.props.user}
						userToDisplay={this.props.user}
						checkAuthentication={this.props.checkAuthentication}
						loadingDialog={this.props.loadingDialog}
						workedMonthHours={this.state.currentMonthHours}
						contracts={this.props.user.userContracts}
					/>
				</Grid>
			</Grid>

		);
	}
}

Dashboard.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(Dashboard);
