import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Checkbox,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';

const styles = theme => ({
});

const permissionsLabel={
    users:"Utilisateurs",
    view:'Affichage',
    viewDisabled:'Affichage Désactivés',
    viewSystem:'Affichage Systèmes',
    createUpdate:'Création / Modification',
    disable:'Désactivation',
    projects:'Projets',
    categories:'Catégories',
    clients:'Clients',
    tags:'Tags',
    events:'Événements',
    logsView:'Logs',
    timespents:'Temps',
    self:'Soi-même',
    others:'Autres',
    absences:'Absences',
}

const permissionRights=[
    "createUpdate",
    "disable",
    "viewDisabled",
    "viewSystem"
]

const permissionCategories=[
    "users",
    "projects",
    "categories",
    "tags",
    "events",
    "clients"
]

const timespentsCategories=[
    "users",
    "absences"
]

class RightTable extends React.Component {

    //======================= UI Callback ========================

    changeRight = (category, right, option) => event => {
        let adminPermission = this.props.adminPermission;
        if(option !== undefined){
            adminPermission.timespents[category][right][option] = event.target.checked;
        }else{
            adminPermission[category][right] = event.target.checked;
        }

        if(category === 'absences' && right === 'createUpdate' && event.target.checked){
            adminPermission.projects.viewSystem = true;
            adminPermission.categories.viewSystem = true;
        }else if(category === 'projects' && right === 'viewSystem' && !event.target.checked){
            adminPermission.timespents.absences.createUpdate.self = false;
            adminPermission.timespents.absences.createUpdate.others = false;
        }else if(category === 'categories' && right === 'viewSystem' && !event.target.checked){
            adminPermission.timespents.absences.createUpdate.self = false;
            adminPermission.timespents.absences.createUpdate.others = false;
        }

        if(category === 'absences' && right === 'view' && option === 'others' && event.target.checked){
            adminPermission.timespents.users.view.others = true;
        }else if(category === 'users' && right === 'view' && option === 'others' && !event.target.checked){
            adminPermission.timespents.absences.view.others = false;
        }

        if(right === 'disable' && event.target.checked){
            adminPermission[category].createUpdate = true;
        }else if(option === undefined &&  right === 'createUpdate' && !event.target.checked){
            adminPermission[category].disable = false;
        }
        this.props.changePermissions_handler(adminPermission)

    };

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Table className={classes.table}>
                <TableHead>
                    <TableRow>
                        <TableCell>Catégorie</TableCell>
                        {permissionRights.map(right => (
                            <TableCell key={right}>{permissionsLabel[right]}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permissionCategories.map(category => (
                        <TableRow key={category}>
                            <TableCell>{permissionsLabel[category]}</TableCell>
                            {this.props.adminPermission && permissionRights.map(right=>{
                                return(
                                    <TableCell key={right}>
                                        <Checkbox
                                            checked={this.props.adminPermission[category][right]}
                                            onChange={this.changeRight(category,right)}
                                        />
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    ))}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell>Catégorie</TableCell>
                        <TableCell>Vue soi-même</TableCell>
                        <TableCell>Vue des autres</TableCell>
                        <TableCell>Modification / Création soi-même</TableCell>
                        <TableCell>Modification / Création des autres</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.adminPermission && timespentsCategories.map(category =>(
                        <TableRow key={category}>
                            <TableCell>{permissionsLabel[category]}</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={this.props.adminPermission.timespents[category].view.self}
                                    onChange={this.changeRight(category,"view","self")}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={this.props.adminPermission.timespents[category].view.others}
                                    onChange={this.changeRight(category,"view","others")}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={this.props.adminPermission.timespents[category].createUpdate.self}
                                    onChange={this.changeRight(category,"createUpdate","self")}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={this.props.adminPermission.timespents[category].createUpdate.others}
                                    onChange={this.changeRight(category,"createUpdate","others")}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableHead>
                    <TableRow>
                        <TableCell>Catégorie</TableCell>
                        <TableCell>Vue dashboard</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.adminPermission && (
                        <TableRow key='clientDashboard'>
                            <TableCell>{permissionsLabel['clients']}</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={this.props.adminPermission.clients.viewDashboard}
                                    onChange={this.changeRight('clients','viewDashboard')}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        );
    }
}

RightTable.propTypes = {
    adminPermission: PropTypes.object,
    changePermissions_handler: PropTypes.func.isRequired
};

export default withStyles(styles,{withTheme: true})(RightTable);
