class AbstractAPIController {

    constructor(entityName, connectionManager, logger) {
        if (new.target === AbstractAPIController) {
			throw new Error('Cannot construct AbstractAPIController instances directly');
        }
        this.entityName = entityName;
        this.connectionManager = connectionManager;
        this.logger = logger;
    }

    getAll = (auth_parameters) =>{
        return this.connectionManager.get(this.entityName,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    getAllDisabled = (auth_parameters) =>{
        return this.connectionManager.get(this.entityName+'/disabled',auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    getById(id,auth_parameters) {
        return this.connectionManager.get(this.entityName+"/"+id,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    create(body_parameters,auth_parameters) {
        return this.connectionManager.post(this.entityName,body_parameters,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }

    update(id,body_parameters,auth_parameters) {
        return this.connectionManager.put(this.entityName+"/"+id,body_parameters,auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }
}

export default AbstractAPIController;