import AbstractAPIController from 'utils/APIControllers/abstractAPIController.js';

class UserAPIController extends AbstractAPIController{

    constructor(connectionManager, logger) {
        super('userContracts',connectionManager, logger);
    }

    delete(id,auth_parameters) {
        return this.connectionManager.delete(this.entityName+"/"+id,{},auth_parameters).then((response)=>{
            return response;
        }).catch((error)=>{
            throw error;
        })
    }
}

export default UserAPIController;