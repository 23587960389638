import {
	Button, withStyles
} from "@material-ui/core";
import classNames from 'classnames';
import SuppressDialog from 'components/SuppressDialog/SuppressDialog.jsx';
import GenericTimespentDialog from 'components/Timespents/GenericTimespentDialog.jsx';
import {
	Delete, Pencil
} from "mdi-material-ui";
import PropTypes from 'prop-types';
import React from 'react';




const hours_limit= {
	min: 0.5,
	max: 24,
	step: 0.5
};

const style_override = theme => ({
	leftIcon: {
		marginRight: theme.spacing(),
	},
	deletion:{
		backgroundColor: theme.button.deletion,
		color: theme.palette.primary.contrastText
	}
});

class UpdateTimespentDialog extends React.Component {

	constructor(props) {
		super(props);
		console.log(this.props)
		this.state = {
			timespent: undefined,
			selectedCategory: undefined,
			selectedProject: undefined,
			selectedTags: [],
			hours: hours_limit.min,
			hours_errorMessage: '',
			category_errorMessage: '',
			project_errorMessage:'',
			suppressDialog_open: false
		};
	}

	//======================= UI Callback ========================

	dialogEnter_handler = () => {
		this.props.loadingDialog.open();
		this.props.apiControllers.timespents.getById(this.props.timespent.id,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token,
        }).then((response)=>{
			this.setState({timespent: response.data,
				hours_errorMessage: '',
				category_errorMessage:'',
				project_errorMessage:'',
				selectedCategory:response.data.category,
				selectedProject:response.data.project,
				selectedTags:response.data.tags,
				hours:response.data.hours,
			});
			this.props.loadingDialog.close();
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
			this.props.loadingDialog.close();
        });
	}

	update_handler = (timespent) => {
		this.setState({
			selectedCategory: undefined,
			selectedProject: undefined,
			selectedTags: [],
			hours: hours_limit.min,
			hours_errorMessage: '',
			category_errorMessage: '',
			project_errorMessage:''
		});
		this.props.updateHandler(timespent);
	}

	delete_handler = (timespent) => {
		this.setState({
			selectedCategory: undefined,
			selectedProject: undefined,
			selectedTags: [],
			hours: hours_limit.min,
			hours_errorMessage: '',
			category_errorMessage: '',
			project_errorMessage:''
		});
		this.props.deleteHandler(timespent);
	}

	closeFromDialog_handler = () => {
		this.update_handler();
	}

	updateTimespent_handler = (event) => {
		let hours = this.state.hours;
		let category = this.state.selectedCategory;
		let project = this.state.selectedProject;
		if(hours < hours_limit.min || hours > hours_limit.max || (hours % hours_limit.step !== 0)){
			this.setState({hours_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else if(category === undefined){
			this.setState({category_errorMessage: "Vous devez sélectionner une catégorie"});
		}else if(project === undefined){
			this.setState({project_errorMessage: "Vous devez sélectionner un projet"});
		}else{
			this.props.apiControllers.timespents.update(this.state.timespent.id,{
				categoryId: category.id,
				projectId: project.id,
				hours: hours,
				description:'',
				tags:this.state.selectedTags.map(tag => tag.id)
			},{
				auth_username:this.props.user.username,
				auth_token:this.props.user.token
			}).then((response) => {
				this.props.notifier.success('Temps modifié');
				this.update_handler(response.data);
			}).catch((error) => {
				this.props.notifier.error(error.data);
			});
		}
	};

	deleteTimespent_handler = (event) => {
		this.props.apiControllers.timespents.delete(this.state.timespent.id,{
			auth_username:this.props.user.username,
			auth_token:this.props.user.token
		}).then((response) => {
			this.props.notifier.success('Temps Supprimé');
			this.closeSuppressDialog_handler();
			this.delete_handler(this.state.timespent);
		}).catch((error) => {
			this.props.notifier.error(error.data);
		});
	};

	hoursChange_handler = (event) => {
		this.setState({hours: event.target.value});
		let value = event.target.value;
		if(value < hours_limit.min || value > hours_limit.max || (value % hours_limit.step !== 0)){
			this.setState({hours_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else{
			this.setState({hours_errorMessage: ''});
		}
	}

	projectChange_handler = (project) => {
		this.setState({
            selectedProject: project,
            selectedCategory: undefined,
			selectedTags: [],
			category_errorMessage: '',
			project_errorMessage: ''
		});
	}

	categoryChange_handler = (category) => {
		this.setState({
			selectedCategory: category,
			selectedTags: [],
			category_errorMessage: ''
		});
	}

	tagsChange_handler = (tags) => {
		this.setState({
			selectedTags: tags
		})
	}

	openSuppressDialog_handler = () => {
		this.setState({suppressDialog_open: true});
	}

	closeSuppressDialog_handler = () => {
		this.setState({suppressDialog_open: false});
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<SuppressDialog
					open={this.state.suppressDialog_open}
					permanent={true}
					deleteHandler={this.deleteTimespent_handler}
					closeHandler={this.closeSuppressDialog_handler}
				/>
				<GenericTimespentDialog
					date={this.state.timespent ? this.state.timespent.date : ""}
					dialogEnter_handler={this.dialogEnter_handler}
					title={"Modifier un temps"}
					closeDialog={this.closeFromDialog_handler}
					apiControllers={this.props.apiControllers}
					open={this.props.open}
					logger={this.props.logger}
					user={this.props.user}
					notifier={this.props.notifier}
					checkAuthentication={this.props.checkAuthentication}
					actions={(
						<React.Fragment>
							<Button
								onClick={this.openSuppressDialog_handler}
								className={classNames(classes.button,classes.deletion)}
								variant="contained"
							>
								<Delete className={classes.leftIcon} />
								Supprimer
							</Button>
							<Button
								onClick={this.updateTimespent_handler}
								color="secondary"
								className={classes.button}
								variant="contained"
							>
								<Pencil className={classes.leftIcon} />
								Modifier
							</Button>
						</React.Fragment>
					)}

					hoursChange_handler={this.hoursChange_handler}
					projectChange_handler={this.projectChange_handler}
					categoryChange_handler={this.categoryChange_handler}
					tagsChange_handler={this.tagsChange_handler}
					selectedHours={this.state.hours}
					selectedProject={this.state.selectedProject}
					selectedCategory={this.state.selectedCategory}
					selectedTags={this.state.selectedTags}
					hours_errorMessage={this.state.hours_errorMessage}
					project_errorMessage={this.state.project_errorMessage}
					category_errorMessage={this.state.category_errorMessage}
				/>
			</React.Fragment>
		);
	}
}

UpdateTimespentDialog.propTypes = {
	updateHandler: PropTypes.func.isRequired,
	deleteHandler: PropTypes.func.isRequired,
	apiControllers: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	logger: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	timespent: PropTypes.object,
	date: PropTypes.string,
	checkAuthentication:PropTypes.func.isRequired,
	loadingDialog: PropTypes.object.isRequired
};

export default withStyles(style_override,{withTheme: true})(UpdateTimespentDialog);
