import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { ChromePicker } from 'react-color'
import {
    Grid,
    TextField,
    Card,
    CardContent,
} from '@material-ui/core';

const styles = theme => ({
    colorPicker: {
        width: '90% !important'
    },
    colorResult:{
        height: '100%'
    }
});

class BasicTagForm extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                <Grid container item xs={6}>
                    <Grid item xs={12}>
                        <TextField
                            id="displayname-input"
                            label="Nom"
                            value={this.props.name}
                            onChange={this.props.name_change}
                            margin="normal"
                            error={this.props.name_errorMessage !== "" ? true : false}
                            helperText={this.props.name_errorMessage}
                            />
                    </Grid>
                </Grid>
                <Grid container item xs={6}>
                    <Grid item xs={6}>
                        <ChromePicker
                            disableAlpha={true}
                            className={classes.colorPicker}
                            color={ this.props.color }
                            onChangeComplete={ this.props.color_change }
                        />

                    </Grid>
                    <Grid item xs={6}>
                        <Card
                            className={classes.colorResult}
                            style={{backgroundColor: this.props.color}}
                        >
                            <CardContent
                            ></CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }
}

BasicTagForm.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    name_errorMessage: PropTypes.string.isRequired,
    name_change: PropTypes.func.isRequired,
    color_change: PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(BasicTagForm);
