import React from "react";
import ReactToPrint from 'react-to-print';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';

import moment from 'moment';

import {Line, Pie} from 'react-chartjs-2';
import 'chartjs-plugin-annotation';

import TimespentsFetchers from 'utils/timespentsFetchers.js';

import {dateFormat} from 'variables/general.jsx';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
	Card,
	CardContent,
	Button,
	Typography,
	Tab,
	Tabs,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
	Tooltip,
	TableSortLabel,
	IconButton
} from '@material-ui/core';

import {
	Printer,
	CheckboxBlank,
	Eye,
	EyeOff,
	FileDocument,
	ChartPie
} from 'mdi-material-ui';


import { entityDisplayNames } from "variables/general.jsx";

const chartSkeleton = {
	fill: false,
	lineTension: 0.0,
	borderCapStyle: 'butt',
	borderJoinStyle: 'miter',
	pointRadius: 3,
	pointBorderWidth: 1,
	pointHoverRadius: 5,
	pointHoverBorderWidth: 2,
	data: []
}

const styles = theme => ({
	formCard:{
		overflow:"visible",
		"&>div>*":{
			margin:"2% 0"
		}
	},
	chartContainer:{
		paddingBottom:"0!important",
		width:'100%',
		height:'90%'
	},
	chartToolbar:{
		display:'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center'
	},
	chartTitle:{
		flexGrow: 1,
		padding: '0 10px',
		textAlign: 'center',
	},
	printVersion:{
		display:'none',
	},
	noPrint:{
		"@media print":{
			opacity:0
		}
	},
	printPage:{
		padding:"20px",
	},
	formCards_container:{
		display:'block'
	},
});

const timespentsFetchers = new TimespentsFetchers();

class ReportingProjectsOneProjectChart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			chartReady:false,
			projectsList:[],
            selectedProject:null,
            projectChart: {},
			timespents: [],
			orderBy: {
				categories:'name',
				tags:'name'
			},
			orderWay: {
				categories:'asc',
				tags:'asc'
			},
			calendarRange:{
				from:new Date(moment().startOf('year')),
				to:new Date(moment().endOf('year'))
			},
			printChart:false,
			chartWidth:null,
			chartXLabels: [],
			totalPeriodHours: 0,
			hoursSum:{
				tags:[],
				categories:[]
			},
			tabIndex:0

		};

		this.viewRight = this.props.user.adminPermission.timespents.users.view.others || this.props.user.projects.length > 0;
		this.completeViewRight = this.props.user.adminPermission.timespents.users.view.others;
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
		}
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
    }

	componentDidMount = () => {
		if(this.completeViewRight){
			this.getProjects().then(() => {
				if(parseInt(this.props.match.params.projectId)){
					let projectInter = this.state.projectsList.find(project => project.id === parseInt(this.props.match.params.projectId));
					if(projectInter){
						this.selectedProject_handler(projectInter);
					}
				}
			});
		}else{
			this.props.logger.debug(this.props.user.projects);
			this.setState({
				projectsList: this.props.user.projects
			}, () => {
				if(this.props.match.params.projectId !== undefined){
					let projectInter = this.state.projectsList.find(project => project.id === parseInt(this.props.match.params.projectId));
					if(projectInter){
						this.selectedProject_handler(projectInter);
					}
				}
			});
		}
	}

	//======================= Tool Functions ========================

	getProjects = () => {
		return this.props.apiControllers.projects.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({projectsList: response.data});
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getCurrentPeriodTimespents = () => {
		let start_date = moment(this.state.calendarRange.from).format(dateFormat);
		let end_date = moment(this.state.calendarRange.to).format(dateFormat);

		this.props.loadingDialog.open();
		return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, [], this.props.apiControllers.timespents, this.props.user, this.state.selectedProject.id, 'projects', true, this.props.logger).then((timespents) => {
			return this.promisedSetState({timespents: timespents, chartReady:false, hoursSum:{tags:[],categories:[]}}).then(() => {
				this.buildDayUserSumsArray(this.state.timespents.filter(timespent => (
					moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]')
				)));
				this.props.loadingDialog.close();
			});
		});
	}

	buildDayUserSumsArray = (timespents) => {

		// Initialize the skeleton of the user chart
		let userChart = {
			...chartSkeleton,
			label: this.state.selectedProject.name,
			backgroundColor: this.state.selectedProject.color,
			borderColor: this.state.selectedProject.color,
			pointHoverBackgroundColor: this.state.selectedProject.color,
			pointHoverBorderColor: this.state.selectedProject.color,
			data:[]
		}

		let totalHoursAccumulator = 0;
		let chartXLabels = [];
		let hoursSum = this.state.hoursSum;

		// This is used when the function is called to hide some categories / tags
		let repopulateHoursSum = false;
		if(hoursSum.categories.length === 0){
			repopulateHoursSum = true;
		}

		//--------------- MONTHES ----------------
		for(let currentMonth = moment(this.state.calendarRange.from); currentMonth.diff(moment(this.state.calendarRange.to)) <= 0; currentMonth.add(1, 'months')){

			// If the current month is after today add a null value to show no line on the chart
			if(currentMonth.isAfter(new Date(), 'month')){
				userChart.data.push({
					hours: 0,
					y: null
				});
			// If there are no timespent in the current month add no hours
			}else if(timespents.find((timespent => currentMonth.isSame(timespent.date,'month')))===undefined){
				userChart.data.push({
					hours: 0,
					y: totalHoursAccumulator
				});
			}else{
				let monthHours = 0;

				// Get only the current month timespent
				let monthTimespents = timespents.filter(timespent => currentMonth.isSame(timespent.date,'month'));

				//--------------- TIMESPENTS ----------------
				for(let currentTimespent of monthTimespents){
					let ignoreThisTimespent = false;

					//========== This part get hours sum of categories and tags ==========

					// If the function is called to repopulate the info
					// (it happens when the selected project is changed)
					if(repopulateHoursSum){

						//------ Categories ---------
						// Find the category of the timespent in the info object
						let categoryInter = hoursSum.categories.find(category => category.id === currentTimespent.category.id);

						// Create it if it does not exist
						if(!categoryInter){
							categoryInter = {
								...currentTimespent.category,
								hoursSum:0,
								visible: true
							};
							hoursSum.categories.push(categoryInter);
						}
						categoryInter.hoursSum += currentTimespent.hours;

						//------ Tags ---------
						// Browse tags of the timespent
						for(let currentTag of currentTimespent.tags){
							// Find the tag of the timespent in the info object
							let tagInter = hoursSum.tags.find(tag => tag.id === currentTag.id);

							// Create it if it does not exist
							if(!tagInter){
								tagInter = {
									...currentTag,
									hoursSum:0,
									visible: true
								};
								hoursSum.tags.push(tagInter);
							}
							tagInter.hoursSum += currentTimespent.hours;
						}
					// If the function is called to hide / show categories or tags
					}else{

						// If the category is hidden
						if(hoursSum.categories.find(category => category.id === currentTimespent.category.id).visible === false){
							ignoreThisTimespent = true;
						}else{
							// Browse tags of timespent
							for(let currentTag of currentTimespent.tags){
								// If one of the tags is hidden
								if(hoursSum.tags.find(tag => tag.id === currentTag.id).visible === false){
									ignoreThisTimespent = true;
								}
							}
						}
					}

					// If the timespent is not ignored
					if(!ignoreThisTimespent){
						monthHours+=currentTimespent.hours;
					}
				}

				totalHoursAccumulator += monthHours;
				userChart.data.push({
					hours: monthHours,
					y: totalHoursAccumulator
				});
			}
			chartXLabels.push(moment(currentMonth).format('MM/YYYY'));
		}

		this.setState({
			userChart: userChart,
			chartXLabels:chartXLabels,
			chartReady:true,
			totalPeriodHours:totalHoursAccumulator,
			hoursSum:hoursSum
		});

	}

    //======================= UI Callback ========================

	selectedProject_handler = (selectedProject) => {
		this.props.logger.debug(selectedProject);
		this.alreadyFetchedRanges = [];
		let calendarRange = {
			from: selectedProject.date_start,
			to: (moment(selectedProject.date_end).isAfter(moment(new Date())) ? selectedProject.date_end : new Date())
		}
		this.setState({
			selectedProject: selectedProject,
			calendarRange: calendarRange,
			timespents:[],
		}, this.getCurrentPeriodTimespents)
	}

	sortData_handler = (entity,property) => (event) => {
		let orderBy = this.state.orderBy;
		let orderWay = this.state.orderWay;
		let newOrderWay = 'desc';

		if (orderBy[entity] === property && orderWay[entity] === "desc") {
			newOrderWay = "asc";
		}

		orderBy[entity] = property;
		orderWay[entity] = newOrderWay;
		this.setState({ orderBy:orderBy, orderWay:orderWay });
	}

	eyeClicked_handler = (entity, id) => (event) => {
		this.changeVisibility(entity, id);
	}

	changeVisibility = (entity, id) => {
		let hoursSum = this.state.hoursSum;
		let elementToChange = hoursSum[entity].find(el => el.id === id);
		elementToChange.visible = !elementToChange.visible;
		this.setState({hoursSum:hoursSum},()=>{
			this.buildDayUserSumsArray(this.state.timespents.filter(timespent => (
				moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]'))));
		});
	}

	sortData = (entity, data) => {
		let sortedData = [...data];
		let orderBy = this.state.orderBy[entity];

		sortedData.sort((a,b) => {
			let testA = a[orderBy];
			let testB = b[orderBy];
			if(typeof testA === 'string'){
				testA = testA.toLowerCase();
				testB = testB.toLowerCase();
			}
			if(this.state.orderWay[entity] === 'asc'){
				return(testA > testB ? 1 : ((testB > testA) ? -1 : 0));
			}
			else{
				return(testA < testB ? 1 : ((testB < testA) ? -1 : 0));
			}
		});
		return sortedData;
	}

	tabIndex_change = (event, newValue) => {
		this.setState({tabIndex:newValue});
	}

	categoryPieLegendClicked = (event, legendItem) => {
		// defaultLegendClickHandler(e, legendItem);
		let categoryToChange = this.state.hoursSum.categories.find(category => category.name === legendItem.text);
		this.changeVisibility('categories',categoryToChange.id);
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<Grid container spacing={2} className='dashboardContainer'>
				<div className={classes.printVersion}>
					{this.state.selectedProject &&
						<div className={classes.printPage} ref={el => (this.componentToPrintRef = el)}>
							<Typography variant="h3">
								{this.state.selectedProject.name}
							</Typography>
							<Typography variant="h3">
								{this.state.selectedProject && (moment(this.state.selectedProject.date_start).format("DD/MM/YYYY") + ' - '+ moment(this.state.selectedProject.date_end).format("DD/MM/YYYY"))}
							</Typography>
							{['categories','tags'].map(entity => (
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell>{entityDisplayNames[entity]}</TableCell>
											<TableCell>Temps</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{this.sortData(entity,this.state.hoursSum[entity]).map(element => (
											<TableRow>
												<TableCell>
													<CheckboxBlank
														style={{
															color:element.color,
															marginRight:"2%",
															fontSize:"16px"
														}}
													/>
													{element.name}
												</TableCell>
												<TableCell>{element.hoursSum} h</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							))}
						</div>
					}
				</div>
				<Grid container item xs={4} spacing={2} className={classes.formCards_container}>
					<Grid item xs={12}>
						<Card className={classes.formCard}>
							<CardContent>
								<Grid container item spacing={2}>
									<Grid item xs={10}>
										<Typography variant="h2" gutterBottom>
											Reporting Projet
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<ReactToPrint
											trigger={() => (
												<Button
													variant="contained"
													color="secondary"
												>
													<Printer/>
												</Button>
											)}
											content={() => this.componentToPrintRef}
										/>
									</Grid>
								</Grid>
								<Typography variant="h3">
									{this.state.selectedProject && (moment(this.state.selectedProject.date_start).format("DD/MM/YYYY") + ' - '+ moment(this.state.selectedProject.date_end).format("DD/MM/YYYY"))}
								</Typography>
								<AutoCompleteHightlight
									selectedValue={this.state.selectedProject}
									suggestions={this.state.projectsList}
									change_handler={this.selectedProject_handler}
									errorMessage=''
									label="Projet :"
								/>
								{(this.state.selectedProject && this.state.selectedProject.client) &&
									<Typography>
										{this.state.selectedProject.client.name}
									</Typography>
								}
								{this.state.selectedProject &&
									<Typography>
										{this.state.selectedProject.description}
									</Typography>
								}
							</CardContent>
						</Card>
					</Grid>
					{this.state.chartReady &&
						<Grid item xs={12}>
							<Card className={classes.formCard}>
								<CardContent>
									<Tabs
										value={this.state.tabIndex}
										onChange={this.tabIndex_change}
										scrollButtons="off"
										variant="fullWidth"
									>
										<Tab
											icon={<FileDocument />}
											id='scrollable-prevent-tab-0'
											aria-controls='scrollable-prevent-tabpanel-0'
										/>
										<Tab icon={<ChartPie />}
											id='scrollable-prevent-tab-1'
											aria-controls='scrollable-prevent-tabpanel-1'
										/>
									</Tabs>
								</CardContent>
								<CardContent hidden={this.state.tabIndex !== 0} role="tabpanel">
									{['categories','tags'].map(entity => (
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell
														key={'visible'}
														sortDirection={this.state.orderBy[entity] === 'visible' ? this.state.orderWay[entity] : false}
														align={'left'}
													>
														<Tooltip
															title="Sort"
															placement={"bottom-end"}
															enterDelay={300}
														>
															<TableSortLabel
																active={this.state.orderBy[entity] === 'visible'}
																direction={this.state.orderWay[entity]}
																onClick={this.sortData_handler(entity,'visible')}
															>
																Visible
															</TableSortLabel>
														</Tooltip>
													</TableCell>
													<TableCell
														key={'name'}
														sortDirection={this.state.orderBy[entity] === 'name' ? this.state.orderWay[entity] : false}
													>
														<Tooltip
															title="Sort"
															placement={"bottom-end"}
															enterDelay={300}
														>
															<TableSortLabel
																active={this.state.orderBy[entity] === 'name'}
																direction={this.state.orderWay[entity]}
																onClick={this.sortData_handler(entity,'name')}
															>
																{entityDisplayNames[entity]}
															</TableSortLabel>
														</Tooltip>
													</TableCell>
													<TableCell
														key={'hoursSum'}
														sortDirection={this.state.orderBy[entity] === 'hoursSum' ? this.state.orderWay[entity] : false}
													>
														<Tooltip
															title="Sort"
															placement={"bottom-end"}
															enterDelay={300}
														>
															<TableSortLabel
																active={this.state.orderBy[entity] === 'hoursSum'}
																direction={this.state.orderWay[entity]}
																onClick={this.sortData_handler(entity,'hoursSum')}
															>
																Temps
															</TableSortLabel>
														</Tooltip>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{this.sortData(entity,this.state.hoursSum[entity]).map(element => (
													<TableRow>
														<TableCell
														>
															{element.visible ?
																<IconButton
																	size='small'
																	onClick={this.eyeClicked_handler(entity,element.id)}
																>
																	<Eye color="secondary" />
																</IconButton>
																:
																<IconButton
																	size='small'
																	onClick={this.eyeClicked_handler(entity,element.id)}
																>
																	<EyeOff color="primary" />
																</IconButton>
															}
														</TableCell>
														<TableCell>
														<CheckboxBlank
															style={{
																color:element.color,
																marginRight:"2%",
																fontSize:"16px"
															}}
														/>
															{element.name}
														</TableCell>
														<TableCell>{element.hoursSum} h</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									))}
								</CardContent>
								<CardContent hidden={this.state.tabIndex !== 1} role="tabpanel">
									<Pie
										options={{
											responsive: true,
											tooltips: {
												callbacks: {
													label: function(tooltipItem, data) {
														return(
															data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]+" h");
													}
												}
											},
											legend:{
												onClick: this.categoryPieLegendClicked
											}
										}}
										data={{
											labels: this.state.hoursSum.categories.map(category => category.name),
											datasets: [{
												data:this.state.hoursSum.categories.map(category => category.visible ? category.hoursSum:undefined),
												backgroundColor:this.state.hoursSum.categories.map(category => category.color)
											}]

										}}
									/>

								</CardContent>
							</Card>

						</Grid>
					}
				</Grid>
				<Grid item xs={8} className={classes.chartContainer} ref={el => (this.chartToPrintRef = el)}>
					<div className={classes.chartToolbar}>
						<Typography variant='h2' className={classes.chartTitle}>
							{moment(this.state.calendarRange.from).format("DD/MM/YYYY")} - {moment(this.state.calendarRange.to).format("DD/MM/YYYY")} : {Math.ceil(this.state.totalPeriodHours/7)} j
						</Typography>
						<ReactToPrint
							trigger={() => (
								<Button
									variant="contained"
									color="secondary"
									className={classes.noPrint}
								>
									<Printer/>
								</Button>
							)}
							content={() => this.chartToPrintRef}
						/>
					</div>
					{this.state.chartReady && (
						<Grid item xs={12} className={classes.chartContainer}>
							<Line
								style={this.state.printChart&&{width:this.state.chartWidth}}
								options={{
									responsive: true,
									annotation: {
										annotations: [
											{
												type: "line",
												borderDash: [2, 2],
												mode: "horizontal",
												scaleID: "y-axis-0",
												value: (this.state.selectedProject.daysCount*7),
												borderColor: "grey",
												borderWidth: 2,
												label: {
													backgroundColor: "red",
													content: this.state.selectedProject.daysCount+" j",
													enabled: true
												}
											},{
												type: "line",
												borderDash: [2, 2],
												mode: "vertical",
												scaleID: "x-axis-0",
												value: (moment(this.state.selectedProject.date_end).format("MM/YYYY")),
												borderColor: "grey",
												borderWidth: 2,
												label: {
													backgroundColor: "red",
													content: moment(this.state.selectedProject.date_end).format("MM/YYYY"),
													enabled: true
												}
											}
										]
									},
									maintainAspectRatio: true,
									tooltips: {
										callbacks: {
											label: function(tooltipItem, data) {
												return(
													data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y+" h - "+
													data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].hours+" h");
											}
										}
									},
									scales: {
										yAxes: [{
											scaleLabel: {
												display:true,
												labelString: "Heures (h)"
											}
										}],
										xAxes: [{
											scaleLabel: {
												display:true,
												labelString: "Mois"
											}
										}]
									}
								}}
								data={{
									labels: this.state.chartXLabels,
									datasets: [
										this.state.userChart
									]
								}}
							/>

						</Grid>
					)}
				</Grid>

			</Grid>

		);
	}
}

ReportingProjectsOneProjectChart.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ReportingProjectsOneProjectChart);
