import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Card,
    CardContent
} from '@material-ui/core';

class ColorPreviewer extends React.Component {

    render() {
        return (
            <Card>
                <CardContent
                    style={{backgroundColor: this.props.color}}
                ></CardContent>
            </Card>
        );
    }
}

ColorPreviewer.propTypes = {
    color: PropTypes.string.isRequired
};
export default withStyles({},{withTheme: true})(ColorPreviewer);
