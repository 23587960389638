import React from 'react';
import PropTypes from 'prop-types';

import GenericTimespentDialog from 'components/Timespents/GenericTimespentDialog.jsx';


import {
	withStyles,
	Button,
} from "@material-ui/core";

import {
	Plus,
} from "mdi-material-ui"

const hours_limit= {
	min: 0.5,
	max: 24,
	step: 0.5
};

const style_override = theme => ({
	leftIcon: {
		marginRight: theme.spacing(),
	}
});

class AddTimespentDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedCategory: undefined,
			selectedProject: undefined,
			selectedTags: [],
			hours: hours_limit.min,
			hours_errorMessage: '',
			category_errorMessage: '',
		};
	}

	//======================= UI Callback ========================

	add_handler = (timespent) => {
		this.setState({
			selectedCategory: undefined,
			selectedProject: undefined,
			selectedTags: [],
			hours: hours_limit.min,
			hours_errorMessage: '',
			category_errorMessage: '',
			project_errorMessage: '',
		});
		this.props.addHandler(timespent);
	}

	closeFromDialog_handler = () => {
		this.add_handler();
	}

	dialogEnter_handler = () => {
		this.setState({
			selectedCategory: undefined,
			selectedProject: undefined,
			selectedTags: [],
			hours: hours_limit.min,
			hours_errorMessage: '',
			category_errorMessage: '',
			project_errorMessage: '',
		});
	}

	addTimespent_handler = (event) => {
		let hours = this.state.hours;
		let category = this.state.selectedCategory;
		let project = this.state.selectedProject;
		if(hours < hours_limit.min || hours > hours_limit.max || (hours % hours_limit.step !== 0)){
			this.setState({hours_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else if(project === undefined){
			this.setState({oroject_errorMessage: "Vous devez sélectionner un projet"});
		}else if(category === undefined){
			this.setState({category_errorMessage: "Vous devez sélectionner une catégorie"});
		}else{
			this.props.apiControllers.timespents.create({
				userId: this.props.user.id,
				projectId: project.id,
				categoryId: category.id,
				hours: hours,
				description:'',
				tags:this.state.selectedTags.map(tag => tag.id)
			},{
				auth_username:this.props.user.username,
				auth_token:this.props.user.token
			}).then((response) => {
				this.props.notifier.success('Temps créé');
				this.update_handler(response.data);
			}).catch((error) => {
				this.props.notifier.error(error.data);
			});
		}
	};

    projectChange_handler = (project) => {
		this.setState({
            selectedProject: project,
            selectedCategory: undefined,
			selectedTags: [],
			category_errorMessage: '',
			project_errorMessage: ''});
	}

	hoursChange_handler = (event) => {
		this.setState({hours: event.target.value});
		let value = event.target.value;
		if(value < hours_limit.min || value > hours_limit.max || (value % hours_limit.step !== 0)){
			this.setState({hours_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else{
			this.setState({hours_errorMessage: ''});
		}
	}

	categoryChange_handler = (category) => {
		this.setState({
			selectedCategory: category,
			selectedTags: [],
			category_errorMessage: ''});
	}

	tagsChange_handler = (tags) => {
		this.setState({
			selectedTags: tags
		})
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<GenericTimespentDialog
				date={this.props.date}
				dialogEnter_handler={this.dialogEnter_handler}
				title={"Ajouter un temps"}
				closeDialog={this.closeFromDialog_handler}
				apiControllers={this.props.apiControllers}
				open={this.props.open}
				logger={this.props.logger}
				user={this.props.user}
				notifier={this.props.notifier}
				checkAuthentication={this.props.checkAuthentication}
				actions={(
					<React.Fragment>
						<Button
							onClick={this.addTimespent_handler}
							color="secondary"
							className={classes.button}
							variant="contained"
						>
							<Plus className={classes.leftIcon} />
							Ajouter
						</Button>
					</React.Fragment>
				)}

				hoursChange_handler={this.hoursChange_handler}
				projectChange_handler={this.projectChange_handler}
				categoryChange_handler={this.categoryChange_handler}
				tagsChange_handler={this.tagsChange_handler}
				selectedHours={this.state.hours}
				selectedProject={this.state.selectedProject}
				selectedCategory={this.state.selectedCategory}
				selectedTags={this.state.selectedTags}
				hours_errorMessage={this.state.hours_errorMessage}
				project_errorMessage={this.state.project_errorMessage}
				category_errorMessage={this.state.category_errorMessage}
			/>
		);
	}
}

AddTimespentDialog.propTypes = {
	updateHandler: PropTypes.func.isRequired,
	deleteHandler: PropTypes.func.isRequired,
	apiControllers: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	logger: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	date: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
};

export default withStyles(style_override,{withTheme: true})(AddTimespentDialog);
