import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import CryptoJS from 'crypto-js';

import Select, {components} from 'react-select';
import {Grid,
    Card,
    CardContent,
    CardHeader,
    TextField,
    Button,
    Typography
} from '@material-ui/core';

import{
    Plus
} from 'mdi-material-ui';

import {
    MuiPickersUtilsProvider,
    DatePicker
} from '@material-ui/pickers';

import moment from "moment";
import "moment/locale/fr";
import MomentUtils from '@date-io/moment';

import RightsTable from "components/Users/RightsTable.jsx";
import HoursConfigurationForm from "components/Users/HoursConfigurationForm.jsx"
import DaysConfigurationForm from "components/Users/DaysConfigurationForm.jsx"
import { dateFormat, contractTypes } from 'variables/general.jsx';


moment.locale("fr");

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    creation:{
        backgroundColor: theme.button.creation,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    contractsContainer:{
		overflow:"visible"
	},
});

const basicRights = {
    users:{
        viewDisabled: false,
        viewSystem: false,
        createUpdate: false,
        disable: false
    },
    projects:{
        viewDisabled: false,
        viewSystem: false,
        createUpdate: false,
        disable: false
    },
    categories:{
        viewDisabled: false,
        viewSystem: false,
        createUpdate: false,
        disable: false
    },
    tags:{
        viewDisabled: false,
        viewSystem: false,
        createUpdate: false,
        disable: false
    },
    events:{
        viewDisabled: false,
        viewSystem: false,
        createUpdate: false,
        disable: false
    },
    clients:{
        viewDisabled: false,
        viewSystem: false,
        createUpdate: false,
        disable: false,
        viewDashboard: false
    },
    logsView:false,
    timespents:{
        users:{
            view:{
                self:true,
                others:false
            },
            createUpdate:{
                self: true,
                others: false
            },
        },
        absences:{
            view:{
                self:true,
                others:false
            },
            createUpdate:{
                self: false,
                others: false
            },
        }
    }
}

const defaultHoursConfiguration =  {
    hours:{
        type:'hours',
        days:{
            monday: 7,
            tuesday: 7,
            wednesday: 7,
            thursday: 7,
            friday: 7,
            saturday: 0,
            sunday:0
        }
    },
    days:{
        type:'days',
        daysPerYear:210
    }
};

class UserCreation extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            joiningDate: moment(new Date()).format(dateFormat),
            date_start: moment(new Date()).format(dateFormat),
            displayname: "",
            username: "",
            firstPassword: "",
            secondPassword: "",
			username_errorMessage: "",
			displayname_errorMessage: "",
            firstPassword_errorMessage: "",
            secondPassword_errorMessage: "",
            adminPermission: basicRights,
            hoursConfiguration: defaultHoursConfiguration.hours,
            hoursDays_errorMessage:{
                monday:'',
                tuesday:'',
                wednesday:'',
                thursday:'',
                friday:'',
                saturday:'',
                sunday:'',
                daysPerYear:''
            }
        };
        this.creationRight = this.props.user.adminPermission.users.createUpdate;

        if(!this.creationRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    //======================= Tool Functions ========================

    checkHoursConfiguration = () => {
        if(this.state.hoursConfiguration.type==='hours'){
            return this.checkHoursConfigurationHours();
        }else{
            return this.checkHoursConfigurationDays();
        }
    }

    checkHoursConfigurationHours = () => {
        let res = true;
        let messages = {
            monday:'',
            tuesday:'',
            wednesday:'',
            thursday:'',
            friday:'',
            saturday:'',
            sunday:'',
            daysPerYear:''
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.monday)) || this.state.hoursConfiguration.days.monday < 0 || this.state.hoursConfiguration.days.monday > 12 || (this.state.hoursConfiguration.days.monday % 0.5) !== 0){
            messages.monday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.tuesday)) || this.state.hoursConfiguration.days.tuesday < 0 || this.state.hoursConfiguration.days.tuesday > 12 || (this.state.hoursConfiguration.days.tuesday % 0.5) !== 0){
            messages.tuesday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.wednesday)) || this.state.hoursConfiguration.days.wednesday < 0 || this.state.hoursConfiguration.days.wednesday > 12 || (this.state.hoursConfiguration.days.wednesday % 0.5) !== 0){
            messages.wednesday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.thursday)) || this.state.hoursConfiguration.days.thursday < 0 || this.state.hoursConfiguration.days.thursday > 12 || (this.state.hoursConfiguration.days.thursday % 0.5) !== 0){
            messages.thursday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.friday)) || this.state.hoursConfiguration.days.friday < 0 || this.state.hoursConfiguration.days.friday > 12 || (this.state.hoursConfiguration.days.friday % 0.5) !== 0){
            messages.friday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.saturday)) || this.state.hoursConfiguration.days.saturday < 0 || this.state.hoursConfiguration.days.saturday > 12 || (this.state.hoursConfiguration.days.saturday % 0.5) !== 0){
            messages.saturday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.days.sunday)) || this.state.hoursConfiguration.days.sunday < 0 || this.state.hoursConfiguration.days.sunday > 12 || (this.state.hoursConfiguration.days.sunday % 0.5) !== 0){
            messages.sunday = 'Le champ doit être compris entre 0 et 12, par pas de 0.5';
            res = false;
        }
        this.setState({hoursDays_errorMessage:messages});
        return res;
    }

    checkHoursConfigurationDays = () => {
        let res = true;
        let messages = {
            monday:'',
            tuesday:'',
            wednesday:'',
            thursday:'',
            friday:'',
            saturday:'',
            sunday:'',
            daysPerYear:''
        }
        if(isNaN(parseInt(this.state.hoursConfiguration.daysPerYear))){
            messages.monday = 'Le champ doit être compris entre 0 et 365, par pas de 1';
            res = false;
        }
        this.setState({hoursDays_errorMessage:messages});
        return res;
    }
    //======================= UI Callback ========================

    create_click = () => {
        if(this.state.displayname === ""){
            this.setState({displayname_errorMessage: "Le champ ne peut être vide"});
        } else if (this.state.username === ""){
            this.setState({username_errorMessage: "Le champ ne peut être vide"});
        } else if (this.state.firstPassword === ""){
            this.setState({firstPassword_errorMessage: "Le champ ne peut être vide"});
        } else if (this.state.secondPassword === ""){
            this.setState({secondPassword_errorMessage: "Le champ ne peut être vide"});
        } else if (this.state.firstPassword !== this.state.secondPassword){
            this.setState({secondPassword_errorMessage: "Les mots de passe ne correspondent pas"});
        } else if (this.checkHoursConfiguration()) {
            let hash = CryptoJS.SHA3(this.state.firstPassword).toString();
            this.props.apiControllers.users.create({
                    displayname: this.state.displayname,
                    username: this.state.username,
                    password: hash,
                    adminPermission: this.state.adminPermission,
                    hoursConfiguration: this.state.hoursConfiguration,
                    joiningDate: moment(this.state.joiningDate).format("YYYY-MM-DD"),
                    date_start: moment(this.state.date_start).format("YYYY-MM-DD"),
                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }).then((response) => {
                    this.props.notifier.success('Utilisateur enregistré');
                    this.props.history.goBack();
                }).catch((error) => {
                    this.props.notifier.error(error.data);
                });
        }
    }

    displayname_change = (event) => {
        this.setState({
            displayname: event.target.value,
            displayname_errorMessage: ""
        });
    }

    username_change = (event) => {
        this.setState({
            username: event.target.value,
            username_errorMessage: ""
        });
    }

    joiningDate_change = (date) => {
        this.setState({
            joiningDate: date,
        })
    }

    date_start_change = (date) => {
        this.setState({
            date_start: date,
        })
    }

    firstPasswordTextChange_handler = (event) => {
        this.setState({
            firstPassword: event.target.value,
            firstPassword_errorMessage: ""
        })
    }
    secondPasswordTextChange_handler = (event) => {
        this.setState({
            secondPassword: event.target.value,
            secondPassword_errorMessage: ""
        })
    }

    changePermissions_handler = (adminPermission) => {
        this.setState({
            adminPermission: adminPermission
        });
    }

    setHoursConfigurationHours_handler = (hoursConfiguration) => {
        this.setState({
            hoursConfiguration: hoursConfiguration,
            hoursDays_errorMessage: {
                monday:'',
                tuesday:'',
                wednesday:'',
                thursday:'',
                friday:'',
                saturday:'',
                sunday:'',
                daysPerYear:''
            }
        },this.checkHoursConfigurationHours);
    }

    setHoursConfigurationDays_handler = (hoursConfiguration) => {
        this.setState({
            hoursConfiguration: hoursConfiguration,
            hoursDays_errorMessage: {
                monday:'',
                tuesday:'',
                wednesday:'',
                thursday:'',
                friday:'',
                saturday:'',
                sunday:'',
                daysPerYear:''
            }
        },this.checkHoursConfigurationDays);
    }

    contractTypeChange_handler = (type) => {
        let hoursConfiguration = this.state.hoursConfiguration;
        if(hoursConfiguration.type !== type.value){
            this.setState({
                hoursConfiguration:Object.assign(defaultHoursConfiguration[type.value])
            });
        }
    }

    //======================= Page render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Création d'un utilisateur"}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="displayname-input"
                                            label="Nom affiché"
                                            value={this.state.displayname}
                                            onChange={this.displayname_change}
                                            margin="normal"
                                            error={this.state.displayname_errorMessage !== "" ? true : false}
                                            helperText={this.state.displayname_errorMessage}
                                            />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            id="username-input"
                                            label="Nom d'utilisateur"
                                            value={this.state.username}
                                            onChange={this.username_change}
                                            margin="normal"
                                            error={this.state.username_errorMessage !== "" ? true : false}
                                            helperText={this.state.username_errorMessage}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            error={this.state.firstPassword_errorMessage !== "" ? true : false}
                                            helperText={this.state.firstPassword_errorMessage}
                                            id="firstPassword-input"
                                            label="Mot de passe"
                                            type="password"
                                            value={this.state.firstPassword}
                                            onChange={this.firstPasswordTextChange_handler}
                                            margin="normal"
                                            />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            error={this.state.secondPassword_errorMessage !== "" ? true : false}
                                            helperText={this.state.secondPassword_errorMessage}
                                            id="secondPassword-input"
                                            label="Confirmer mot de passe"
                                            type="password"
                                            value={this.state.secondPassword}
                                            onChange={this.secondPasswordTextChange_handler}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid item xs={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <DatePicker
                                                margin="normal"
                                                label="Date d'entrée"
                                                value={this.state.joiningDate}
                                                onChange={this.joiningDate_change}
                                                format="DD/MM/YYYY"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card className={classes.contractsContainer}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h3">
                                        Contrats
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            margin="normal"
                                            label="Date de début"
                                            value={this.state.date_start}
                                            onChange={this.date_start_change}
                                            format="DD/MM/YYYY"
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Grid container spacing={8}>
                                <Grid item xs={2}>
                                    <React.Fragment>
                                        <Typography variant="body1" >
                                            Type de contrat
                                        </Typography>
                                        <Select
                                            isMulti={false}
                                            options={[{value:'hours', label:contractTypes.hours},{value:'days', label:contractTypes.days}]}
                                            getOptionValue={(option) => (option.value)}
                                            getOptionLabel={(option) => (option.label)
                                            }
                                            value={{value:this.state.hoursConfiguration.type,label:contractTypes[this.state.hoursConfiguration.type]}}
                                            onChange={this.contractTypeChange_handler}
                                        />
                                    </React.Fragment>
                                </Grid>
                                <Grid item xs={10}>
                                    {this.state.hoursConfiguration.type === 'hours' ?
                                        <HoursConfigurationForm
                                            hoursConfiguration={this.state.hoursConfiguration}
                                            setHoursConfiguration_handler={this.setHoursConfigurationHours_handler}
                                            hoursDays_errorMessage={this.state.hoursDays_errorMessage}
                                        />
                                    :
                                        <DaysConfigurationForm
                                            hoursConfiguration={this.state.hoursConfiguration}
                                            setHoursConfiguration_handler={this.setHoursConfigurationDays_handler}
                                            hoursDays_errorMessage={this.state.hoursDays_errorMessage}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography variant="h3">
                                        Droits
                                    </Typography>
                                </Grid>
                            </Grid>
                            <RightsTable
                                adminPermission={this.state.adminPermission}
                                changePermissions_handler={this.changePermissions_handler}
                            />
                            <Grid item xs={12}>
                                <Button
                                    onClick={this.create_click}
                                    className={classNames(classes.button,classes.creation)}
                                    variant="contained"
                                >
                                    <Plus className={classes.leftIcon} />
                                    Création
                                </Button>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

UserCreation.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(UserCreation);
