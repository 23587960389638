import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import {Grid,
    Card,
    CardContent,
    CardHeader,
    Button} from '@material-ui/core';

import{
    ContentSave,
} from 'mdi-material-ui';
import BasicTagForm from 'components/Tags/BasicTagForm.jsx';

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    update:{
        backgroundColor: theme.button.update,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
});

class TagViewOne extends React.Component {

    constructor(props) {
		super(props);
		this.state = {
            name_fixed: "",
            name: "",
            color: "",
			name_errorMessage: "",
        };

        this.updateRight = this.props.user.adminPermission.tags.createUpdate;
        if(!this.updateRight){
            this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    componentDidMount = () => {
		this.props.apiControllers.tags.getById(this.props.match.params.tagId,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token}).then((response)=>{
                this.setState({
                    name_fixed: response.data.name,
                    name: response.data.name,
                    color: response.data.color,
                });
            }).catch((error) => {
                if(error.status === 403 || error.status === 404 || error.status === 400){
                    this.props.notifier.error('Accès interdit');
                    this.props.history.push('/');
                }else{
                    this.props.checkAuthentication();
                }
            });
    }

    //======================= UI Callback ========================

    update_click = () => {
        if(this.state.name === ""){
            this.setState({name_errorMessage: "Le champ ne peut être vide"});
        } else {
            this.props.apiControllers.tags.update(
                this.props.match.params.tagId,
                {
                    name: this.state.name,
                    color: this.state.color,
                },{
                    auth_username:this.props.user.username,
                    auth_token:this.props.user.token
                }).then((response) => {
                    this.props.notifier.success('Tag correctement modifié');
                    this.props.updateInnerUser()
                        .then(() => {this.props.history.goBack()});
                }).catch((error) => {
                    this.props.notifier.error(error.data);
                });
        }
    }

    name_change = (event) => {
        this.setState({
            name: event.target.value,
            name_errorMessage: ""
        });
    }

    color_change = (color) => {
        this.setState({ color: color.hex });
    };

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Modification du tag "+this.state.name_fixed}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid container item xs={12}>
                                    <BasicTagForm
                                        name={this.state.name}
                                        color={this.state.color}
                                        name_errorMessage={this.state.name_errorMessage}
                                        name_change={this.name_change}
                                        color_change={this.color_change}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        onClick={this.update_click}
                                        className={classNames(classes.button,classes.update)}
                                        variant="contained"
                                    >
                                        <ContentSave className={classes.leftIcon} />
                                        Enregistrer les modifications
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

TagViewOne.propTypes = {
    classes: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles,{withTheme: true})(TagViewOne);
