import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import TimespentsCalendar from 'components/Timespents/TimespentsCalendar.jsx';
import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';
import AddTimespentForm from 'components/Timespents/AddTimespentForm.jsx';
import AddAbsenceForm from 'components/Timespents/AddAbsenceForm.jsx';

import moment from 'moment';

import TimespentsFetchers from 'utils/timespentsFetchers.js';

// import './ReportingUsersOneUserCalendar.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import {dateFormat} from 'variables/general.jsx';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
	Card,
	CardContent,
	Typography,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from '@material-ui/core';



import {
	ChevronDown,
} from 'mdi-material-ui';

const dayNumberToName = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
]

const absenceDays_limit= {
	min: 0.5,
	max: 30,
	step: 0.5
};
const styles = theme => ({
	addTimespentExpandContent:{
		display:'block',
		overflow:"visible",
		"&>*":{
			margin:"2% 0"
		}
	},
	calendarContainer:{
		paddingBottom:"0!important"
	},
	formCards_container:{
		display:'block'
	},
	selectUserCard:{
		overflow:'visible',
		width:"100%"

	},
});

const timespentsFetchers = new TimespentsFetchers();

class ReportingUsersOneUserCalendar extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			usersList:[],
			clientsList:[],
			projectsList: [],
			selectedUser:null,
			timespents: [],
			events:[],
			calendarRange:{
				from:new Date(moment().startOf('month')),
				to:new Date(moment().endOf('month'))
			},
			updateTimespentDialog_state: false,
			selectedDate: moment(),
			updateTimespent_id:-1,
			absenceDays: 0,
			absenceDays_errorMessage:"",
			showAddTime:true,
			currentMonthHours:0,
			shouldWorkMonthHours:0,
			hideHoursSum:false
		};
		this.alreadyFetchedRanges = [];

		this.viewRight = this.props.user.adminPermission.timespents.users.view.others;
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
		this.absenceViewRight = this.props.user.adminPermission.timespents.absences.view.others;
		this.updateRight = this.props.user.adminPermission.timespents.users.createUpdate.others;
		this.absenceUpdateRight = this.props.user.adminPermission.timespents.absences.createUpdate.others;
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	componentDidMount = () => {
		this.getProjectList()
		.then(() => this.getClientList())
		.then(() => this.getUsers())
		.then(()=> this.getEvents());
	}

    //======================= Tool Funtions ========================

	getProjectList = () => {
		return this.props.apiControllers.projects.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({projectsList: response.data});
			return true;
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getClientList = () => {
		return this.props.apiControllers.clients.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({clientsList: response.data});
			return true;
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}


	getEvents = () => {
		return this.props.apiControllers.events.getAll(
			undefined,
			undefined,
			{
				auth_username:this.props.user.username,
				auth_token:this.props.user.token,
		}).then((response)=>{
			let events = response.data;
            return this.setState({events: events});
		}).catch((error) => {
			if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
		});
	}

	getUsers = () => {
		return this.props.apiControllers.users.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			let usersWithNames = response.data.map(user => ({
				...user,
				name:user.displayname
			}));
			this.setState({usersList: usersWithNames});
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getThisMonthTimespents = () => {
		if(this.state.selectedUser){
			return this.props.loadingDialog.open().then(()=>{
				setTimeout(()=>{
					let start_date = moment(this.state.calendarRange.from).format(dateFormat);
					let end_date = moment(this.state.calendarRange.to).format(dateFormat);
					return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, this.alreadyFetchedRanges, this.props.apiControllers.timespents, this.props.user,
						this.state.selectedUser.id, 'users', true, this.props.logger).then((timespents)=> {
						let timespentsBase = this.state.timespents;
						let timespentsToAdd = timespents;
						if(timespentsToAdd !== undefined){
							for(let timespent of timespentsToAdd){
								if(timespent.project.id !== 1 || this.absenceViewRight){
									timespentsBase.push(timespent);
								}
							}
						}
						return this.promisedSetState({timespents: timespentsBase}).then(()=>{
							this.computeTotalHours();
							this.computeShouldWorkCurrentMonth(this.state.timespents.filter(timespent => (
								moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]'))
							));
							this.props.loadingDialog.close();
						});

					}).catch((error) => {
						this.props.loadingDialog.close();
						if(error.status === 403){
							this.props.notifier.error('Accès interdit');
							this.props.history.push('/');
						}else{
							this.props.checkAuthentication();
						}
					});
				},10);
			});
		}
	}

	computeTotalHours = () =>{
		let totalHours = 0;
		this.state.timespents.map(timespent => {
			let selectedContract = this.state.selectedUser.userContracts.find(contract => (moment(contract.date_start).isSameOrBefore(timespent.date)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(timespent.date)));
			if(selectedContract.hoursConfiguration.type === 'hours'){
				if((timespent.project.id !== 1 || timespent.category.id === 9) && moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]')){
					totalHours+=timespent.hours;
				}
			}
			return timespent;
		});
		this.setState({currentMonthHours:totalHours});
	}

	computeShouldWorkCurrentMonth = (timespents) => {
		let hideHoursSum = true;
		let shouldWorkMonthHours = 0;
		let currentDate = moment(this.state.calendarRange.from);
		let toLimit = this.state.calendarRange.to;
		if(moment(new Date()).isAfter(this.state.calendarRange.from) && moment(new Date()).isBefore(this.state.calendarRange.to)){
			toLimit = moment(new Date());
		}
		while(currentDate.isSameOrBefore(toLimit, 'day')){
			let selectedContract = this.state.selectedUser.userContracts.find(contract => (moment(contract.date_start).isSameOrBefore(currentDate)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(currentDate)));
			if(selectedContract.hoursConfiguration.type === 'hours'){
				hideHoursSum = false;
				let shouldWork = selectedContract.hoursConfiguration.days[dayNumberToName[moment(currentDate).day()]];

				let todayTimespents = timespents.filter(timespent => currentDate.isSame(timespent.date,'day'));

				for(let currentTimespent of todayTimespents){
					// If it was an absence and not a recovery or study
					if(currentTimespent.project.id === 1 && (currentTimespent.category.id !== 2 && currentTimespent.category.id !== 9 && currentTimespent.category.id !== 10)){
						shouldWork -= Math.min(shouldWork, currentTimespent.hours);
					}
				}

				// If the current date is a blocking event
				let todayBlockingEvent = this.state.events.find(event => (event.blocking && currentDate.isSame(event.date,'day')));

				if(todayBlockingEvent !== undefined){
					shouldWork = 0;
				}
				shouldWorkMonthHours += (shouldWork > 0 ? shouldWork : 0);
			}
			currentDate.add(1,'day');
		}
		this.setState({shouldWorkMonthHours:shouldWorkMonthHours, hideHoursSum:hideHoursSum});
	}

	addLabelValuesToArray = (array) => {
		return array.map(project => ({
			...project,
			label:project.name,
			value:project.id
		}));
	}

    //======================= UI Callback ========================

	calendarRangeChange_handler = (dateRange) => {
		this.setState({calendarRange:dateRange},
			this.getThisMonthTimespents);
	}

	calendarDateChange_handler = (date) => {
		this.setState({selectedDate: date});
	}

	addTimespent_handler = (timespent) => {
		if(timespent !== undefined){
			if(Array.isArray(timespent)){
				for(let i in timespent){
					this.addTimespentToCalendar(timespent[i]);
				}
			}else{
				this.addTimespentToCalendar(timespent);
			}
		}
	}

	addTimespentToCalendar = (timespent) => {
		if(timespentsFetchers.rangeAlreadyFetched(timespent.date, timespent.date, this.alreadyFetchedRanges, this.props.logger)){
			let timespentsBase = this.state.timespents;
			timespentsBase.push(timespent);
			this.setState({timespents:timespentsBase});
			this.getThisMonthTimespents();
		}
	}

	updateTimespent_handler = (timespent) => {
		if(timespent !== undefined){
			let timespentsBase = this.state.timespents;
			let index = timespentsBase.indexOf(timespentsBase.find(el => el.id === timespent.id));
			timespentsBase[index] = timespent;
			this.setState({timespents:timespentsBase});
			this.getThisMonthTimespents();
		}
	}

	deleteTimespent_handler = (timespent) => {
		if(timespent !== undefined){
			let timespentsBase = this.state.timespents;
			let timespentIndex = timespentsBase.indexOf(timespentsBase.find(el => el.id === timespent.id));
			if(timespentIndex >= 0){
				timespentsBase.splice(timespentIndex,1);
			}
			this.setState({timespents:timespentsBase});
			this.getThisMonthTimespents();
		}
	}

	absenceDaysChange_handler = (event) => {
		this.setState({absenceDays: event.target.value});
		let value = event.target.value;
		if(value < absenceDays_limit.min || value > absenceDays_limit.max || (value % absenceDays_limit.step !== 0)){
			this.setState({absenceDays_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else{
			this.setState({absenceDays_errorMessage: ''});
		}
	}

	selectedUser_handler = (selectedUser) => {
		this.alreadyFetchedRanges = [];
		this.setState({
			selectedUser: selectedUser,
			timespents:[],
		}, this.getThisMonthTimespents)
	}

	categoryChange_handler = (selectedCategory) => {
		this.setState({
			form_selectedCategory: selectedCategory,
			form_selectedTags:[],
			form_category_errorMessage: '',
		});
	}

	changeShowAddTime = () => {
		this.setState({
			showAddTime: !this.state.showAddTime
		});
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<Grid container spacing={2}  className='dashboardContainer'>
				<Grid container item xs={4} spacing={2} className={classes.formCards_container}>
					<Grid container item xs={12} className={classes.selectUserCard_container}>
						<Card className={classes.selectUserCard}>
							<CardContent>
								<Typography variant="h2" gutterBottom>
									Reporting Utilisateur
								</Typography>
								<AutoCompleteHightlight
									suggestions={this.state.usersList}
									change_handler={this.selectedUser_handler}
									errorMessage=''
									label="Utilisateur :"
								/>
							</CardContent>
						</Card>
					</Grid>
					{this.state.selectedUser&&
					<React.Fragment>
						{this.updateRight&&
							<Grid item xs={12} className={classes.addTimespentCard_container}>
								<ExpansionPanel
									expanded={this.state.showAddTime}
									onChange={this.changeShowAddTime}
								>
									<ExpansionPanelSummary expandIcon={<ChevronDown />}>
										<Typography variant="h2">Ajouter un temps</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails className={classes.addTimespentExpandContent}>
										<AddTimespentForm
											addTimespent_handler={this.addTimespent_handler}
											projectsList={this.state.projectsList}
											clientsList={this.state.clientsList}
											user={this.state.selectedUser}
											auth_user={this.props.user}
											userManager={this.props.userManager}
											notifier={this.props.notifier}
											logger={this.props.logger}
											apiControllers={this.props.apiControllers}
											checkAuthentication={this.props.checkAuthentication}
											selectedDate={this.state.selectedDate}
										/>
									</ExpansionPanelDetails>
								</ExpansionPanel>
							</Grid>
						}
						{this.absenceUpdateRight&&
							<Grid item xs={12} className={classes.addAbsenceCard_container}>
								<ExpansionPanel
									expanded={!this.state.showAddTime}
									onChange={this.changeShowAddTime}
								>
									<ExpansionPanelSummary expandIcon={<ChevronDown />}>
										<Typography variant="h2">Ajouter une absence</Typography>
									</ExpansionPanelSummary>
									<ExpansionPanelDetails className={classes.addTimespentExpandContent}>
										<AddAbsenceForm
											addTimespent_handler={this.addTimespent_handler}
											user={this.state.selectedUser}
											auth_user={this.props.user}
											userManager={this.props.userManager}
											notifier={this.props.notifier}
											logger={this.props.logger}
											apiControllers={this.props.apiControllers}
											checkAuthentication={this.props.checkAuthentication}
											selectedDate={this.state.selectedDate}
											loadingDialog={this.props.loadingDialog}
										/>
									</ExpansionPanelDetails>
								</ExpansionPanel>
							</Grid>
						}
					</React.Fragment>
					}
				</Grid>
				<Grid item xs={8} className={classes.calendarContainer}>
					<TimespentsCalendar
						displayAbsences={this.absenceViewRight}
						daySum={true}
						accessor="project"
						selectable={true}
						enableUpdateTimespent={this.updateRight}
						enableUpdateAbsence={this.absenceUpdateRight}
						rangeChange_handler={this.calendarRangeChange_handler}
						dateChange_handler={this.calendarDateChange_handler}
						updateTimespent_handler={this.updateTimespent_handler}
						deleteTimespent_handler={this.deleteTimespent_handler}
						timespents={this.state.timespents}
						apiControllers={this.props.apiControllers}
						notifier={this.props.notifier}
						logger={this.props.logger}
						user={this.props.user}
						userToDisplay={this.state.selectedUser}
						checkAuthentication={this.props.checkAuthentication}
						loadingDialog={this.props.loadingDialog}
						workedMonthHours={this.state.currentMonthHours}
						shouldWorkedMonthHours={this.state.shouldWorkMonthHours}
						contracts={this.state.selectedUser && this.state.selectedUser.userContracts}
						hideHoursSum={this.state.hideHoursSum}
					/>
				</Grid>
			</Grid>

		);
	}
}

ReportingUsersOneUserCalendar.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ReportingUsersOneUserCalendar);
