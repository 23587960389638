import axios from 'axios';

// const BASE_URL = "http://big-brother.carbonbee-dev.fr:"+process.env.REACT_APP_HTTP_PORT+"/";
// const BASE_URL = window.location.protocol+'//'+window.location.hostname+':'+process.env.REACT_APP_HTTP_PORT+'/';
// const BASE_URL = window.location.origin+'/';
// const BASE_URL = 'http://localhost:1362/';
const BASE_URL = window.location.protocol + '//' + window.location.hostname + '/';
console.log(BASE_URL);

class ConnectionManager {
	constructor(logger) {
		this.logger = logger;
	}

	get = (url, query_parameters) => {
		return axios
			.get(BASE_URL + url, { params: query_parameters })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				this.logger.info(error.response);
				throw error.response;
			});
	};

	post(url, body_parameters, query_parameters) {
		return axios
			.post(BASE_URL + url, body_parameters, { params: query_parameters })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				this.logger.info(error.response);
				throw error.response;
			});
	}

	put(url, body_parameters, query_parameters) {
		return axios
			.put(BASE_URL + url, body_parameters, { params: query_parameters })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				this.logger.info(error.response);
				throw error.response;
			});
	}

	delete(url, body_parameters, query_parameters) {
		return axios
			.delete(BASE_URL + url, { params: query_parameters })
			.then((response) => {
				return response;
			})
			.catch((error) => {
				this.logger.info(error.response);
				throw error.response;
			});
	}
}

export default ConnectionManager;
