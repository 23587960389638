import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Grid,
    TextField,
    InputAdornment,
    Typography
} from '@material-ui/core';

const styles = theme => ({
});

class HoursConfigurationForm extends React.Component {

	//======================= UI Callback ========================
    hoursDays_change = (day) => (event) => {
        let hours = isNaN(parseFloat(event.target.value)) ? event.target.value : parseFloat(event.target.value);
        let hoursConfiguration = {...this.props.hoursConfiguration};
        hoursConfiguration.days[day] = hours;
        this.props.setHoursConfiguration_handler(hoursConfiguration);
    }

    //======================= Page Render ========================

    render() {
        return (
            (this.props.hoursConfiguration ?
                <Grid container>
                    <Grid container item xs={9} spacing={4} alignItems="center">
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-monday"
                                label="Lundi"
                                value={this.props.hoursConfiguration.days.monday}
                                onChange={this.hoursDays_change('monday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.monday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.monday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-tuesday"
                                label="Mardi"
                                value={this.props.hoursConfiguration.days.tuesday}
                                onChange={this.hoursDays_change('tuesday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.tuesday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.tuesday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-wednesday"
                                label="Mercredi"
                                value={this.props.hoursConfiguration.days.wednesday}
                                onChange={this.hoursDays_change('wednesday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.wednesday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.wednesday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-thursday"
                                label="Jeudi"
                                value={this.props.hoursConfiguration.days.thursday}
                                onChange={this.hoursDays_change('thursday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.thursday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.thursday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-friday"
                                label="Vendredi"
                                value={this.props.hoursConfiguration.days.friday}
                                onChange={this.hoursDays_change('friday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.friday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.friday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-saturday"
                                label="Samedi"
                                value={this.props.hoursConfiguration.days.saturday}
                                onChange={this.hoursDays_change('saturday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.saturday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.saturday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={1}>
                                <TextField

                                id="hoursDays-sunday"
                                label="Dimanche"
                                value={this.props.hoursConfiguration.days.sunday}
                                onChange={this.hoursDays_change('sunday')}
                                margin="normal"
                                type="number"
                                error={this.props.hoursDays_errorMessage.sunday !== "" ? true : false}
                                helperText={this.props.hoursDays_errorMessage.sunday}
                                InputProps={{
                                    inputProps:{
                                        min: 0,
                                        max: 12,
                                        step: 0.5
                                    },
                                    endAdornment: <InputAdornment position="end">h</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h3">
                                = {this.props.hoursConfiguration.days.monday
                                + this.props.hoursConfiguration.days.tuesday
                                + this.props.hoursConfiguration.days.wednesday
                                + this.props.hoursConfiguration.days.thursday
                                + this.props.hoursConfiguration.days.friday
                                + this.props.hoursConfiguration.days.saturday
                                + this.props.hoursConfiguration.days.sunday} h / semaine = {(((
                                    this.props.hoursConfiguration.days.monday +
                                    this.props.hoursConfiguration.days.tuesday +
                                    this.props.hoursConfiguration.days.wednesday +
                                    this.props.hoursConfiguration.days.thursday +
                                    this.props.hoursConfiguration.days.friday +
                                    this.props.hoursConfiguration.days.saturday +
                                    this.props.hoursConfiguration.days.sunday
                                )*100)/35).toFixed(2)} %
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            : null)
        );
    }
}

HoursConfigurationForm.propTypes = {
    hoursConfiguration: PropTypes.object,
    setHoursConfiguration_handler: PropTypes.func.isRequired
};

export default withStyles(styles,{withTheme: true})(HoursConfigurationForm);
