import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import moment from 'moment';
import {
	withStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
    Typography,
    Button
} from "@material-ui/core";


import {
	Delete
} from "mdi-material-ui"


const style_override = theme => ({
	paper:{
		overflow:'visible'
	},
	mainDialogContent:{
		overflow:'visible',
		paddingTop:"0!important",
		"&>*":{
			margin:"8% 0"
		}
	},
	leftIcon: {
		marginRight: theme.spacing(),
	},
	deletion:{
		backgroundColor: theme.button.deletion,
		color: theme.palette.primary.contrastText
	},
});

class UpdateEventDialog extends React.Component {

	//======================= UI Callback ========================

	delete_handler = (event) => {
		this.props.deleteHandler(event);
	}

    closeFromDialog_handler = () => {
        this.props.deleteHandler();
    }

	deleteEvent_handler = (event) => {
		this.props.apiControllers.events.delete(this.props.event.id,{
			auth_username:this.props.user.username,
			auth_token:this.props.user.token
		}).then((response) => {
			this.props.notifier.success('Événement Supprimée');
			this.delete_handler(this.props.event);
		}).catch((error) => {
			this.props.notifier.error(error.data);
		});
	};

    //======================= Page Render ========================

    render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.closeFromDialog_handler}
				aria-labelledby="form-dialog-title"
				classes={{
					paper:classes.paper
				}}
			>
				<DialogTitle id="form-dialog-title">Supprimer un événement</DialogTitle>
				<form>
					<DialogContent className={classes.mainDialogContent}>
						<Typography variant="h3" >
							Date : {this.props.event !== undefined ? moment(this.props.event.date).format("DD/MM/YYYY") : undefined}
						</Typography>
                        <Typography variant="body1" >
							{this.props.event !== undefined ? this.props.event.title : undefined}
						</Typography>
                        <Typography variant="body1" >
							{this.props.event !== undefined ? (this.props.event.blocking ? "Événement bloquant" : "Événement non bloquant") : undefined}
						</Typography>
					</DialogContent>
					<DialogActions>
                        <Button
							onClick={this.deleteEvent_handler}
							className={classNames(classes.button,classes.deletion)}
							variant="contained"
						>
							<Delete className={classes.leftIcon} />
							Supprimer
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

UpdateEventDialog.propTypes = {
    date: PropTypes.object,
    deleteHandler: PropTypes.func.isRequired,
	apiControllers: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	logger: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    event: PropTypes.object,
};

export default withStyles(style_override,{withTheme: true})(UpdateEventDialog);
