import React from "react";
import ReactToPrint from 'react-to-print';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';

import classNames from 'classnames';
import moment from 'moment';
import randomColor from 'randomcolor';

import {Line} from 'react-chartjs-2';

import TimespentsFetchers from 'utils/timespentsFetchers.js';

import {dateFormat} from 'variables/general.jsx';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import momentBusinessDays from 'moment-business-days';
import {
	Grid,
	Card,
	CardContent,
	Button,
    Typography,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';

import {
	Printer,
	ChevronLeft,
	ChevronRight,
	CalendarToday,
} from 'mdi-material-ui';

const chartSkeletonHours = {
	fill: false,
	lineTension: 0,
	borderCapStyle: 'butt',
	borderJoinStyle: 'miter',
	pointRadius: 3,
	pointBorderWidth: 1,
	pointHoverRadius: 5,
	pointHoverBorderWidth: 2,
	data: [...Array(12).keys()].map(el => ({
		y:0,
		hours: 0,
		absence: 0
	}))
}

const chartSkeletonDays = {
	fill: false,
	lineTension: 0,
	borderCapStyle: 'butt',
	borderJoinStyle: 'miter',
	pointRadius: 3,
	pointBorderWidth: 1,
	pointHoverRadius: 5,
	pointHoverBorderWidth: 2,
	data: [...Array(12).keys()].map(el => ({
		y:0,
		days: 0,
		absence: 0
	}))
}

const zeroData = [...Array(12).keys()].map(el => ({
	y:0,
	hours: 0,
	absence: 0
}));

const dayNumberToName = [
	"sunday",
	"monday",
	"tuesday",
	"wednesday",
	"thursday",
	"friday",
	"saturday",
	"sunday",
]

const idToInfo=[
    'payedLeave',
	'recoveryTaken',
    'nonPayedLeave',
    'sickLeave',
    'sickChildLeave',
    'parentalLeave',
    'pregnancyLeave',
	'exceptionnalLeave',
	'study',
	'dayForfaitRecoveryTaken',
	'partialLeave'
]

const infoSkeleton = {
    // ratio:{
    //     label: "Ratio de temps de travail",
    //     value: 100,
    //     suffix: "%"
    // },
    joiningDate: {
        label: "Date d'entrée",
        value: "",
        suffix: ""
    },
	totalWorkedDays: {
        label: "Total jours travaillées",
        value: 0,
        suffix: "j"
    },
    workedHours: {
        label: "Heures travaillées",
        value: undefined,
        suffix: "h",
		legend: 'Rappel - ces heures correspondent au cumul des heures rentrées par l\'employé plus les absences pour étude'
	},
    contractHours: {
        label: "Heures contractuelles",
        value: undefined,
		suffix: "h",
		legend: 'Rappel - ces heures correspondent uniquement aux heures précisées dans le contrat de l\'utilisateur, moins les absences sauf récupération et étude'
    },
    workedDays: {
        label: "Jours travaillés",
        value: undefined,
		suffix: "j",
    },
    contractDays: {
        label: "Jours contractuels",
        value: undefined,
        suffix: "j",
    },
    recoveryLeft:{
        label: "Restant de récupération",
        value: 0,
		suffix: "j",
		legend: "Rappel - ces jours correspondent au cumul des heures supplémentaires par demie-journée (3.5h) moins les jours de récupération déjà pris. Sauf exception, un employé ne peut dépasser plus de 9 jours de récupération dans l'année. De plus, la prise de jour de récupération est soumise à l'aval de la direction."
    },
    recoveryTaken:{
        label: "Récupérations prises",
        value: 0,
        suffix: "j"
	},
	dayForfaitRecoveryTaken:{
        label: "Jour au forfait pris",
        value: 0,
        suffix: "j"
    },
    payedLeave:{
        label: "Congés payés pris",
        value: 0,
        suffix: "j"
    },
    nonPayedLeave:{
        label: "Congés sans solde pris",
        value: 0,
        suffix: "j"
    },
    sickLeave:{
        label: "Congés maladie pris",
        value: 0,
        suffix: "j"
    },
    sickChildLeave:{
        label: "Congés enfant malade pris",
        value: 0,
        suffix: "j"
    },
    parentalLeave:{
        label: "Congés parental pris",
        value: 0,
        suffix: "j"
    },
    pregnancyLeave:{
        label: "Congés maternité pris",
        value: 0,
        suffix: "j"
    },
    exceptionnalLeave:{
        label: "Congés exceptionnels pris",
        value: 0,
        suffix: "j"
	},
	study:{
        label: "Étude",
        value: 0,
        suffix: "j"
    },
	partialLeave:{
        label: "Chômage partiel",
        value: 0,
        suffix: "j"
    }
}

const randomColorConfig={
	luminosity: 'bright',
	format: 'hex'
}

const styles = theme => ({
	addTimespentCard:{
		overflow:"visible",
		"&>div>*":{
			margin:"2% 0"
		}
	},
	chartContainer:{
		paddingBottom:"0!important",
		width:'100%',
		height:'90%'
	},
	chartToolbar:{
		display:'flex',
		flexWrap: 'wrap',
		justifyContent: 'center',
		alignItems: 'center',
		textAlign:'center'
	},
	chartTitle:{
		flexGrow: 1,
		padding: '0 10px',
		textAlign: 'center',
	},
	navButtonGroup:{
		display:'inline-block',
		"&> button:last-child:not(:first-child)":{
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
		},
		"&> button:first-child:not(:last-child)": {
			borderTopRightRadius: 0,
			borderBottomRightRadius: 0,
		},
		"&> button:not(:first-child):not(:last-child)": {
			borderRadius: 0
		}
	},
	navButton:{
		backgroundColor:'white'

	},
	printVersion:{
		display:'none',
	},
	noPrint:{
		"@media print":{
			opacity:0
		}
	},
	printPage:{
		padding:"20px",
	},
	infoLegend:{
		color:'red',
		fontSize:'0.8em',
		fontStyle:'italic'

	}
});

const timespentsFetchers = new TimespentsFetchers();

class ReportingUsersOneUserChart extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			usersList:[],
            usersSelected:null,
            userInfo: infoSkeleton,
			userChartHours: {},
			userChartDays: {},
			monthToBegin:0,
			monthToBeginHours:0,
			monthToBeginDays:0,
			monthToEndHours:0,
			monthToEndDays:0,
			timespents: [],
			events: [],
			calendarRange:{
				from:new Date(moment().startOf('year')),
				to:new Date(moment().endOf('year'))
			},
			printChart:false,
            chartWidth:null,
            contractualDataHours: [],
            contractualDataDays: []
		};
		this.alreadyFetchedRanges = [];
		this.userChartColor = randomColor(randomColorConfig);

		this.requestedUserId = parseInt(this.props.match.params.userId);

		this.viewRight = this.props.user.adminPermission.timespents.users.view.others && this.props.user.adminPermission.timespents.absences.view.others;
		if(this.requestedUserId && this.requestedUserId === this.props.user.id){
			this.viewRight = true;
		}
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
    }

    cloneObject = (obj) => {
        var clone = {};
        for(var i in obj) {
            if(obj[i] != null &&  typeof(obj[i])=="object")
                clone[i] = this.cloneObject(obj[i]);
            else
                clone[i] = obj[i];
        }
        return clone;
    }

	componentDidMount = () => {
		if(this.requestedUserId){
			this.selectedUser_handler(this.props.user)
		}else{
			this.getUsers();
		}
		this.getEvents();
	}

    //======================= Tool Functions ========================

	getUsers = () => {
		return this.props.apiControllers.users.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			let usersWithNames = response.data.map(user => ({
				...user,
				name:user.displayname
			}));
			this.setState({usersList: usersWithNames});
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getEvents = () => {
		return this.props.apiControllers.events.getAll(
			undefined,
			undefined,
			{
				auth_username:this.props.user.username,
				auth_token:this.props.user.token,
		}).then((response)=>{
			let events = response.data;
            return this.setState({events: events});
		}).catch((error) => {
			if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
		});
	}

	getCurrentYearTimespents = () => {
		let start_date = moment(this.state.calendarRange.from).format(dateFormat);
		let end_date = moment(this.state.calendarRange.to).format(dateFormat);

		// If this is current year
		if(moment(this.state.calendarRange.from).isSame(moment(),'year')){
			end_date = moment().subtract(1,'month').endOf('month').format(dateFormat);
		}
		return this.props.loadingDialog.open()
		.then(()=>{
			setTimeout(()=> {
				return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, this.alreadyFetchedRanges, this.props.apiControllers.timespents, this.props.user, this.state.selectedUser.id, 'users', true, this.props.logger).then((timespents) => {
					let timespentsBase = this.state.timespents;
					let timespentsToAdd = timespents;
					if(timespentsToAdd !== undefined){
						for(let timespent of timespents){
							timespentsBase.push(timespent);
						}
					}
					return this.promisedSetState({timespents: timespentsBase}).then(() => {
						this.buildDayUserSumsArray(this.state.timespents.filter(timespent => (
							moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]')
							))).then(() => {
								this.props.loadingDialog.close();
						});
					});
				});
			},10);
		});
	}

	buildDayUserSumsArray = (timespents) => {
		return this.resetUsersSum().then(() => {
            let userInfo = this.cloneObject(infoSkeleton);
            let userChartHours = {
				...chartSkeletonHours,
                label: this.state.selectedUser.displayname,
                backgroundColor: this.userChartColor,
                borderColor: this.userChartColor,
                pointHoverBackgroundColor: this.userChartColor,
				pointHoverBorderColor: this.userChartColor,
			}

			let userChartDays = {
				...chartSkeletonDays,
                label: this.state.selectedUser.displayname,
                backgroundColor: this.userChartColor,
                borderColor: this.userChartColor,
                pointHoverBackgroundColor: this.userChartColor,
				pointHoverBorderColor: this.userChartColor,
			}
			userChartHours.data = JSON.parse(JSON.stringify(zeroData));
			userChartDays.data = JSON.parse(JSON.stringify(zeroData));

			let yearShouldWorkHours = [0,0,0,0,0,0,0,0,0,0,0,0];
			let yearShouldWorkDays = [0,0,0,0,0,0,0,0,0,0,0,0];

			let monthToSeek = 12;
			// If current year, compute previous month
			if(moment(this.state.calendarRange.from).isSame(moment(),'year')){
				monthToSeek = moment().month();
			}

			let monthToBegin = 0;
			if(moment(this.state.selectedUser.joiningDate).isSame(this.state.calendarRange.from, 'year')){
				monthToBegin = moment(this.state.selectedUser.joiningDate).month();
			}else if(moment(this.state.selectedUser.joiningDate).isAfter(this.state.calendarRange.from, 'year')){
				monthToBegin = 12;
			}

			let monthToBeginHours = 12;
			let monthToBeginDays = 12;

			let monthToEndHours = 0;
			let monthToEndDays = 0;

			let yearWorkedHoursAccumulator = 0;
			let yearWorkedDaysAccumulator = 0;
			let yearShouldWorkHoursAccumulator = 0;
			let yearShouldWorkDaysAccumulator = 0;

			// Browse each monthes
			for(let currentMonth = monthToBegin; currentMonth < monthToSeek; currentMonth++){
				let monthBegining = moment(this.state.calendarRange.from).month(currentMonth).startOf('month');
				let monthEnding = moment(this.state.calendarRange.from).month(currentMonth).endOf('month');
				let monthWorkedHours = 0;
				let monthWorkedDays = 0;
				let monthShouldWorkHours = 0;
				let monthShouldWorkDays = 0;

				// Browse each days
				let currentDate = moment(monthBegining);
				while(currentDate.isSameOrBefore(monthEnding, 'day')){
					let selectedContract = this.state.selectedUser.userContracts.find(contract => (moment(contract.date_start).isSameOrBefore(currentDate)) && (!contract.date_end || moment(contract.date_end).isSameOrAfter(currentDate)));
					let shouldWorkHours =  0;
					let shouldWorkDay =  0;
					if(selectedContract){
						if(selectedContract.hoursConfiguration.type === 'hours'){
							shouldWorkHours = selectedContract.hoursConfiguration.days[dayNumberToName[moment(currentDate).day()]];
							monthToEndHours=currentMonth;
							monthToBeginHours = Math.min(monthToBeginHours, currentMonth);
						}else{
							shouldWorkDay = momentBusinessDays(currentDate).isBusinessDay() ? 1 : 0;
							monthToEndDays=currentMonth;
							monthToBeginDays = Math.min(monthToBeginDays, currentMonth);
						}
					}

					let todayTimespents = timespents.filter(timespent => currentDate.isSame(timespent.date,'day'));
					let todayWorkedHours = 0;
					let workedDay = 0;

					for(let currentTimespent of todayTimespents){

						// If it was an absence
						if(currentTimespent.project.id === 1){
							userInfo[idToInfo[currentTimespent.category.id-1]].value +=(currentTimespent.hours / 7);
						}
						if(selectedContract && selectedContract.hoursConfiguration.type === 'hours'){

							// Not an absence, or else a study absence
							if(currentTimespent.project.id !== 1 || currentTimespent.category.id === 9){
								todayWorkedHours+=currentTimespent.hours;
								// If the absence is not a recovery
							}else if(currentTimespent.category.id !== 2){
								shouldWorkHours -= Math.min(shouldWorkHours, currentTimespent.hours);
							}
						}else{
							if(currentTimespent.project.id !== 1 || currentTimespent.category.id === 9){
								workedDay = 1;
							}else if(currentTimespent.category.id !== 2 || currentTimespent.category.id !== 10){
								shouldWorkDay = 0;
							}
						}
					}

					monthWorkedHours += todayWorkedHours;
					monthWorkedDays += workedDay;
					if(todayWorkedHours > 0 || workedDay > 0){
						userInfo.totalWorkedDays.value ++;
					}

					let todayBlockingEvent = this.state.events.find(event => (event.blocking && currentDate.isSame(event.date,'day')));

					if(todayBlockingEvent !== undefined){
						shouldWorkHours = 0;
						shouldWorkDay = 0;
					}
					monthShouldWorkHours += (shouldWorkHours > 0 ? shouldWorkHours : 0);
					monthShouldWorkDays += shouldWorkDay;
					currentDate.add(1,'day');
				}
				yearWorkedHoursAccumulator += monthWorkedHours;
				yearWorkedDaysAccumulator += monthWorkedDays;
				yearShouldWorkHoursAccumulator += monthShouldWorkHours;
				yearShouldWorkDaysAccumulator += monthShouldWorkDays;

				yearShouldWorkHours[currentMonth] = {hours : monthShouldWorkHours, y : yearShouldWorkHoursAccumulator};
				yearShouldWorkDays[currentMonth] = {days : monthShouldWorkDays, y : yearShouldWorkDaysAccumulator};

				userChartHours.data[currentMonth].hours = monthWorkedHours;
				userChartHours.data[currentMonth].y = yearWorkedHoursAccumulator;

				userChartDays.data[currentMonth].days = monthWorkedDays;
				userChartDays.data[currentMonth].y = yearWorkedDaysAccumulator;

			}

			userChartHours.data = userChartHours.data.slice(monthToBeginHours,monthToEndHours+1);
			userChartDays.data = userChartDays.data.slice(monthToBeginDays,monthToEndDays+1);

			yearShouldWorkHours = yearShouldWorkHours.slice(monthToBeginHours,monthToEndHours+1);
			yearShouldWorkDays = yearShouldWorkDays.slice(monthToBeginDays,monthToEndDays+1);

			userInfo.workedHours.value = yearWorkedHoursAccumulator || undefined;
			userInfo.contractHours.value = yearShouldWorkHoursAccumulator || undefined;
			userInfo.workedDays.value = yearWorkedDaysAccumulator || undefined;
			userInfo.contractDays.value = yearShouldWorkDaysAccumulator || undefined;
			userInfo.recoveryLeft.value = ((yearWorkedHoursAccumulator - yearShouldWorkHoursAccumulator)/7);
			userInfo.recoveryLeft.value = userInfo.recoveryLeft.value > 0 ? Math.floor(userInfo.recoveryLeft.value*2)/2 : 0;
			userInfo.joiningDate.value = moment(this.state.selectedUser.joiningDate).format("DD/MM/YYYY");
			// userInfo.ratio.value = selectedContract.hoursConfiguration.ratio;

			return this.promisedSetState({
				userInfo: userInfo,
				userChartHours: userChartHours,
				contractualDataHours:yearShouldWorkHours,
				userChartDays: userChartDays,
				contractualDataDays:yearShouldWorkDays,
				monthToBegin:monthToBegin,
				monthToEndDays:monthToEndDays,
				monthToEndHours:monthToEndHours,
				monthToBeginHours:monthToBeginHours,
				monthToBeginDays:monthToBeginDays
			});
		});

	}

	resetUsersSum = () => {
		return this.promisedSetState({userInfo : {}});
	}

	buildContractChart = (hoursPerMonth,beginMonth) => {
		let hours = [];
		let hoursAccumulator = 0;
		let month =0;
		for(let month = 0; month < beginMonth; month++){
			hours.push({y:0,hours:0});
		}
		for(; month < 12; month++){
			hoursAccumulator += hoursPerMonth;
			hours.push({y:hoursAccumulator, hours:hoursPerMonth});
		}
		this.setState({contractualDataHours : hours});
	}

    //======================= UI Callback ========================

	navigatePrevious = () => {
		let previousYearStart = new Date(moment(this.state.calendarRange.from).subtract(1,'year').startOf('year'));
		let previousYearEnd = new Date(moment(this.state.calendarRange.from).subtract(1,'year').endOf('year'));
		this.setState({calendarRange:{from:previousYearStart,to:previousYearEnd}}, this.dateChanged_handler);
	}

	navigateNext = () => {
		let previousYearStart = new Date(moment(this.state.calendarRange.from).add(1,'year').startOf('year'));
		let previousYearEnd = new Date(moment(this.state.calendarRange.from).add(1,'year').endOf('year'));
		this.setState({calendarRange:{from:previousYearStart,to:previousYearEnd}}, this.dateChanged_handler);
	}

	navigateToday = () => {
		let previousYearStart = new Date(moment().startOf('year'));
		let previousYearEnd = new Date(moment().endOf('year'));
		this.setState({calendarRange:{from:previousYearStart,to:previousYearEnd}}, this.dateChanged_handler);
	}

	dateChanged_handler = () => {
		if(this.state.selectedUser)
		{
			this.getCurrentYearTimespents();
		}
	}

	selectedUser_handler = (selectedUser) => {
		this.userChartColor = randomColor(randomColorConfig);
		this.alreadyFetchedRanges = [];
		this.setState({
			selectedUser: selectedUser,
			timespents:[],
		}, this.getCurrentYearTimespents)
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<Grid container spacing={2} className='dashboardContainer'>
				<div className={classes.printVersion}>
					{this.state.selectedUser &&
						<div className={classes.printPage} ref={el => (this.componentToPrintRef = el)}>
							<Typography variant="h3">
								{this.state.selectedUser.displayname}
							</Typography>
							<Typography variant="h3">
								{moment(this.state.calendarRange.from).format("YYYY")}
							</Typography>
							<Table>
								<TableBody>
									{Object.values(this.state.userInfo).map(info => {
										return (
											<TableRow key={info.label}>
												<TableCell>{info.label}</TableCell>
												<TableCell>{info.value} {info.suffix}</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
                            </Table>
						</div>
					}
				</div>
				<Grid container item xs={4} spacing={2}>
					<Grid item xs={12}>
						<Card className={classes.addTimespentCard}>
							<CardContent>
								<Grid container item spacing={2}>
									<Grid item xs={10}>
										<Typography variant="h2" gutterBottom>
											Reporting Utilisateur
										</Typography>
									</Grid>
									<Grid item xs={2}>
									<ReactToPrint
										trigger={() => (
											<Button
												variant="contained"
												color="secondary"
											>
												<Printer/>
											</Button>
										)}
										content={() => this.componentToPrintRef}
									/>
									</Grid>
								</Grid>
								<Typography variant="h3">
									{moment(this.state.calendarRange.from).format("YYYY")}
								</Typography>
								{!this.requestedUserId &&
									<AutoCompleteHightlight
										suggestions={this.state.usersList}
										change_handler={this.selectedUser_handler}
										errorMessage=''
										label="Utilisateur :"
									/>
								}
                                <Table>
									<TableBody>
									{Object.values(this.state.userInfo).map(info => {
										return (
											<React.Fragment>
												{info.value !== undefined &&
													<TableRow key={info.label}>
														<TableCell>{info.label} {info.legend && (
															<Typography className={classes.infoLegend}>
																{info.legend}
															</Typography>
														)}</TableCell>
														<TableCell>{info.value} {info.suffix}</TableCell>
													</TableRow>
												}
											</React.Fragment>
										)
									})}
									</TableBody>
                                </Table>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={8} className={classes.chartContainer} ref={el => (this.chartToPrintRef = el)}>
					<div className={classes.chartToolbar}>
						<div className={classNames(classes.navButtonGroup,classes.noPrint)}>
							<Button
								className={classes.navButton}
								variant="outlined"
								onClick={this.navigatePrevious}
							>
								<ChevronLeft/>
							</Button>
							<Button
								className={classes.navButton}
								variant="outlined"
								onClick={this.navigateToday}
							>
								<CalendarToday/>
							</Button>
							{!moment(this.state.calendarRange.from).isSame(moment(),'year') &&
								<Button
									className={classes.navButton}
									variant="outlined"
									onClick={this.navigateNext}
								>
									<ChevronRight/>
								</Button>
							}
						</div>
						<Typography variant='h2' className={classes.chartTitle}>
							{moment(this.state.calendarRange.from).format("YYYY")}
						</Typography>
						<ReactToPrint
							trigger={() => (
								<Button
									variant="contained"
									color="secondary"
									className={classes.noPrint}
								>
									<Printer/>
								</Button>
							)}
							content={() => this.chartToPrintRef}
						/>
					</div>
					<Grid container item xs={12}>
						{this.state.monthToEndHours > 0 &&
							// <Grid item xs={this.state.monthToEndDays > 0 ? 6 : 12} className={classes.chartContainer}>
							<Grid item xs={12} className={classes.chartContainer}>
								<Line
									options={{
										responsive: true,
										maintainAspectRatio: true,
										tooltips: {
											callbacks: {
												label: function(tooltipItem, data) {
													return(
														data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y+" h - "+
														data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].hours+" h");
												}
											}
										},
										scales: {
											yAxes: [{
												scaleLabel: {
													display:true,
													labelString: "Heures dans l'année (h)"
												}
											}],
											xAxes: [{
												scaleLabel: {
													display:true,
													labelString: "Mois de l'année"
												}
											}]
										}
									}}
									data={{
										labels: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'].slice(this.state.monthToBeginHours,this.state.monthToEndHours+1),
										datasets: [
											{
												...chartSkeletonHours,
												label: 'Contractuel',
												backgroundColor: '#666666',
												borderColor: '#333333',
												pointHoverBorderColor: '#333333',
												pointHoverBackgroundColor: '#666666',
												data: this.state.contractualDataHours
											},
											this.state.userChartHours
										]
									}}
								/>
							</Grid>
						}
						{this.state.monthToEndDays > 0 &&
							// <Grid item xs={this.state.monthToEndHours > 0 ? 6 : 12} className={classes.chartContainer}>
							<Grid item xs={12} className={classes.chartContainer}>
								<Line
									options={{
										responsive: true,
										maintainAspectRatio: true,
										tooltips: {
											callbacks: {
												label: function(tooltipItem, data) {
													return(
														data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].y+" j - "+
														data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].days+" j");
												}
											}
										},
										scales: {
											yAxes: [{
												scaleLabel: {
													display:true,
													labelString: "Jours dans l'année (j)"
												}
											}],
											xAxes: [{
												scaleLabel: {
													display:true,
													labelString: "Mois de l'année"
												}
											}]
										}
									}}
									data={{
										labels: ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'].slice(this.state.monthToBeginDays,this.state.monthToEndDays+1),
										datasets: [
											{
												...chartSkeletonDays,
												label: 'Contractuel',
												backgroundColor: '#666666',
												borderColor: '#333333',
												pointHoverBorderColor: '#333333',
												pointHoverBackgroundColor: '#666666',
												data: this.state.contractualDataDays
											},
											this.state.userChartDays
										]
									}}
								/>
							</Grid>
						}

					</Grid>
				</Grid>

			</Grid>

		);
	}
}

ReportingUsersOneUserChart.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ReportingUsersOneUserChart);
