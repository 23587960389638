import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from 'classnames';
import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';

import moment from 'moment';

import './AddTimespentForm.css'

import {
	Button,
	Typography,
	TextField,
	InputAdornment
} from '@material-ui/core';



import {
	Plus
} from 'mdi-material-ui';

const hours_limit= {
	min: 0.5,
	max: 24,
	step: 0.5
};


const styles = theme => ({
	button: {
		margin: theme.spacing(),
	},
	creation:{
		backgroundColor: theme.button.creation,
		color: theme.palette.primary.contrastText
	},
	leftIcon: {
		marginRight: theme.spacing(),
	}
});

class AddTimespentForm extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			form_hours:0,
			form_hours_errorMessage:"",
			form_selectedClient:null,
			form_client_errorMessage:"",
			form_selectedProject:null,
			form_project_errorMessage:"",
			form_selectedCategory:null,
			form_category_errorMessage:"",
			form_selectedTags:[]
		};
	}

	//======================= Tool Functions ========================

	filterTags = () => this.state.form_selectedCategory ? (
		this.state.form_selectedProject.tags.filter(proj_tag => (!proj_tag.disabled && this.state.form_selectedCategory.tags.find(cat_tag => cat_tag.id === proj_tag.id)))) : []

	//======================= UI Callback ========================

	addTimespent_handler = (event) => {
		let hours = this.state.form_hours;
		let client = this.state.form_selectedClient;
		let project = this.state.form_selectedProject;
		let category = this.state.form_selectedCategory;
		if(hours < hours_limit.min || hours > hours_limit.max || (hours % hours_limit.step !== 0)){
			this.setState({form_hours_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else if(client === null){
			this.setState({form_client_errorMessage: "Vous devez sélectionner un client"});
		}else if(project === null){
			this.setState({form_project_errorMessage: "Vous devez sélectionner un projet"});
		}else if(category === null){
			this.setState({form_category_errorMessage: "Vous devez sélectionner une catégorie"});
		}else{
			this.props.apiControllers.timespents.create({
				userId: this.props.user.id,
				projectId: project.id,
				categoryId: category.id,
				hours: hours,
				description:'',
				tags:this.state.form_selectedTags.map(tag => tag.id),
				date:moment(this.props.selectedDate).format("YYYY-MM-DD")
			},{
				auth_username:this.props.auth_user.username,
				auth_token:this.props.auth_user.token
			}).then((response) => {
				this.props.notifier.success('Temps enregistré');
				this.props.addTimespent_handler(response.data);
			}).catch((error) => {
				this.props.logger.error(error);
				this.props.notifier.error(error.data);
			});
		}
	};

	hoursChange_handler = (event) => {
		this.setState({form_hours: event.target.value});
		let value = event.target.value;
		if(value < hours_limit.min || value > hours_limit.max || (value % hours_limit.step !== 0)){
			this.setState({form_hours_errorMessage: 'Min 0.5, Max 24, par pas de 0.5'});
		}else{
			this.setState({form_hours_errorMessage: ''});
		}
	}

	clientChange_handler = (selectedClient) => {
		this.setState({
			form_selectedClient: selectedClient,
			form_client_errorMessage: '',
			form_selectedProject: null,
			form_selectedCategory:null,
			form_selectedTags:[],
		})
	}

	projectChange_handler = (selectedProject) => {
		this.setState({
			form_selectedProject: selectedProject,
			form_project_errorMessage: '',
			form_selectedCategory:null,
		});
	}

	categoryChange_handler = (selectedCategory) => {
		this.setState({
			form_selectedCategory: selectedCategory,
			form_selectedTags:[],
			form_category_errorMessage: '',
		});
	}

	tagsChange_handler = (tags) => {
		this.setState({
			form_selectedTags: tags,
		});
	}

	selectAllTags_handler = () => {
		this.tagsChange_handler(this.filterTags());
	}

	//======================= Page Render ========================

	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Typography variant="h3">
					Date : {this.props.selectedDate !== undefined ? moment(this.props.selectedDate).format("DD/MM/YYYY") : null}
				</Typography>
				<TextField
					fullWidth
					error={this.state.form_hours_errorMessage !== "" ? true : false}
					helperText={this.state.form_hours_errorMessage}
					color="secondary"
					id="hours-input"
					label="Nombre d'heures"
					type="number"
					InputProps={{
						inputProps:{
							min: hours_limit.min,
							max: hours_limit.max,step: hours_limit.step
						},
						endAdornment: <InputAdornment position="end">h</InputAdornment>
					}}
					value={this.state.form_hours}
					onChange={this.hoursChange_handler}
				/>
				{this.props.clientsList &&
				<AutoCompleteHightlight
					suggestions={this.props.clientsList.filter(client => (!client.disabled))}
					change_handler={this.clientChange_handler}
					errorMessage={this.state.form_client_errorMessage}
					label="Client :"
				/>
				}
				{this.state.form_selectedClient !== null ?
				<AutoCompleteHightlight
					suggestions={this.props.projectsList.filter(project => (!project.disabled && project.clientId === this.state.form_selectedClient.id))}
					change_handler={this.projectChange_handler}
					selectedValue={this.state.form_selectedProject}
					errorMessage={this.state.form_project_errorMessage}
					label="Projet :"
				/>
				: null}
				{this.state.form_selectedProject !== null ?
				<AutoCompleteHightlight
					suggestions={this.state.form_selectedProject.categories.filter(category => (!category.disabled))}
					change_handler={this.categoryChange_handler}
					selectedValue={this.state.form_selectedCategory}
					errorMessage={this.state.form_category_errorMessage}
					disabled={false}
					label="Catégorie :"
				/>
				: null}
				{this.state.form_selectedCategory !== null ?
				<AutoCompleteHightlight
					suggestions={this.filterTags()}
					change_handler={this.tagsChange_handler}
					selectedValue={this.state.form_selectedTags}
					errorMessage=""
					disabled={false}
					label="Tags :"
					isMulti={true}
					selectAll={true}
					selectAll_handler={this.selectAllTags_handler}
				/>
				: null}
				<Button
					className={classNames(classes.button,classes.creation)}
					variant="contained"
					onClick={this.addTimespent_handler}
				>
					<Plus className={classes.leftIcon} />
					Ajouter un temps
				</Button>
			</React.Fragment>
		);
	}
}

AddTimespentForm.propTypes = {
    selectedDate: PropTypes.object.isRequired,
    addTimespent_handler: PropTypes.func.isRequired,
    clientsList: PropTypes.array.isRequired,
    projectsList: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    auth_user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(AddTimespentForm);
