import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid,
    Card,
    CardHeader,
    CardContent,
    Button,
    Typography,
    Switch,
    TextField,
    InputAdornment
} from '@material-ui/core';
import{
    Pencil, Delete, Plus, DeleteRestore, Filter
} from 'mdi-material-ui';

import classNames from 'classnames';

import { entityDisplayNames, entityElements } from "variables/general.jsx";

import PagedFilterableTable from 'components/PagedFilterableTable/PagedFilterableTable.jsx';
import SuppressDialog from 'components/SuppressDialog/SuppressDialog.jsx';

const styles = theme => ({
    button: {
        margin: theme.spacing(),
    },
    creation:{
        backgroundColor: theme.button.creation,
        color: theme.palette.primary.contrastText
    },
    leftIcon: {
        marginRight: theme.spacing(),
    },
    update:{
        backgroundColor: theme.button.update,
        color: theme.palette.primary.contrastText
    },
    deletion:{
        backgroundColor: theme.button.deletion,
        color: theme.palette.primary.contrastText
    }
});

class EntityList extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            entityList: [],
            showDisabled: false,
            showSystem: false,
            entityFilter:'',
            suppressDialog_open: false,
            idToSuppress: null
        };

        this.rights = this.props.user.adminPermission[this.props.match.params.entityName];
        if(!this.rights.createUpdate){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
    }

    componentDidMount = () => {
		this.refreshList();
    }

    //======================= Tool Functions ========================

    refreshList = () => {
        this.props.loadingDialog.open();
        this.props.apiControllers[this.props.match.params.entityName].getAll({
            auth_username:this.props.user.username,
            auth_token:this.props.user.token,
            disabled:this.state.showDisabled,
            system:this.state.showSystem
        }).then((response)=>{
            let entityList = [];
            response.data.forEach(entity => {
                entity.actions=()=>(
                    <React.Fragment>
                        {this.rights.createUpdate ?
                            <Button
                                variant="contained"
                                className={classNames(this.props.classes.button,this.props.classes.update)}
                                onClick={() => this.update_clicked(entity.id)}
                                >
                                <Pencil/>
                            </Button>
                        : null}
                        {this.rights.disable && !entity.system ?
                            (entity.disabled ?
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    className={this.props.classes.button}
                                    onClick={() => this.restore_clicked(entity.id)}
                                >
                                    <DeleteRestore/>
                                </Button>
                                :
                                <Button
                                    variant="contained"
                                    className={classNames(this.props.classes.button,this.props.classes.deletion)}
                                    onClick={() => this.openSuppressDialog_handler(entity.id)}
                                >
                                    <Delete/>
                                </Button>
                            )
                        : null}
                    </React.Fragment>
                );
                entityList.push(entity);
            })
            this.setState({entityList: entityList});
            this.props.loadingDialog.close();
        }).catch((error) => {
            this.props.loadingDialog.close();
            if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

    //======================= UI Callback ========================

    filterChange_handler = (event) => {
        this.setState({
            entityFilter: event.target.value
        })
    }

    update_clicked = (id) => {
        this.props.history.push('/'+this.props.match.params.entityName+'/'+id);
    }


    create_clicked = () => {
        this.props.history.push('/'+this.props.match.params.entityName+'/create');
    }

    delete_handler = () => {
        let id = this.state.idToSuppress
        this.closeSuppressDialog_handler();
        this.props.apiControllers[this.props.match.params.entityName].update(
            id,{
                disabled:true
            },{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token
            }).then((response) => {
                this.props.notifier.success('Élément désactivé');
                this.refreshList();
            }).catch((error) => {
                this.props.notifier.error(error.data);
            });
    }

    restore_clicked = (id) => {
        this.props.apiControllers[this.props.match.params.entityName].update(
            id,{
                disabled:false
            },{
                auth_username:this.props.user.username,
                auth_token:this.props.user.token
            }).then((response) => {
                this.props.notifier.success('Élément réactivé');
                this.refreshList();
            }).catch((error) => {
                this.props.notifier.error(error.data);
            });
    }

    showDisabled_changed = (event) => {
        this.setState({showDisabled : event.target.checked});
        setTimeout(this.refreshList, 0);
    }

    showSystem_changed = (event) => {
        this.setState({showSystem : event.target.checked});
        setTimeout(this.refreshList, 0);
    }

    openSuppressDialog_handler = (id) => {
		this.setState({
            suppressDialog_open: true,
            idToSuppress:id
        });
	}

	closeSuppressDialog_handler = () => {
		this.setState({suppressDialog_open: false});
    }

    //======================= Page Render ========================

    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                <SuppressDialog
					open={this.state.suppressDialog_open}
					permanent={false}
					deleteHandler={this.delete_handler}
                    closeHandler={this.closeSuppressDialog_handler}
				/>
                <Grid item xs={12}>
                    <Card >
                        <CardHeader
                            title={"Liste des "+entityDisplayNames[this.props.match.params.entityName]}
                        />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    {this.rights.viewDisabled &&
                                        <Typography>
                                            Afficher les {entityDisplayNames[this.props.match.params.entityName]} désactivés
                                            <Switch
                                                checked={this.state.showDisabled}
                                                onChange={this.showDisabled_changed}
                                                value={false}
                                            />
                                        </Typography>
                                    }
                                    {this.rights.viewSystem &&
                                        <Typography>
                                            Afficher les {entityDisplayNames[this.props.match.params.entityName]} systèmes
                                            <Switch
                                                checked={this.state.showSystem}
                                                onChange={this.showSystem_changed}
                                                value={false}
                                            />
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        className={classes.margin}
                                        id="input-with-icon-textfield"
                                        value={this.state.entityFilter}
                                        onChange={this.filterChange_handler}
                                        label="Filtre"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Filter />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <PagedFilterableTable
                                    data={this.state.entityList.filter(entity => {
                                        return(entity[entityElements[this.props.match.params.entityName].find(element => element.filterable).name].toLowerCase().includes(this.state.entityFilter.toLowerCase()))
                                    })}
									properties={[
                                        ...entityElements[this.props.match.params.entityName].filter(element => element.toDisplay),
                                        {name:'actions',label:'Actions',type:'custom', align:'right'}
                                    ]}
                            />
                            <Button
                                variant="contained"
                                className={classNames(classes.button,classes.creation)}
                                onClick={this.create_clicked}
                            >
                                <Plus className={classes.leftIcon} />
                                Créer
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

EntityList.propTypes = {
    user: PropTypes.object.isRequired,
    logger: PropTypes.object.isRequired,
    userManager: PropTypes.object.isRequired,
    notifier: PropTypes.object.isRequired,
    loadingDialog: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    apiControllers: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};
export default withStyles(styles,{withTheme: true})(EntityList);
