import React from "react";
import ReactToPrint from 'react-to-print';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import TimespentsCalendar from 'components/Timespents/TimespentsCalendar.jsx';
import PagedFilterableTable from 'components/PagedFilterableTable/PagedFilterableTable.jsx';


import TimespentsFetchers from 'utils/timespentsFetchers.js';

import PrintableTable from 'components/PrintableTable/PrintableTable.jsx';

import moment from 'moment';

import 'react-big-calendar/lib/css/react-big-calendar.css'

import {dateFormat} from 'variables/general.jsx';

// Make sure moment.js has the required locale data
import 'moment/locale/fr';
import {
	Grid,
	Card,
	CardContent,
	Button,
	Typography,
} from '@material-ui/core';



import {
	Printer
} from 'mdi-material-ui';

const hoursPerDay = 7;
const styles = theme => ({
	addTimespentCard:{
		overflow:"visible",
		"&>div>*":{
			margin:"2% 0"
		}
	},
	calendarContainer:{
		paddingBottom:"0!important"
	},
	printVersion:{
		display:'none'
	}
});

const timespentsFetchers = new TimespentsFetchers();
class ReportingUsersCalendarPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			usersList:[],
			usersSelected:[],
			timespents: [],
			calendarRange:{
				from:new Date(moment().startOf('month')),
				to:new Date(moment().endOf('month'))
			},
			dayUserSums:[],
			dayUserSumsSelected:[]
		};
		this.alreadyFetchedRanges = [];

		this.viewRight = this.props.user.adminPermission.timespents.users.view.others && this.props.user.adminPermission.timespents.absences.view.others;
        if(!this.viewRight){
			this.props.notifier.error('Accès interdit');
            this.props.history.push('/');
        }
		this.absenceRight = this.props.user.adminPermission.timespents.absences.view.others;
	}

	promisedSetState = (newState) => {
		return new Promise((resolve) => {
			this.setState(newState, () => {
				resolve()
			});
		});
	}

	componentDidMount = () => {
		this.getUsers().then(() => {
			this.getThisMonthTimespents();
		});
	}

    //======================= Tool Functions ========================

	getUsers = () => {
		return this.props.apiControllers.users.getAll({
			auth_username:this.props.user.username,
			auth_token:this.props.user.token,
		}).then((response)=>{
			this.setState({usersList: response.data});
		}).catch((error) => {
			if(error.status === 403){
				this.props.notifier.error('Accès interdit');
				this.props.history.push('/');
			}else{
				this.props.checkAuthentication();
			}
		});
	}

	getThisMonthTimespents = () => {
		return this.props.loadingDialog.open().then(()=>{
			setTimeout(()=>{
				let end_date = moment(this.state.calendarRange.to).format(dateFormat);
				let start_date = moment(this.state.calendarRange.from).format(dateFormat);
				return timespentsFetchers.fetchPeriodTimespent(start_date,end_date, this.alreadyFetchedRanges, this.props.apiControllers.timespents, this.props.user, undefined, undefined, true, this.props.logger).then((timespents) => {


					let timespentsBase = this.state.timespents;
					let timespentsToAdd = timespents;
					if(timespentsToAdd !== undefined){
						for(let timespent of timespents){
							timespentsBase.push(timespent);
						}
					}
					return this.promisedSetState({timespents: timespentsBase}).then(()=>{
						this.buildDayUserSumsArray(this.state.timespents.filter(timespent => (
							moment(timespent.date).isBetween(this.state.calendarRange.from, this.state.calendarRange.to, 'days', '[]')
						)));
						this.props.loadingDialog.close();
					});
				});
			});
		});
	}

	buildDayUserSumsArray = (timespents) => {
		this.resetUsersSum().then(() => {
			let usersInter = this.state.usersList;
			let result = timespents.reduce((accumulator, current) => {
				let index = accumulator.findIndex(el => {
					return((moment(el.date).isSame(current.date, 'day')) &&(el.user.id === current.user.id) && (el.project.system === current.project.system))
				});
				let userToChange = usersInter.find(user => user.id === current.user.id);
				if(index !== -1){
					let existing = accumulator[index];
					existing.timespents.push(current);
					existing.hours += current.hours;
					existing.title = current.user.displayname+' : '+existing.hoursSum+'h';
				}else{
					if(current.project.id !== 1){
						userToChange.daysSum++;
					}
					let res = {};
					res.timespents = [];
					res.timespents.push(current);
					res.hours = current.hours;
					res.user = current.user;
					res.user.name = current.user.displayname;
					res.user.color = "#8888EE";
					res.allDay = false;
					res.date = current.date;
					res.category = current.category;
					res.project = current.project;
					accumulator.push(res);
				}
				if(current.project.id !== 1){
					userToChange.hoursSum+=current.hours;
				}else{
					userToChange.absenceDaysSum+=(current.hours / hoursPerDay);
				}
				return accumulator;
			},
			[]);
			this.setState({usersList: usersInter, dayUserSums: result},this.populateEventArray);
		});

	}

	resetUsersSum = () => {
		let usersInter = this.state.usersList;
		usersInter.forEach((el) => {
			el.hoursSum = 0;
			el.daysSum = 0;
			el.absenceDaysSum = 0;
		});
		return this.promisedSetState({usersList : usersInter});
	}

	populateEventArray = () => {
		let dayUserSumsSelected = [];
		this.state.usersSelected.forEach(user => {
			dayUserSumsSelected.push(...this.state.dayUserSums.filter(el => el.user.id === user.id ));
		});

		this.setState({dayUserSumsSelected:dayUserSumsSelected});
	}

    //======================= UI Callback ========================

	userSelected_handler = (selectedIds) => {
		let usersSelected = [];
		selectedIds.forEach(id => {
			usersSelected.push(this.state.usersList.find(user => user.id === id));
		});
		this.setState({usersSelected:usersSelected},this.populateEventArray);
	}

	calendarRangeChange_handler = (dateRange) => {
		this.setState({calendarRange:dateRange},
			this.getThisMonthTimespents);
	}

    //======================= Page Render ========================

	render() {
		const { classes } = this.props;

		return (
			<Grid container spacing={2} className='dashboardContainer'>
				<div className={classes.printVersion}>
					<PrintableTable
						data={this.state.usersList}
						dataSelected_handler={this.userSelected_handler}
						orderBy="displayname"
						order="asc"
						properties={[
							{name:"displayname",label:"Nom"},
							{name:"hoursSum",label:"Heures", suffix:' h'},
							{name:"daysSum",label:"Jours", suffix:' j'},
							{name:"absenceDaysSum",label:"Absences", suffix:' j'},
						]}
						ref={el => (this.componentToPrintRef = el)}
						title={moment(this.state.calendarRange.from).format("MMMM YYYY")}
					/>
				</div>
				<Grid container item xs={4} spacing={2}>
					<Grid item xs={12}>
						<Card className={classes.addTimespentCard}>
							<CardContent>
								<Grid container item spacing={2}>
									<Grid item xs={10}>
										<Typography variant="h2" gutterBottom>
											Reporting Utilisateur
										</Typography>
									</Grid>
									<Grid item xs={2}>
										<ReactToPrint
											trigger={() => (
												<Button
													variant="contained"
													color="secondary"
												>
													<Printer/>
												</Button>
											)}
											content={() => this.componentToPrintRef}
										/>
									</Grid>
								</Grid>
								<Typography variant="h3">
									{moment(this.state.calendarRange.from).format("MMMM YYYY")}
								</Typography>
								<PagedFilterableTable
									data={this.state.usersList}
									dataSelected_handler={this.userSelected_handler}
									properties={[
										{name:"displayname",label:"Nom"},
										{name:"hoursSum",label:"Heures", suffix:' h'},
										{name:"daysSum",label:"Jours", suffix:' j'},
										{name:"absenceDaysSum",label:"Absences", suffix:' j'},
									]}
									selectable={true}
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<Grid item xs={8} className={classes.calendarContainer}>
					<TimespentsCalendar
						displayAbsences={this.absenceRight}
						daySum={false}
						accessor="user"
						selectable={false}
						rangeChange_handler={this.calendarRangeChange_handler}
						timespents={this.state.dayUserSumsSelected}
						notifier={this.props.notifier}
						logger={this.props.logger}
						enableAddTimespent={false}
						enableUpdateTimespent={false}
						enableUpdateAbsence={false}
						apiControllers={this.props.apiControllers}
						user={this.props.user}
						checkAuthentication={this.props.checkAuthentication}
						loadingDialog={this.props.loadingDialog}
					/>
				</Grid>
			</Grid>

		);
	}
}

ReportingUsersCalendarPage.propTypes = {
	classes: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	userManager: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
	loadingDialog: PropTypes.object.isRequired,
	logger: PropTypes.object.isRequired,
	apiControllers: PropTypes.object.isRequired,
	checkAuthentication:PropTypes.func.isRequired,
    updateInnerUser:PropTypes.func.isRequired,
};

export default withStyles(styles, {withTheme: true})(ReportingUsersCalendarPage);
