import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AutoCompleteHightlight from 'components/Inputs/AutoCompleteHighlight.jsx';


import moment from 'moment';
import {
	withStyles,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	InputAdornment,
    Typography,
    Button
} from "@material-ui/core";
import {
	CurrencyUsd,
	Sleep,
	CurrencyUsdOff,
	HeartPulse,
	AccountChild,
    BabyBuggy,
    Delete,
	Pencil,
	HumanPregnant,
	HomeAlert,
	School,
	FileDocumentEdit,
	ProgressClock
} from 'mdi-material-ui';


const absenceDays_limit= {
	min: 3.5,
	max: 7,
	step: 3.5
};

const iconStyle = {
	marginRight:"2%",
	fontSize:"16px"
};
const categoriesIcons = [
	{id:1, icon:(<CurrencyUsd
		style={iconStyle}
	/>)},
	{id:2, icon:(<Sleep
		style={iconStyle}
	/>)},
	{id:3, icon:(<CurrencyUsdOff
		style={iconStyle}
	/>)},
	{id:4, icon:(<HeartPulse
		style={iconStyle}
	/>)},
	{id:5, icon:(<AccountChild
		style={iconStyle}
	/>)},
	{id:6, icon:(<BabyBuggy
		style={iconStyle}
	/>)},
	{id:7, icon:(<HumanPregnant
		style={iconStyle}
	/>)},
	{id:8, icon:(<HomeAlert
		style={iconStyle}
	/>)},
	{id:9, icon:(<School
		style={iconStyle}
	/>)},
	{id:10, icon:(<FileDocumentEdit
		style={iconStyle}
	/>)},
	{id:11, icon:(<ProgressClock
		style={iconStyle}
	/>)}
]

const style_override = theme => ({
	paper:{
		overflow:'visible'
	},
	mainDialogContent:{
		overflow:'visible',
		paddingTop:"0!important",
		"&>*":{
			margin:"8% 0"
		}
	},
	leftIcon: {
		marginRight: theme.spacing(),
	},
	deletion:{
		backgroundColor: theme.button.deletion,
		color: theme.palette.primary.contrastText
	},
});

class UpdateAbsenceDialog extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
            project: undefined,
			selectedCategory: undefined,
            absenceDay: absenceDays_limit.min,
			absenceDay_errorMessage: '',
			category_errorMessage: '',
        };

    }
    componentDidMount = () => {
        this.props.apiControllers.projects.getById(1,{
            auth_username:this.props.user.username,
            auth_token:this.props.user.token,
        }).then((response)=>{
			let projects = response.data;
			projects.categories.map(cat => cat.icon = categoriesIcons.find((catIcon) => (catIcon.id === cat.id)).icon)
            this.setState({project: response.data});
        }).catch((error) => {
            if(error.status === 403){
                this.props.notifier.error('Accès interdit');
                this.props.history.push('/');
            }else{
                this.props.checkAuthentication();
            }
        });
    }

	//======================= UI Callback ========================

    update_handler = (timespent) => {
		this.setState({
			selectedCategory: undefined,
			absenceDay: absenceDays_limit.min,
		});
		this.props.updateHandler(timespent);
	}

	delete_handler = (timespent) => {
		this.setState({
			selectedCategory: undefined,
			absenceDay: absenceDays_limit.min,
		});
		this.props.deleteHandler(timespent);
	}

    closeFromDialog_handler = () => {
        this.props.updateHandler();
    }

	dialogEnter_handler = () => {
		let category =  (this.props.timespent ? this.props.timespent.category: undefined);
		if(category !== undefined){
			category.icon = categoriesIcons.find((catIcon) => (catIcon.id === category.id)).icon;
		}
        this.setState({
			selectedCategory: category,
			absenceDay: (this.props.timespent ? this.props.timespent.hours: absenceDays_limit.min),
			hours_errorMessage: '',
			category_errorMessage: '',
		});
    }

    updateTimespent_handler = (event) => {
		let absenceDay = this.state.absenceDay;
		let category = this.state.selectedCategory;
		if(absenceDay < absenceDays_limit.min || absenceDay > absenceDays_limit.max || (absenceDay % absenceDays_limit.step !== 0)){
			this.setState({absenceDay_errorMessage: 'Min 3.5, Max 7, par pas de 3.5'});
		}else if(category === undefined){
			this.setState({category_errorMessage: "Vous devez sélectionner une catégorie"});
		}else{
			this.props.apiControllers.timespents.update(this.props.timespent.id,{
				categoryId: category.id,
				hours: absenceDay,
				description:'',
				tags:[]
			},{
				auth_username:this.props.user.username,
				auth_token:this.props.user.token
			}).then((response) => {
				this.props.notifier.success('Absence modifiée');
				this.update_handler(response.data);
			}).catch((error) => {
				this.props.notifier.error(error.data);
			});
		}
	};

	deleteTimespent_handler = (event) => {
		this.props.apiControllers.timespents.delete(this.props.timespent.id,{
			auth_username:this.props.user.username,
			auth_token:this.props.user.token
		}).then((response) => {
			this.props.notifier.success('Absence Supprimée');
			this.delete_handler(this.props.timespent);
		}).catch((error) => {
			this.props.notifier.error(error.data);
		});
	};

	absenceDayChange_handler = (event) => {
		this.setState({absenceDay: event.target.value});
		let value = event.target.value;
		if(value < absenceDays_limit.min || value > absenceDays_limit.max || (value % absenceDays_limit.step !== 0)){
			this.setState({absenceDay_errorMessage: 'Min 3.5, Max 7, par pas de 3.5'});
		}else{
			this.setState({absenceDay_errorMessage: ''});
		}
	}

	categoryChange_handler = (category) => {
		this.setState({
			selectedCategory: category,
			category_errorMessage: ''
		});
	}

	//======================= Page Render ========================

    render() {
		const { classes } = this.props;
		return (
			<Dialog
				open={this.props.open}
				onClose={this.closeFromDialog_handler}
				onEnter={this.dialogEnter_handler}
				aria-labelledby="form-dialog-title"
				classes={{
					paper:classes.paper
				}}
			>
				<DialogTitle id="form-dialog-title">Modifier une absence</DialogTitle>
				<form>
					<DialogContent className={classes.mainDialogContent}>
						<Typography variant="h3" >
							Date : {this.props.date !== undefined ? moment(this.props.date).format("DD/MM/YYYY") : undefined}
						</Typography>
                        <TextField
							fullWidth
							error={this.state.absenceDay_errorMessage !== "" ? true : false}
							helperText={this.state.absenceDay_errorMessage}
							color="secondary"
							id="absenceDay-input"
							label="Nombre d'heures"
							type="number"
							InputProps={{
								inputProps:{
									min: absenceDays_limit.min,
									max: absenceDays_limit.max,
									step: absenceDays_limit.step
								},
								endAdornment: <InputAdornment position="end">h</InputAdornment>
							}}
							value={this.state.absenceDay}
							onChange={this.absenceDayChange_handler}
						/>
						{(this.state.project !== undefined && this.state.project.categories.length > 0) ?
							<AutoCompleteHightlight
								suggestions={this.state.project.categories}
								change_handler={this.categoryChange_handler}
								selectedValue={this.state.selectedCategory}
								errorMessage={this.state.category_errorMessage}
							/>
						: undefined}
					</DialogContent>
					<DialogActions>
                        <Button
							onClick={this.deleteTimespent_handler}
							className={classNames(classes.button,classes.deletion)}
							variant="contained"
						>
							<Delete className={classes.leftIcon} />
							Supprimer
						</Button>
						<Button
							onClick={this.updateTimespent_handler}
							color="secondary"
							className={classes.button}
							variant="contained"
						>
							<Pencil className={classes.leftIcon} />
							Modifier
						</Button>
					</DialogActions>
				</form>
			</Dialog>
		);
	}
}

UpdateAbsenceDialog.propTypes = {
    date: PropTypes.object.isRequired,
    updateHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func.isRequired,
	apiControllers: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	logger: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	notifier: PropTypes.object.isRequired,
    checkAuthentication:PropTypes.func.isRequired,
    timespent: PropTypes.object,
};

export default withStyles(style_override,{withTheme: true})(UpdateAbsenceDialog);
